
import React from 'react';
import PropTypes from 'prop-types'
import ModalBase from './base/ModalBase';
import StyleSheet from './StyleSheet';
import Touchable from './Touchable';
import Dimensions from './Dimensions';
import View from './View';
import ScrollView from './ScrollView';

/** A modal component to present content above an enclosing view. */
export default class Modal extends React.PureComponent {
  static propTypes = {
    /** When false, modal cannot be seen. */
    visible: PropTypes.bool.isRequired,
    /** The animationType prop controls the animation transition of the modal  */
    animationType: PropTypes.oneOf(["slide", "fade", "none"]),
    /** The transparent prop determines if the modal should over the entire view. */
    transparent: PropTypes.bool,

  }
  static defaultProps = {
    animationType: "none"
  }
  constructor(props) {
    super(props);
    this.handleBackdropPress = this.handleBackdropPress.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
  }
  handleBackdropPress() {
    if (this.props.onBackdropPress) this.props.onBackdropPress();
  }
  handleRequestClose() {
    if (this.props.onRequestClose) return this.props.onRequestClose();
    return true;
  }
  handleContentLayout(event) {
    if (this.props.scrollEnabled) {
      let windowDimensions = Dimensions.get("window");
    }
  }
  render() {
    let ContentWrapper = this.props.scrollEnabled ? ScrollView : View;
    return (
      <ModalBase
        {...this.props}
        onRequestClose={this.handleRequestClose}
      >

        <ContentWrapper
          style={styles.contentWrapper}
        >
          <View
            style={[styles.content,this.props.contentStyle]}
          >
            {this.props.children}
          </View>
          
        </ContentWrapper>
        {(this.props.onBackdropPress || this.props.backdrop) &&
            <Touchable
              onPress={this.handleBackdropPress}
              feedback={false}
              style={styles.backdropTouchable}
            >
              <View style={[styles.backdrop, this.props.backdropStyle]} />
            </Touchable>
          }
      </ModalBase>
    );
  }
}
const styles = StyleSheet.create({
  backdrop: {
    ...StyleSheet.absoluteFill,
  },
  backdropTouchable: {
    ...StyleSheet.absoluteFill,
  },
  contentWrapper: {
    flex: 1
  },
  content: {
    position: 'relative',
    flex: 1,
    // marginLeft: 'auto',
    // marginRight: 'auto',
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },

});
