import React from "react";
import PropTypes from "prop-types";
import R14 from "../R14";
import RadioButton from "./RadioButton";
import View from "./View";
import Text from "./Text";
import InputField from "./InputField";
import StyleSheet from "./StyleSheet";
import Touchable from "./Touchable";
import { Colors } from "./Theme";

export default R14.connectForm(
  /**
   * A hidden form field field component
   */
  class RadioButtonField extends React.Component {
    static propTypes = {
      /** Label for the radioButton. */
      label: PropTypes.string,
      /** Name for the radioButton field. */
      name: PropTypes.string.isRequired,
      /** Value of the radioButton. */
      value: PropTypes.bool,
      /** Array of label / value objects. */
      items: PropTypes.array,
      /** Handler to be called when the radioButton groups value is changed  */
      onValueChange: PropTypes.func,
      /** If true, disable the radioButton */
      disabled: PropTypes.bool,
    };
    static defaultProps = {
      items: [],
    };
    constructor(props) {
      super(props);
      this.elmt = this.props.form.addElement(props);
      this.handleValueChange = this.handleValueChange.bind(this);
      this.handleFocus = this.handleFocus.bind(this);
      this.handleBlur = this.handleBlur.bind(this);
      this.handleItemLabelPress = this.handleItemLabelPress.bind(this);
    }
    handleValueChange(value) {
      this.elmt.setValue(value);
      if (this.props.onValueChange) this.props.onValueChange(value, this.elmt);
    }
    handleFocus(item) {
      this.elmt.setFocus(true, { item });
      if (this.props.onFocus) this.props.onFocus(this.elmt, item);
    }
    handleBlur(item) {
      this.elmt.setBlur(true, { item });
      if (this.props.onBlur) this.props.onBlur(this.elmt, item);
    }
    handleItemLabelPress(item) {
      this.elmt.setFocus(true, { item });
      this.handleValueChange(
        item.value !== this.elmt.value ? item.value : null
      );
    }
    componentWillUnmount() {
      this.elmt.remove();
    }
    renderRadioButtonGroup() {
      let radioButtons = this.elmt.items.map((item, i) => {
        // autofocus can be either boolean or value
        let autoFocus =
          (this.props.autoFocus === true && parseInt(i) === 0) ||
          this.props.autoFocus === item.value;
        return (
          <View
            key={item.value}
            style={[
              styles.radioButtonRow,
              this.elmt.isItemFocused(item) && styles.radioButtonRowFocus,
            ]}
          >
            <RadioButton
              name={item.value}
              // value={true}
              elementRef={this.elmt.getItemRef(item)}
              value={this.elmt.value === item.value}
              onValueChange={(value) =>
                this.handleValueChange(value ? item.value : null)
              }
              onFocus={(e) => this.handleFocus(item)}
              onBlur={(e) => this.handleBlur(item)}
              tabIndex={0}
              autoFocus={autoFocus}
              style={styles.radioButton}
            />
            <Touchable
              style={styles.radioButtonLabel}
              onPress={() => this.handleItemLabelPress(item)}
            >
              <Text style={styles.radioButtonLabelText}>{item.label}</Text>
            </Touchable>
          </View>
        );
      });
      return <View style={styles.radioButtonGroup}>{radioButtons}</View>;
    }
    render() {
      return (
        <InputField
          // {...this.props}
          label={this.props.label}
          staticLabel
          style={this.props.style}
          element={this.elmt}
          InputComponent={
            <View style={styles.radioButtonFieldWrapper}>
              {this.renderRadioButtonGroup()}
              {/* <RadioButton
                name={this.elmt.name}
                ref={this.elmt.ref}
                value={this.elmt.value ? true : false}
                onValueChange={this.handleValueChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                autoFocus={this.props.autoFocus || null}
                style={styles.radioButton}
              />
               {this.props.label && (
                <Text style={styles.labelText}>{this.props.label}</Text>
              )} */}
            </View>
          }
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  radioButtonFieldWrapper: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    ...StyleSheet.padding(12, 0, 12, 0),
    // height: 52,
  },
  labelText: {
    lineHeight: 14,
    // pointerEvents: "none",
    fontWeight: "500",
    paddingRight: 12,
    color: StyleSheet.color(Colors.onBackground).rgba(0.8),
  },
  radioButton: {
    ...StyleSheet.padding(1, 0, 0, 0),
  },
  radioButtonLabel: {
    flex: 1,
    ...StyleSheet.padding(8, 8, 8, 16),
    color: StyleSheet.color(Colors.onBackground).rgba(0.8),
  },
  radioButtonLabelText: {
    lineHeight: 24,
    fontSize: 16,
  },
  radioButtonRow: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    minHeight: 40,
    // backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.2),
    // marginBottom: 4,
    ...StyleSheet.margin(0, 8, 0, 8),
    borderRadius: 4,
  },
  radioButtonRowFocus: {
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.02),
  },
  radioButtonGroup: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
});
