import React from "react";
import R14, {
  Colors,
  StyleSheet,
  Text,
  View,
  Button,
  FadeView,
  Card,
} from "../core";
import EntityDataTable from "./EntityDataTable";
export default R14.connect(
  class manualEntryErrorDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.manualEntryError = this.props.app.dm.manualEntryError;
      this.pageLoader = this.pageLoader.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.rowBottomRenderer = this.rowBottomRenderer.bind(this);
      this.perms = this.props.app.dm.user.getPermissions(
        this.projectKey,
        "manualEntryError"
      );
      this.state = {
        rowBottomVisible: [],
      };
    }
    get projectKey() {
      return this.props.app.dm.project.getKeyByType(this.props.projectType);
    }
    showRowBottom(row) {
      let rowKey = this.rowKeyExtractor(row);
      if (this.isRowBottomVisible(row)) return;
      let rowBottomVisible = this.state.rowBottomVisible;
      rowBottomVisible.push(rowKey);
      this.setState({ rowBottomVisible });
    }
    hideRowBottom(row) {
      let rowKey = this.rowKeyExtractor(row);
      if (!this.isRowBottomVisible(row)) return;
      let rowBottomVisible = this.state.rowBottomVisible;
      rowBottomVisible.splice(rowBottomVisible.indexOf(rowKey), 1);
      this.setState({ rowBottomVisible });
    }
    isRowBottomVisible(row) {
      let rowKey = this.rowKeyExtractor(row);
      return this.state.rowBottomVisible.includes(rowKey);
    }
    rowKeyExtractor(row) {
      return `${row.uid}`;
    }

    async pageLoader(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {
        // projectUid: { eq: this.props.projectUid },
      };
      if (search) filter.search = { like: `%${search}%` };
      if (this.props.type) filter.type = { eq: this.props.type };
      let res = await this.manualEntryError.find(
        `
        uid
        pipeline {
          name
          uid
        }
        pipelineBlock {
          name
          uid
        }
        errorMessage
        errorStack
        ioDataJson
        createdAt
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    renderObject(jsonData, depth = 1) {
      let ret = null;
      switch (typeof jsonData) {
        case "object":
          let children = [];
          for (let i in jsonData) {
            let label = !Array.isArray(jsonData) ? (
              <View>
                <Text style={styles.jsonObjectLabelText}>{i}:</Text>
              </View>
            ) : (
              ""
            );
            children.push(
              <View
                key={`${depth}-${i}`}
                style={[
                  styles.jsonObjectRow,
                  depth > 1 && styles.jsonObjectRowChild,
                ]}
              >
                {label}
                {this.renderObject(jsonData[i], depth + 1)}
              </View>
            );
          }
          ret = (
            <View>
              {Array.isArray(jsonData) && (
                <View style={styles.jsonObjectRowChild}>
                  <Text>[</Text>
                </View>
              )}
              {children}
              {Array.isArray(jsonData) && (
                <View style={styles.jsonObjectRowChild}>
                  <Text>]</Text>
                </View>
              )}
            </View>
          );
          break;
        default:
          ret = <Text style={styles.jsonObjectRowChild}>"{jsonData}"</Text>;
      }
      return ret;
    }
    renderIoData(ioDataJson) {
      if (!ioDataJson) return null;
      let ioData = JSON.parse(ioDataJson);
      if (!ioData) return null;
      return this.renderObject(ioData);
    }
    rowBottomRenderer({ row }) {
      return (
        <FadeView
          style={styles.detailsCards}
          visible={this.isRowBottomVisible(row)}
          unmountOnExit={true}
        >
          <Card
            style={styles.detailsCard}
            title='Trace'
            titleTextStyle={[styles.detailsCardTitleText]}
          >
            {row.errorStack.split("\n").map((r, i) => (
              <View
                key={`errorLink-${i}`}
                style={[
                  styles.errorStack,
                  parseInt(i) === 0 && styles.errorStackError,
                ]}
              >
                <Text
                  style={[
                    styles.errorStackText,
                    parseInt(i) === 0 && styles.errorStackErrorText,
                  ]}
                >
                  {r}
                </Text>
              </View>
            ))}
          </Card>
          {row.ioDataJson && (
            <Card
              style={styles.detailsCard}
              title='IO Data'
              titleTextStyle={[styles.detailsCardTitleText]}
            >
              {this.renderIoData(row.ioDataJson)}
            </Card>
          )}
        </FadeView>
      );
    }
    render() {
      return (
        <EntityDataTable
          entityDomain={this.manualEntryError}
          searchable
          title='Manual Entry Errors'
          name='manualEntryErrors'
          columns={{
            createdAt: {
              label: "Created",
              width: 160,
              sortable: true,
            },
            pipeline: {
              label: "Pipeline",
              width: 220,
            },
            pipelineBlock: {
              label: "Block",
              width: 220,
            },
            errorMessage: {
              label: "Message",
            },
            // errorStack: {
            //   label: "Trace",
            //   width: 100,
            // },
            // ioDataJson: {
            //   label: "IO Data",
            // },
          }}
          cellRenderer={({ cell, columnName, row, columns }) => {
            let ret = null;
            switch (columnName) {
              case "pipeline":
              case "pipelineBlock":
                ret = <Text style={[styles.cellText]}>{cell.name}</Text>;
                break;
              case "createdAt":
                let createdAt = new Date(cell);
                ret = (
                  <View>
                    <View>
                      <Text style={[styles.cellText]}>
                        {this.props.app.utils.date.toShortDate(createdAt)}
                      </Text>
                    </View>
                    <View>
                      <Text style={[styles.cellText, styles.cellTextSmall]}>
                        {this.props.app.utils.date.toTime(createdAt)}
                      </Text>
                    </View>
                  </View>
                );
                break;
              default:
                ret = <Text style={[styles.cellText]}>{cell}</Text>;
            }
            return ret;
          }}
          rowControlsRightWidth={160}
          rowControlsRight={(row, dataTable) => {
            let rowBottomVisible = this.isRowBottomVisible(row);

            let ret = [
              <Button
                title={rowBottomVisible ? "Hide Details" : "View Details"}
                color={rowBottomVisible ? Colors.secondary : Colors.primary}
                style={styles.rowBottomExpandButton}
                key='expand'
                variant='outlined'
                uppercase={false}
                size='small'
                onPress={() =>
                  rowBottomVisible
                    ? this.hideRowBottom(row)
                    : this.showRowBottom(row)
                }
              />,
            ];
            return ret;
          }}
          pageLoader={this.pageLoader}
          rowKeyExtractor={this.rowKeyExtractor}
          rowBottom={this.rowBottomRenderer}
          initialSortColumnName='createdAt'
          initialSortDirection='desc'
          //perms={this.perms}
          projectUid={this.props.projectUid}
          projectType={this.props.projectType}
          autoRefresh={30}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
    //fontSize: 24
  },
  cellTextSmall: {
    fontSize: 14,
    color: StyleSheet.color(Colors.onBackground).rgba(0.8),
  },
  errorStackText: {
    fontSize: 14,
  },
  errorStack: {
    paddingBottom: 4,
  },
  errorStackError: {
    paddingBottom: 8,
  },
  errorStackErrorText: {
    fontWeight: 500,
  },
  rowBottomExpandButton: {
    // marginLeft: "auto",
    ...StyleSheet.margin(8,8,0,0)
  },
  detailsCard: {
    flex: 1,
  },
  detailsCards: {
    paddingTop: 24,
    flex: 1,
    flexDirection: "row",
    ...StyleSheet.margin(0, -16, -16, 0),
  },
  detailsCardTitleText: {
    fontSize: 16,
    // fontWeight: "500",
    color: StyleSheet.color(Colors.onBackground).rgba(0.8),
    ...StyleSheet.padding(0),
  },
  jsonObjectRow: {
    flex: 0,
    flexDirection: "column",
  },
  jsonObjectRowChild: {
    paddingLeft: 16,
  },
  jsonObjectText: {
    fontSize: 14,
  },
  jsonObjectLabelText: {
    fontSize: 14,
    fontWeight: 500,
    color: StyleSheet.color(Colors.onBackground).rgba(0.8),
  },
});
