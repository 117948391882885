import React from "react";
import R14, {
  HiddenInputField,
  FieldGroup,
  SelectMenuField,
  TextInputField,
  View,
  Text,
  StyleSheet,
  DialogFormField,
  ShowHideText,
  SwitchField,
  IconButton,
  Dialog,
  Form
} from "../core";
import EditForm from "../components/EditForm";
import LabelView from "../components/LabelView";
import AppModuleEditForm from "../components/AppModuleEditForm";

export default R14.connect(
  class BlockEditForm extends React.Component {
    constructor(props) {
      super(props);
      this.handleIoValueFormat = this.handleIoValueFormat.bind(this);
      this.handleIoRenderer = this.handleIoRenderer.bind(this);
      this.handleOptionsRenderer = this.handleOptionsRenderer.bind(this);
      this.appModuleItemLoader = this.appModuleItemLoader.bind(this);
      this.handleRemoveBlockIoItem = this.handleRemoveBlockIoItem.bind(this);
      this.handleFieldTypeChange = this.handleFieldTypeChange.bind(this);
      this.handleAppModuleValueChange = this.handleAppModuleValueChange.bind(
        this
      );
      this.handleCreateAppModulePress = this.handleCreateAppModulePress.bind(this);
      this.handleCreateAppModuleDialogClosePress = this.handleCreateAppModuleDialogClosePress.bind(
        this
      );
      this.handleCreateAppModuleDialogOkPress = this.handleCreateAppModuleDialogOkPress.bind(
        this
      );
      this.handleCreateAppModuleSubmit = this.handleCreateAppModuleSubmit.bind(this);

      this.pipelineDomain = this.props.app.dm.pipeline;
      this.state = {
        createAppModuleDialogVisible: false,
      };
      this.createAppModuleFormData = null;
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    async appModuleItemLoader(filters) {
      let res = await this.props.app.dm.block.fetchAppModuleSelections(filters);
      return res;
    }
    async handleAppModuleValueChange(val) {}
    async handleRemoveBlockIoItem(item) {
      this.props.onRemoveBlockIoItem && this.props.onRemoveBlockIoItem(item);
    }
    async handleCreateAppModuleSubmit(form) {
      try {
        this.props.app.ui.progressIndicator.show();
        let res = await this.props.app.dm.appModule.create(form.values);
        this.props.app.ui.progressIndicator.hide({ timeout: 500 });
        if (res.success) {
          let selectVal = {
            label: res.block.name,
            value: res.block.uid,
          };
          this.form.elmts("appModule").setValue(selectVal);
          this.handleAppModuleValueChange(selectVal);
          this.setState({ createAppModuleDialogVisible: false });
          this.props.app.ui.snackBar.show({
            message: `Created App Module: ${selectVal.label}.`,
            variant: "success",
          });
        } else if (res.errors && res.errors.length) {
          form.addError(res.errors[0].message);
        } else form.addError("An unknown error has occurred.");
      } catch (err) {
        form.addError(err.message);
      }
    }
    async handleCreateAppModulePress(value) {
      if (!this.createAppModuleFormData) {
        this.props.app.ui.progressIndicator.show();
        this.createAppModuleFormData = await this.props.app.dm.appModule.fetchEditFormData(
          // null,
          // { projectType: this.props.projectType }
        );
        this.props.app.ui.progressIndicator.hide({ timeout: 500 });
      }
      this.setState({ createAppModuleDialogVisible: true });
    }
    async handleCreateAppModuleDialogClosePress() {
      this.setState({ createAppModuleDialogVisible: false });
    }
    async handleCreateAppModuleDialogOkPress() {
      this.props.app.ui.form("createAppModuleForm").submit();
    }

    handleFieldTypeChange(type) {
      console.log(type);
    }

    handleOptionsRenderer(values) {
      return (
        <View>
          <View style={styles.ioItemRow}>
            <LabelView style={styles.ioItemLabelView} key='label' label='Label'>
              <Text>{values.label}</Text>
            </LabelView>
            <LabelView style={styles.ioItemLabelView} key='key' label='Key'>
              <Text>{values.key}</Text>
            </LabelView>
            <LabelView
              style={styles.ioItemLabelView}
              key='required'
              label='Required'
            >
              <Text>{values.required ? "Yes" : "No"}</Text>
            </LabelView>
          </View>
        </View>
      );
    }

    handleIoValueFormat(values) {
      return values;
    }
    handleIoRenderer(values) {
      return (
        <View>
          <View style={styles.ioItemRow}>
            <LabelView style={styles.ioItemLabelView} key='name' label='Name'>
              <Text>{values.name}</Text>
            </LabelView>
            <LabelView style={styles.ioItemLabelView} key='key' label='Key'>
              <Text>{values.key}</Text>
            </LabelView>
            <LabelView
              style={styles.ioItemLabelView}
              key='datatype'
              label='Data Type'
            >
              <Text>
                {this.props.app.dm.block.getIoDataTypeLabel(values.dataType)}
              </Text>
            </LabelView>
            <LabelView style={styles.ioItemLabelView} key='sorted' label='Sorted'>
              <Text>{values.sorted ? "Yes" : "No"}</Text>
            </LabelView>
          </View>
          {values.description && (
            <View style={styles.ioItemRowBottom}>
              <LabelView
                style={styles.ioItemLabelView}
                key='description'
                label='Description'
              >
                <ShowHideText text={values.description} />
              </LabelView>
            </View>
          )}
        </View>
      );
    }
    renderIoForm() {
      return (
        <React.Fragment>
          <HiddenInputField name='uid' />
          <FieldGroup>
            <TextInputField name='name' label='Name' required='Enter Name' />
            <TextInputField
              name='description'
              label='Description'
              helper='Optional'
            />
          </FieldGroup>
          <FieldGroup>
            <TextInputField
              name='key'
              label='Key'
              validator={{
                key: true,
              }}
              required='Please enter a key'
              helper='Used by App Module'
            />
            <SelectMenuField
              useValuesOnly
              name='dataType'
              label='Data Type'
              required='Select data type'
              items={this.props.formData.blockIoDataTypeSelections}
            />
          </FieldGroup>
          <SwitchField
            key='sorted'
            name='sorted'
            label='Is Sorted?'
          />
        </React.Fragment>
      );
    }
    render() {
      return (
        <View>
          {this.isEditMode && <HiddenInputField name='uid' />}
          <HiddenInputField name='projectType' />
          <FieldGroup>
            <TextInputField
              name='name'
              label='Name'
              required='Enter Name'
              helper='Required, must be unique.'
            />
            <TextInputField name='description' label='Description' />
          </FieldGroup>

          <View style={styles.appModuleField}>
            <SelectMenuField
              name='appModule'
              label='App Module'
              searchable
              itemLoader={this.appModuleItemLoader}
              items={this.formData.appModuleSelections}
              required='Please select an app module.'
              helper='Required'
            />
            <IconButton
              tooltip='Create App Module'
              onPress={this.handleCreateAppModulePress}
              icon='add'
              variant='circle'
              disabled
              style={styles.appModuleCreateButton}
            />
          </View>
          <Dialog
            name='createAppModuleDialog'
            title='Create App Module'
            visible={this.state.createAppModuleDialogVisible}
            titleControlVariant='back'
            onClosePress={this.handleCreateAppModuleDialogClosePress}
            onCancelPress={this.handleCreateAppModuleDialogClosePress}
            onBackdropPress={this.handleCreateAppModuleDialogClosePress}
            onBackPress={this.handleCreateAppModuleDialogClosePress}
            onOkPress={this.handleCreateAppModuleDialogOkPress}
            okButtonTitle={"Create"}
            // OkButtonComponent={<SubmitButton title='Create'}
          >
            <Form
              name='createAppModuleForm'
              style={styles.createAppModuleForm}
              onSubmit={this.handleCreateAppModuleSubmit}
              validateBeforeSubmit
              // validators={FormValidators}
              initialValues={
                this.createAppModuleFormData && this.createAppModuleFormData.values
              }
            >
              <AppModuleEditForm 
                formData={this.createAppModuleFormData}
                // onAfterSave={}
              />
            </Form>
          </Dialog>
          {this.props.formData.cloudAccessKeySelections.length ? (
            <SelectMenuField
              useValuesOnly
              name='cloudAccessKeyUid'
              label='Cloud Access Key'
              items={this.props.formData.cloudAccessKeySelections}
            />
          ) : null}
          <DialogFormField
            name='inputs'
            dialogTitle='Add Input'
            label='Inputs'
            formName='blockForm'
            itemRenderer={this.handleIoRenderer}
            onRemoveItem={this.handleRemoveBlockIoItem}
            validator='uniqueKey'
            // onValueChange={(val) => {
            //   console.log("INPUT VALUE CHANGE", val);
            // }}
          >
            {this.renderIoForm()}
          </DialogFormField>
          <DialogFormField
            name='outputs'
            dialogTitle='Add Output'
            label='Outputs'
            formName='blockForm'
            validator='uniqueKey'
            itemRenderer={this.handleIoRenderer}
            onRemoveItem={this.handleRemoveBlockIoItem}
          >
            {this.renderIoForm()}
          </DialogFormField>
          <DialogFormField
            name='options'
            dialogTitle='Add Option'
            label='Options'
            formName='blockForm'
            itemRenderer={this.handleOptionsRenderer}
            validator='uniqueKey'
          >
            <TextInputField name='label' label='Label' required='Enter Label' />
            <TextInputField
              name='key'
              label='Key'
              validator='key'
              required='Enter Key'
            />
            <SwitchField
              key='isRequired'
              name='required'
              label='Is Required?'
            />
            <SelectMenuField
              useValuesOnly
              name='type'
              label='Field Type'
              items={this.formData.fieldTypeSelections}
              required='Please select a field type.'
              // onValueChange={this.handleFieldTypeChange}
            />
            <TextInputField
              name='helperText'
              label='Field Helper Text'
              helper='Text displayed under field'
            />
          </DialogFormField>
        </View>
      );
    }
  }
);
const styles = StyleSheet.create({
  ioItemRow: {
    flex: 1,
    flexDirection: "row",
    flexWrap:'wrap',
    marginBottom: -16
  },
  ioItemRowBottom: {
    flex: 1,
    flexDirection: "row",
    paddingTop: 8,
  },
  ioItemLabelView: {
    width: "50%",
    minWidth: 0,
    flex: 1,
    marginBottom: 12
  },
  appModuleField: {
    flex: 1,
    flexDirection: "row",
  },
  appModuleCreateButton: {
    height: 36,
    width: 36,
    ...StyleSheet.margin(10, 8, 0, 12),
  },
});
