import React from "react";
import R14, {
  View,
  StyleSheet,
  Surface,
  Form,
  ScrollView,
  SectionHeader,
  Colors,
} from "../core";
export default R14.connect(
  class CenteredForm extends React.Component {
    render() {
      return (
        <ScrollView>
          <View style={styles.formContainer}>
            <Surface style={[styles.formSurface]}>
              <Form
                backgroundColor={Colors.surface}
                {...this.props}
                style={[styles.centeredForm, this.props.style]}
              >
                {this.props.headerLabel && (
                  <SectionHeader style={styles.formHeader} level={2}>
                    {this.props.headerLabel}
                  </SectionHeader>
                )}
                {this.props.children}
              </Form>
            </Surface>
          </View>
        </ScrollView>
      );
    }
  }
);
const styles = StyleSheet.create({
  formContainer: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    screen: ({ width }) => {
      if (width <= 640)
        return {
          // alignItems: 'flex-start',
          justifyContent: "flex-start",
        };
    },
  },
  formHeader: {
    paddingBottom: 24,
  },
  formSurface: {
    flex: 0,
    flexDirection: "column",
    // width: 480,
    alignItems: "center",
    margin: 16,
    ...StyleSheet.margin(16),
    ...StyleSheet.padding(0),
    
    // paddingBottom: 24
  },
  centeredForm: {
    minWidth: 480,
    ...StyleSheet.padding(24),
    screen: ({ width }) => {
      if (width <= 640) {
        return {
          width: null,
        };
      }
    },
  },
});
