import R14 from "../core";

export default class PipelineLogDomain extends R14.Domain {
  constructor() {
    super();
    this.TYPE_LOG = "LOG";
    this.TYPE_WARN = "WARN";
    this.TYPE_ERROR = "ERROR";
  }
  async find(fieldsStr, options = {}) {
    if (!fieldsStr)
      throw new Error("Pipeline Log Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    console.log(
      "Index testing: ",
      `
    query FindPipelineLogs($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $search: String, $filter: PipelineLogFilter) {
      pipelineLogs(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, totalCount: $totalCount, search: $search, filter: $filter){
        totalCount @include(if: $totalCount)
        nodes {
          ${fieldsStr}
        }
      }
    }`,
      options.filter
    );
    let res = await this.api.qry(
      `
      query FindPipelineLogs($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $search: String, $sort: [SortOption!]!, $filter: PipelineLogFilter) {
        pipelineLogs(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, totalCount: $totalCount, search: $search, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return res.data.pipelineLogs;
  }

  async fetchDataGridFiltersFormData(pipeline = null) {
    let ret = {
      typeSelections: this.getTypeSelections(),
    };
    if (pipeline && pipeline.pipelineBlocks)
      ret.pipelineBlockSelections = pipeline.pipeli;
    return {
      typeSelections: this.getTypeSelections(),
    };
  }

  async fetchData(pipelineUid) {
    let { pipeline, pipelines } = await this.dm.pipeline.get(pipelineUid, {
      project: true,
      blocks: true,
      // pipelineBlockIo: true,
      pipelines: true,
    });
    let ret = {
      pipeline,
      pipelines,
      project: pipeline.project,
      dataGridFiltersFormData: {
        typeSelections: this.getTypeSelections(),
      },
    };
    if (pipeline && pipeline.pipelineBlocks && pipeline.pipelineBlocks.nodes)
      ret.dataGridFiltersFormData.pipelineBlockSelections =
        pipeline.pipelineBlocks.nodes.map((p) => ({
          value: p.uid,
          label: p.name,
        }));
    return ret;
  }

  // async create(values) {
  //   let res = await this.api.mutate(
  //     `
  //     mutation CreateDataset($input: CreateDatasetInput!) {
  //       createDataset(input: $input){
  //         dataset {
  //           uid
  //           name
  //           description
  //         }
  //       }
  //     }`,
  //     {
  //       input: this.parseFormValues(values),
  //     }
  //   );
  //   return true;
  // }
  // async update(values, options = {}) {
  //   console.log("UPDATE DS", values, options);
  //   let res = await this.api.mutate(
  //     `
  //     mutation UpdateDataset($input: UpdateDatasetInput!) {
  //       updateDataset(input: $input){
  //         dataset {
  //           uid
  //           name
  //           description
  //         }
  //       }
  //     }`,
  //     {
  //       input: options.parse === false ? values : this.parseFormValues(values),
  //     }
  //   );
  //   return true;
  // }
  // parseFormValues(values) {
  //   let ret = {
  //     ...values,
  //     clientUid: this.dm.userSession.clientUid,
  //   };
  //   return ret;
  // }
  // async get(uid) {
  //   let res = await this.api.qry(
  //     `
  //     query GetDataset($uid: ID!) {
  //      Dataset(uid: $uid){
  //         uid
  //         name
  //         description
  //       }
  //     }`,
  //     {
  //       uid: uid,
  //     }
  //   );
  //   return res.data.dataset;
  // }

  // async delete(uid) {
  //   return await this.updateState(uid, this.STATE_DELETE);
  // }
  // async clearData(uid) {
  //   return await this.updateState(uid, this.STATE_CLEAR);
  // }
  // async stopSync(uid) {
  //   return await this.updateState(uid, this.STATE_READY);
  // }
  // async runSync(uid) {
  //   return await this.updateState(uid, this.STATE_SYNC);
  // }
  // async updateState(uid, state) {
  //   let res = await this.update(
  //     {
  //       uid: uid,
  //       state: state,
  //     },
  //     { parse: false }
  //   );
  //   return true;
  // }

  // async fetchEditFormData(uid = null, options = {}) {
  //   let qry = "";
  //   let qryVals = {
  //     //userFilter: { clientUid: { eq: this.dm.userSession.clientUid } },
  //     cloudAccessKeyFilter: {
  //       clientUid: { eq: this.dm.userSession.clientUid },
  //       type: { eq: this.dm.cloudAccessKey.TYPE_AWS },
  //     },
  //   };
  //   if (uid) {
  //     qry = `
  //     query DatasetCreateFormData($uid: ID!, $cloudAccessKeyFilter: CloudAccessKeyFilter) {
  //       dataset(uid: $uid){
  //         uid
  //         key
  //         type
  //         name
  //         description
  //         bucketName
  //         cloudAccessKeyUid
  //       },
  //       cloudAccessKeys(filter:$cloudAccessKeyFilter) {
  //         nodes {
  //           uid
  //           name
  //         }
  //       }
  //     }
  //   `;
  //     qryVals.uid = uid;
  //   } else {
  //     qry = `
  //       query DatasetEditFormData($cloudAccessKeyFilter: CloudAccessKeyFilter) {
  //         cloudAccessKeys(filter:$cloudAccessKeyFilter) {
  //           nodes {
  //             uid
  //             name
  //           }
  //         }
  //       }
  //     `;
  //   }

  //   let res = await this.api.qry(qry, qryVals);
  //   let formVals = res.data.dataset || {};
  //   if (!formVals.projectType && options.projectType)
  //     formVals.projectType = options.projectType;
  //   let formData = {
  //     values: formVals,
  //     typeSelections: this.getTypeSelections(),
  //     // userSelections: res.data.users.nodes.map((val) => ({
  //     //   label: val.name,
  //     //   value: val.uid,
  //     // })),
  //     cloudAccessKeySelections: res.data.cloudAccessKeys.nodes.map((val) => ({
  //       label: val.name,
  //       value: val.uid,
  //     })),
  //   };
  //   return formData;
  // }
  getTypeSelections(options = {}) {
    return [
      {
        label: this.getTypeLabel(this.TYPE_LOG),
        value: this.TYPE_LOG,
      },
      {
        label: this.getTypeLabel(this.TYPE_ERROR),
        value: this.TYPE_ERROR,
      },
      {
        label: this.getTypeLabel(this.TYPE_WARN),
        value: this.TYPE_WARN,
      },
    ];
  }
  getTypeLabel(type) {
    let ret = null;
    switch (type) {
      case this.TYPE_ERROR:
        ret = "Error";
        break;
      case this.TYPE_LOG:
        ret = "Log";
        break;
      case this.TYPE_WARN:
        ret = "Warning";
        break;
    }
    return ret;
  }
  getTypeIndicatorColor(type) {
    let color = "yellow";
    switch (type) {
      case this.TYPE_ERROR:
        color = "red";
        break;
      case this.TYPE_LOG:
        color = "green";
        break;
      case this.TYPE_YELLOW:
        color = "yellow";
        break;
    }
    return color;
  }
  // getStateIndicatorLabel(state) {
  //   let label = null;
  //   switch (state) {
  //     case this.STATE_CLEAR:
  //     case this.STATE_CLEARING:
  //       label = "Clearing";
  //       break;
  //     case this.STATE_DELETE:
  //     case this.STATE_DELETING:
  //       label = "Deleting";
  //       break;
  //     case this.STATE_INIT:
  //     case this.STATE_INITIALIZING:
  //       label = "Initializing";
  //       break;
  //     case this.STATE_SYNC:
  //     case this.STATE_SYNCING:
  //       label = "Syncing";
  //       break;
  //     case this.STATE_READY:
  //       label = "Ready";
  //       break;
  //   }
  //   return label;
  // }
  // getStateIndicatorColor(state) {
  //   let color = "red";
  //   switch (state) {
  //     case this.STATE_CLEAR:
  //     case this.STATE_CLEARING:
  //     case this.STATE_INIT:
  //     case this.STATE_INITIALIZING:
  //     case this.STATE_SYNC:
  //     case this.STATE_SYNCING:
  //       color = "yellow";
  //       break;
  //     case this.STATE_READY:
  //       color = "green";
  //       break;
  //   }
  //   return color;
  // }
}
