import R14 from "../core";
export default class ManualEntryPipelineDomain extends R14.EntityDomain {
  constructor(key) {
    super({
      name: "manualEntryPipeline",
    });
    this.state = {};
    this.MODE_FIELD_CHARACTERS = "FIELD_CHARACTERS";
    this.MODE_CHARACTERS = "CHARACTERS";
    this.MODE_FIELD = "FIELD";
    this.MODE_DOCUMENT_SET_FIELDS = "DOCUMENT_SET_FIELDS";
    this.COMMAND_TIMEOUT = "TIMEOUT";
    this.COMMAND_RESTART_SERVICES = "RESTART_SERVICES";
    this.METRIC_PARENT_TYPE_USER = "USER";
    this.METRIC_PARENT_TYPE_DOCUMENT_SET = "DOCUMENT_SET";
    this.METRIC_PARENT_TYPE_DOCUMENT = "DOCUMENT";
    this.METRIC_PARENT_TYPE_CHARACTER = "CHARACTER";
    this.METRIC_PARENT_TYPE_FIELD = "FIELD";
    this.METRIC_PARENT_TYPE_FIELD_GROUP = "FIELD_GROUP";
    this.METRIC_TYPE_TOTAL = "TOTAL";
    this.METRIC_TYPE_TOTAL_AVAILABLE = "TOTAL_AVAILABLE";
    this.METRIC_TYPE_TOTAL_QUEUED = "TOTAL_QUEUED";
    this.METRIC_TYPE_TOTAL_ACTIVE = "TOTAL_ACTIVE";
    this.METRIC_TYPE_TOTAL_COMPLETE = "TOTAL_COMPLETE";
    this.METRIC_TYPE_TOTAL_TIMEOUT = "TOTAL_TIMEOUT";
  }
  // async get(uid, fields) {
  //   if (!fields) fields = [("uid", "name", "description")];
  //   return await super.get(uid, fields);
  // }
  async get(uid, options = {}) {
    let projectField = "";
    let manualEntryConfigsField = "";
    let metricsField = "";
    let qryParams = ["$uid: ID!"];
    let qryVals = {
      uid: uid,
    };

    if (options.project) {
      projectField = `
    project {
      key
      uid
      name
      type
    }
    `;
    }
    if (options.manualEntryConfigs) {
      manualEntryConfigsField = `
    manualEntryConfigs {
      nodes {
        uid
      }
    }
    `;
    }
    if (options.metrics) {
      metricsField = `
    metrics {
      parentType
      type
      value
    }
    lastMetricsSyncAt
    `;
    }

    let res = await this.api.qry(
      `
      query GetManualEntryPipeline(${qryParams.join(", ")}) {
       manualEntryPipeline(uid: $uid){
          uid
          name
          ${projectField}
          ${manualEntryConfigsField}
          ${metricsField}
        }
      }`,
      qryVals
    );
    return res.data.manualEntryPipeline;
  }
  async fetchEditFormData(uid = null, options = {}) {
    let qry = "";
    let qryVals = {
      manualEntryPipelineFilter: {
        clientUid: { eq: this.dm.userSession.clientUid },
      },
      pipelineFilter: {
        clientUid: { eq: this.dm.userSession.clientUid },
        userUids: { eq: this.dm.userSession.uid },
      },
      pipelineSort: [
        {
          field: "name",
          order: "ASC",
        },
      ],
    };
    if (uid) {
      qry = `
      query ManualEntryPipelineCreateFormData($uid: ID!, $pipelineFilter: PipelineFilter, $pipelineSort: [SortOption!]!, $manualEntryPipelineFilter: ManualEntryPipelineFilter) {
        manualEntryPipeline(uid: $uid){
          uid
          name
          description
          pipelineUid
          modes
          controllerPipelineBlockUid
          scoreThreshold
          timeoutMinutes
          timeoutFieldValues {
            field
            value
            minutes
          }
          pipeline {
            pipelineBlocks {
              nodes {
                uid
                name
              }
            }
          }
        },
        pipelines(filter: $pipelineFilter, sort: $pipelineSort) {
          nodes {
            uid
            name
          }
        }
        manualEntryPipelines(filter: $manualEntryPipelineFilter) {
          nodes {
            uid
            pipelineUid
          }
        }
      }
    `;
      qryVals.uid = uid;
    } else {
      qry = `
        query ManualEntryPipelineEditFormData($pipelineFilter: PipelineFilter, $pipelineSort: [SortOption!]!, $manualEntryPipelineFilter: ManualEntryPipelineFilter) {
          pipelines(filter: $pipelineFilter, sort: $pipelineSort) {
            nodes {
              uid
              name
            }
          }
          manualEntryPipelines(filter: $manualEntryPipelineFilter) {
            nodes {
              uid
              pipelineUid
            }
          }
        }
      `;
    }

    let res = await this.api.qry(qry, qryVals);
    let formVals = res.data.manualEntryPipeline || {};
    if (options.projectUid) formVals.projectUid = options.projectUid;
    let usedPipelineUids = res.data.manualEntryPipelines.nodes
      .filter((val) => val.uid !== uid)
      .map((val) => val.pipelineUid);

    let formData = {
      values: formVals,
      pipelineSelections: res.data.pipelines.nodes
        .filter((val) => !usedPipelineUids.includes(val.uid))
        .map((val) => ({
          label: val.name,
          value: val.uid,
        })),
      pipelineBlockSelections:
        res.data.manualEntryPipeline &&
        res.data.manualEntryPipeline.pipeline &&
        res.data.manualEntryPipeline.pipeline.pipelineBlocks &&
        res.data.manualEntryPipeline.pipeline.pipelineBlocks.nodes
          ? res.data.manualEntryPipeline.pipeline.pipelineBlocks.nodes.map(
              (val) => ({ label: val.name, value: val.uid })
            )
          : [],
      modeSelections: [
        {
          label: "Characters",
          value: this.MODE_CHARACTERS,
        },
        {
          label: "Field",
          value: this.MODE_FIELD,
        },
        {
          label: "Field Characters",
          value: this.MODE_FIELD_CHARACTERS,
        },
        {
          label: "Document Set Fields",
          value: this.MODE_DOCUMENT_SET_FIELDS,
        },
      ],
    };
    return formData;
  }

  parseSubmitValues(values) {
    let updateValues = { ...values };
    updateValues.clientUid = this.dm.userSession.clientUid;
    updateValues.scoreThreshold = values.scoreThreshold
      ? parseInt(values.scoreThreshold)
      : null;
    updateValues.timeoutMinutes = values.timeoutMinutes
      ? parseInt(values.timeoutMinutes)
      : null;
    updateValues.timeoutFieldValues = values.timeoutFieldValues
      ? values.timeoutFieldValues.map((val) => ({
          field: val.field,
          value: val.value,
          minutes: parseInt(val.minutes),
        }))
      : null;
    return updateValues;
  }

  async fetchMetrics(uid, options = {}) {
    // let fieldsStr = this.utils.gql.fieldsToString(fields);

    // if (!options.filter) options.filter = {};
    //if (!options.totalCount) options.totalCount = false;
    // options.filter.clientUid = { eq: this.dm.userSession.clientUid };
    options.filter = {
      manualEntryPipelineUid: { eq: uid },
      clientUid: { eq: this.dm.userSession.clientUid },
    };
    options.sort = [
      {
        field: "metricsAt",
        order: "DESC",
      },
    ];
    if (!options.page) options.page = 1;
    if (!options.rowsPerPage) {
      options.resultsPerPage = 5;
    } else {
      options.resultsPerPage = options.rowsPerPage;
      delete options.rowsPerPage;
    }
    options.totalCount = true;
    let result = await this.api.qry(
      `
      query fetchManualEntryPipelineMetrics($filter: ManualEntryPipelineMetricFilter, $page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!) {
        manualEntryPipelineMetrics(filter: $filter, page: $page, resultsPerPage: $resultsPerPage, sort: $sort){
          totalCount @include(if: $totalCount)
          nodes {
            dateKey
            metricsAt
            metrics {
              parentType
              type
              value
            }
          }
        }
      }`,
      options
    );
    return result.data.manualEntryPipelineMetrics;
  }

  async initDashboard(uid) {
    let manualEntryPipeline = await this.get(uid, {
      metrics: true,
      project: true,
    });
    // let res = await this.api.qry(
    //   `
    //   query manualEntryPipeline($uid: ID!) {
    //    ManualEntryPipeline(uid: $uid){
    //       uid
    //       name
    //       metrics {
    //         parentType
    //         type
    //         value
    //       }
    //       project {
    //         uid
    //         name
    //         type
    //       }
    //     }
    //   }`,
    //   {
    //     uid: uid,
    //   }
    // );
    // let ret = res.data.manualEntryPipeline;
    return manualEntryPipeline;
    // ret.metrics = {
    //   documents: res.data.manualEntryDocumentCurrentMetrics,
    //   users: res.data.manualEntryUserCurrentMetrics,
    //   characters: res.data.manualEntryCharacterCurrentMetrics,
    //   fields: res.data.manualEntryFieldCurrentMetrics,
    // };
  }
  getMetricValue(manualEntryPipeline, parentType, type) {
    console.log("CHECK CHECK CHECK", manualEntryPipeline, parentType, type);
  }
  async runCommand(uid, command) {
    let res = await this.api.mutate(
      `
      mutation RunManualEntryCommand($uid: ID!, $command: ManualEntryCommandEnum!) {
        runManualEntryCommand(uid: $uid, command: $command){
          success
        }
      }`,
      {
        uid,
        command,
      }
    );
    return true;
  }
}
