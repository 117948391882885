import React from "react";
import R14, {
  HiddenInputField,
  FieldGroup,
  SelectMenuField,
  TextInputField,
  BackButton,
  Form,
  SubmitButton,
  Text,
  StyleSheet,
  Redirect,
  SwitchField
} from "../core";
import LabelView from "../components/LabelView";

export default R14.connect(
  class ProjectPipelineCopyScreen extends React.Component {
    constructor(props) {
      super(props);
      this.pipelineDomain = this.props.app.dm.pipeline;
      this.handleSubmit = this.handleSubmit.bind(this);
      this.state = {
        connectionType: this.props.formData.values.connectionType,
      };
    }
    get formData() {
      return this.props.formData;
    }
    async handleSubmit(form) {
      let ret = null;
      this.props.app.ui.progressIndicator.show();
      try {
        let res = await this.pipelineDomain.copy(form.values);
        if (res.success) {
          ret = (
            <Redirect
              to='projectPipeline'
              params={{
                uid: this.props.projectUid,
                key: this.props.app.dm.project.getKeyByType(
                  this.props.projectType
                ),
                pipelineUid: res.pipeline.uid
              }}
            />
          );
        } else if (res.errors) {
          res.errors.forEach((err) => form.addError(err.message));
        } else form.addError("Unknown Error, please try again.");
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
      // if (
      //   isSuccess &&
      //   this.isEditMode &&
      //   this.props.app.ui.pipeline.exists(this.props.formData.values.uid)
      // ) {
      //   let pipeline = await this.props.app.ui.pipeline.instance(
      //     this.props.formData.values.uid
      //   );
      //   await pipeline.refresh();
      // }
    }
    render() {
      return (
        <Form
          name='resourceLogAddForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton title='Add' key='submit' />,
          ]}
        >
          <LabelView
            style={styles.labelView}
            key='name'
            label='Copying Pipeline'
          >
            <Text>{this.formData.pipelineName}</Text>
          </LabelView>
          <HiddenInputField name='uid' />
          <FieldGroup>
            <TextInputField
              name='postfix'
              label='Postfix'
              helper='Required'
              required={true}
            />
            <TextInputField
              name='replacePostfix'
              label='Replace postfix'
              validator='key'
            />
          </FieldGroup>
          <FieldGroup>
            <TextInputField
              name='resourceUid'
              label='Resource UID'
              helper='Default Resource'
            />
            <TextInputField
              name='gpuResourceUid'
              label='GPU Resource UID'
              helper='Default GPU Resource'
            />
            <SwitchField
              key='shallowCopy'
              name='shallowCopy'
              label='Shallow Copy'
              value={true}
            />
          </FieldGroup>
        </Form>
      );
    }
  }
);
const styles = StyleSheet.create({
  labelView: {
    marginBottom: 16,
  },
});
