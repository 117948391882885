import React from "react";
import R14, {
  Redirect,
  SelectMenuField,
  HiddenInputField,
  BackButton,
  Form,
  FieldGroup,
  SubmitButton,
  TextInputField,
  SwitchField,
  DialogFormField,
  FadeView,
  Surface,
  StyleSheet,
  Colors,
} from "../core";
import CountrySelectMenuField from "../components/CountrySelectMenuField";

export default R14.connect(
  class ManualEntryUserEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleManualEntryVisibleChange =
        this.handleManualEntryVisibleChange.bind(this);
      this.handleVirtualWorkspaceVisibleChange =
        this.handleVirtualWorkspaceVisibleChange.bind(this);
      this.handleMfaTypeChange = this.handleMfaTypeChange.bind(this);
      // this.handlePermissionsRenderer =
      //   this.handlePermissionsRenderer.bind(this);
      // this.handlePermissionsDialogClose =
      //   this.handlePermissionsDialogClose.bind(this);
      // this.handlePipelineValueChange =
      //   this.handlePipelineValueChange.bind(this);
      // this.handlePermissionsDialogSubmit =
      //   this.handlePermissionsDialogSubmit.bind(this);
      // this.handlePermissionsItemEditPress =
      //   this.handlePermissionsItemEditPress.bind(this);

      this.state = {
        manualEntryVisible:
          this.props.formData &&
          this.props.formData.values &&
          this.props.formData.values.manualEntryConfigUid
            ? true
            : false,
        mfaType:
          this.props.formData &&
          this.props.formData.values &&
          this.props.formData.values.mfaType
            ? this.props.formData.values.mfaType
            : null,
        // virtualWorkspaceVisible: true,
      };
      // this.pipelineMap = this.props.formData.pipelineMap;
    }
    handleManualEntryVisibleChange(value) {
      this.setState({ manualEntryVisible: value });
    }
    handleVirtualWorkspaceVisibleChange(value) {
      this.setState({ virtualWorkspaceVisible: value });
    }
    handleMfaTypeChange(value) {
      switch (value) {
        case this.props.app.dm.manualEntryUser.MFA_TYPE_EMAIL:
          this.form.elmts.email.setRequired("Enter Email");
          this.form.elmts.email.setHelper(null);
          this.form.elmts.phone.setRequired(false);
          this.form.elmts.phone.setHelper("Optional");
          break;
        case this.props.app.dm.manualEntryUser.MFA_TYPE_SMS:
          this.form.elmts.email.setRequired(false);
          this.form.elmts.email.setHelper("Optional");
          this.form.elmts.phone.setRequired("Enter Phone");
          this.form.elmts.phone.setHelper(null);
          break;
        default:
          this.form.elmts.email.setRequired(false);
          this.form.elmts.email.setHelper("Optional");
          this.form.elmts.phone.setRequired(false);
          this.form.elmts.phone.setHelper("Optional");
      }
      this.setState({ mfaType: value });
    }

    // async handlePipelineValueChange(value) {
    //   this.props.app.ui.progressIndicator.show();

    //   if (!value) {
    //     this.setState({ pipelineBlockSelections: [] });
    //     return false;
    //   }
    //   let pipelineBlockSelections =
    //     await this.props.app.dm.pipeline.fetchBlockSelections({
    //       pipelineUid: value,
    //     });
    //   this.props.app.ui.progressIndicator.hide({ timeout: 750 });
    //   this.setState({ pipelineBlockSelections });
    // }
    // handlePermissionsItemEditPress(values) {
    //   this.handlePipelineValueChange(values.parentUid);
    // }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    get form() {
      return this.props.app.ui.form("manualEntryUserForm");
    }

    // handlePermissionsRenderer(values) {
    //   if (
    //     !this.pipelineMap[values.parentUid] ||
    //     !this.pipelineMap[values.parentUid].pipelineBlocks[values.uid]
    //   )
    //     throw new Error("Pipeline map render error");
    //   return (
    //     <View>
    //       <View style={styles.permissionItemRow}>
    //         <LabelView
    //           style={styles.permissionItemLabelView}
    //           key='pipeline'
    //           label='Pipeline'
    //         >
    //           <Text>{this.pipelineMap[values.parentUid].name}</Text>
    //         </LabelView>
    //         <LabelView
    //           style={styles.permissionItemLabelView}
    //           key='pipelineBlock'
    //           label='Pipeline Block'
    //         >
    //           <Text>
    //             {
    //               this.pipelineMap[values.parentUid].pipelineBlocks[values.uid]
    //                 .name
    //             }
    //           </Text>
    //         </LabelView>
    //       </View>
    //     </View>
    //   );
    // }

    // handlePermissionsDialogSubmit(form) {
    //   let perm = form.values;
    //   // for (let perm of this.form.values["permissions"]) {
    //   // Check for block in selections
    //   if (
    //     !perm.type ===
    //     this.props.app.dm.manualEntryUser.PERMISSION_TYPE_PIPELINE_BLOCK
    //   )
    //     return false;
    //   if (!this.pipelineMap[perm.parentUid])
    //     throw new Error("Pipeline map error.");
    //   if (!this.pipelineMap[perm.parentUid].pipelineBlocks[perm.uid]) {
    //     for (let pipelineBlock of this.state.pipelineBlockSelections) {
    //       if (pipelineBlock.value === perm.uid) {
    //         this.pipelineMap[perm.parentUid].pipelineBlocks[perm.uid] = {
    //           uid: pipelineBlock.value,
    //           name: pipelineBlock.label,
    //         };
    //         break;
    //       }
    //     }
    //     if (!this.pipelineMap[perm.parentUid].pipelineBlocks[perm.uid])
    //       throw new Error("Pipeline block map error.");
    //   }
    //   // this.setState({ pipelineBlockSelections: [] });
    //   return true;
    // }
    // handlePermissionsDialogClose() {
    //   this.setState({ pipelineBlockSelections: [] });
    // }

    async handleSubmit(form) {
      let editMethod = this.isEditMode ? "update" : "create";
      let ret = null;
      this.props.app.ui.progressIndicator.show();
      try {
        let isSuccess = await this.props.app.dm.manualEntryUser[editMethod](
          form.values
        );
        if (isSuccess) {
          let dataTable = this.props.refreshDataTable || "manualEntryUsers";
          this.props.app.ui.dataTable.exists(dataTable) &&
            (await this.props.app.ui.dataTable(dataTable).refresh());
          ret = <Redirect to={this.props.redirect || "manualEntryUsers"} />;
        }
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
    }
    render() {
      return (
        <Form
          name='manualEntryUserForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton
              title={this.isEditMode ? "Update" : "Create"}
              key='submit'
            />,
          ]}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}
          <FieldGroup>
            <TextInputField
              name='username'
              label='Username'
              required='Enter Username'
              validator='username'
            />
            <TextInputField name='name' label='Name' required='Enter Name' />
          </FieldGroup>
          <FieldGroup>
            <TextInputField
              name='password'
              label={this.isEditMode ? "Change Password" : "Password"}
              secure
              required={!this.isEditMode && "Enter Password"}
              autoComplete={false}
            />
            <SelectMenuField
              useValuesOnly
              name='mfaType'
              label='Multi-Factor Auth'
              items={this.formData.mfaTypeSelections}
              onValueChange={this.handleMfaTypeChange}
            />
          </FieldGroup>

          <FieldGroup>
            <TextInputField
              name='email'
              label='Email'
              validator='email'
              helper='Optional'
            />
            <TextInputField name='phone' label='Phone' helper='Optional' />
          </FieldGroup>
          <FieldGroup>
            <SelectMenuField
              useValuesOnly
              name='source'
              label='Source'
              items={this.formData.sourceSelections}
              required='Please select source'
            />
            <CountrySelectMenuField
              searchable
              autoLoad
              currencyUid={true}
              //required='Please choose at least 1 country.'
              //helper='Required'
            />
          </FieldGroup>
          {/* <SwitchField
            name='manualEntryVisible'
            label='Enable Manual Entry'
            value={this.state.manualEntryVisible}
            onValueChange={this.handleManualEntryVisibleChange}
          /> */}
          <SelectMenuField
            useValuesOnly
            name='manualEntryConfigUid'
            label='Manual Entry'
            items={this.formData.manualEntryConfigSelections}
            onValueChange={this.handleManualEntryVisibleChange}
            // required={
            //   this.state.manualEntryVisible
            //     ? "Please select a manual entry config."
            //     : false
            // }
          />
          <FadeView
            visible={this.state.manualEntryVisible}
            // unmountOnExit={true}
          >
            <Surface style={styles.sectionSurfaceStyle}>
              <SelectMenuField
                useValuesOnly
                multiple
                name='modes'
                label='Modes'
                items={this.formData.modeSelections}
                labelStyle={styles.surfaceLabel}
                required={
                  this.state.manualEntryVisible
                    ? "Please select a mode."
                    : false
                }
              />
              <FieldGroup>
                <TextInputField
                  name='queuedTimeoutSeconds'
                  label={"Queued Item Timeout"}
                  labelStyle={styles.surfaceLabel}
                  // required={true}
                  helper='Seconds'
                />
              </FieldGroup>
            </Surface>
          </FadeView>
          {/* <SwitchField
            name='virtualWorkspaceVisible'
            label='Enable Virtual Workspace(s)'
            value={this.state.virtualWorkspaceVisible}
            onValueChange={this.handleVirtualWorkspaceVisibleChange}
          /> */}
          <SelectMenuField
            useValuesOnly
            name='virtualWorkspaceConfigUid'
            label='Virtual Workspace'
            items={this.formData.virtualWorkspaceConfigSelections}
            required={
              this.state.virtualWorkspaceVisible
                ? "Please select a virtual workspace."
                : false
            }
          />
          <SwitchField name='active' label='Is Active' value={true} />
        </Form>
      );
    }
  }
);
const styles = StyleSheet.create({
  sectionSurfaceStyle: {
    ...StyleSheet.padding(16, 8, 0, 8),
    ...StyleSheet.margin(-16, -8, 24, -8),
  },
  surfaceLabel: {
    backgroundColor: Colors.surface,
  },
});
