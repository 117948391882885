import React from "react";
import R14, { Colors, StyleSheet, Text, Image, View } from "../core";
import EntityDataTable from "./EntityDataTable";
import StateIndicatorText from "./StateIndicatorText";

export default R14.connect(
  class VirtualWorkspaceDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.pageLoader = this.pageLoader.bind(this);
      this.perms = this.props.app.dm.user.getPermissions(
        "admin",
        "virtualWorkspace"
      );
    }
    async pageLoader(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      if (search) filter.search = { like: `%${search}%` };
      let res = await this.props.app.dm.virtualWorkspace.find(
        `
        uid
        name
        stackName
        fleetName
        state
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    render() {
      console.log("CHECK ENTITY", this.props.app.dm.virtualWorkspace);
      return (
        <EntityDataTable
          entityDomain={this.props.app.dm.virtualWorkspace}
          searchable
          title='Workspaces'
          name='virtualWorkspaces'
          columns={{
            name: {
              label: "Name",
              sortable: true,
            },
            stackName: {
              label: "Stack",
              sortable: true,
            },
            fleetName: {
              label: "Fleet",
              sortable: true,
            },
            state: {
              label: "Status",
              sortable: true,
              renderer: ({ cell }) => (
                <StateIndicatorText
                  label={this.props.app.dm.virtualWorkspace.getStateLabel(cell)}
                  color={this.props.app.dm.virtualWorkspace.getStateIndicatorColor(
                    cell
                  )}
                />
              ),
            },
          }}
          onRowPress={({ uid }) =>
            this.props.app.nav.to("virtualWorkspace", { uid })
          }
          cellRenderer={({ cell, columnName, row, columns }) => (
            <Text style={[styles.cellText]}>{cell}</Text>
          )}
          pageLoader={this.pageLoader}
          initialSortColumnName='name'
          initialSortDirection='asc'
          perms={this.perms}
          autoRefresh={30}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {},
});
