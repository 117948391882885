import React from "react";
import PropTypes from "prop-types";
import R14 from "../R14";
import ControlsView from "./ControlsView";
import View from "./View";
import Text from "./Text";
import FormBase from "./base/FormBase";
import { Provider } from "mobx-react";
import StyleSheet from "./StyleSheet";
import { Colors } from "./Theme";
/**
 * Enhanced form component
 */
export const Form = R14.connect(
  class Form extends React.Component {
    static propTypes = {
      /** Unique name for the form, used as a key in app.ui.form(name) */
      name: PropTypes.string.isRequired,
      /**
       * Callback for when the form is submitted. If the result is false or a redirect component, the form will not be submitted to the to prop. This is called with following parameters:
       *
       * @param {Object} form The submitted form object
       */
      onSubmit: PropTypes.func,
      /** The route the form should be submitted to. Can either be a route name, or a route object containing route and params. If neither onSubmit or the to prop is given, the form will submit to it's own action. */
      to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      /** If set to true, the form will validate before submission or calling onSubmit */
      validateBeforeSubmit: PropTypes.bool,
      /** Key value array of form element values. This is a controlled input. */
      values: PropTypes.object,
      /** Key value array of the initial form element values. */
      initialValues: PropTypes.object,
      /** The background color to be applied to the form and elements. */
      backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      /** The onBeforeNavigation prop allows navigation to be suspended. If a string is given, a confirm will prompt the user before allowing navigation. If a function is given (async or sync) and returns false, navigation is canceled. */
      onBeforeNavigation: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    };
    static defaultProps = {
      validateBeforeSubmit: false
    };
    constructor(props) {
      super(props);
      this.form = this.props.app.ui.form.create(props);
    }
    componentDidMount() {
      this.form.handleMount(this.props.initialValues || this.props.values || {});
    }
    componentDidUpdate(prevProps) {
      if (prevProps.values !== this.props.values)
        this.form.setValues(this.props.values || {});
    }
    componentWillUnmount() {
      if (
        !this.props.app.ui.form.submittedForm ||
        this.props.app.ui.form.submittedForm.name !== this.form.name
      ) {
        this.form.remove();
      }
    }
    dynamicStyles() {
      return StyleSheet.create({
        form: {
          backgroundColor: this.props.backgroundColor || Colors.onSurface
        }
      });
    }
    render() {
      return (
        <Provider form={this.form}>
          <FormBase autoComplete={this.props.autoComplete} style={[styles.form, this.dynamicStyles.form, this.props.style]}>
            {this.form.state.errors.length > 0 && (
              <View>
                {this.form.state.errors.map((error, i) => (
                  <View key={`${error}-${i}`} style={styles.error}>
                    <Text style={styles.errorText}>{error}</Text>
                  </View>
                ))}
              </View>
            )}
            {this.form.state.redirect}
            {this.props.children}
            {this.props.controlsBottomRight && (
              <ControlsView
                align='right'
                type={this.props.controlsBottomRightType || "buttons"}
              >
                {this.props.controlsBottomRight}
              </ControlsView>
            )}
          </FormBase>
        </Provider>
      );
    }
  }
);
export default Form;
const styles = StyleSheet.create({
  form: {
    ...StyleSheet.padding(24, 16, 16, 16),
    flex: 1,
    flexDirection: 'column'
  },
  errors: {},
  error: {
    paddingBottom: 12
  },
  errorText: { color: Colors.error }
});
