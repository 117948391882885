import React from "react";
import R14, {
  Redirect,
  FieldGroup,
  HiddenInputField,
  BackButton,
  Form,
  SubmitButton,
  TextInputField,
  SelectMenuField,
  ScrollView,
} from "../core";
export default R14.connect(
  class ProjectEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.resourceItemLoader = this.resourceItemLoader.bind(this);
      this.userItemLoader = this.userItemLoader.bind(this);
      this.datasetItemLoader = this.datasetItemLoader.bind(this);
      this.formData = this.props.formData || {};
      console.log(this.formData.values.type);
      this.isEditMode =
        this.formData.values && this.formData.values.uid ? true : false;
      this.hasType =
        this.formData.values && this.formData.values.type ? true : false;

      console.log(this.hasType);
    }
    async handleSubmit(form) {
      let editMethod = this.isEditMode ? "update" : "create";
      let ret = null;
      this.props.app.ui.progressIndicator.show();
      try {
        let isSuccess = await this.props.app.dm.project[editMethod](
          form.values
        );
        if (isSuccess) {
          let dataTable = this.props.refreshDataTable || "projects";
          let redirect = this.hasType
            ? {
                route: "projects",
                params: {
                  key: this.props.app.dm.project.getKeyByType(
                    this.formData.values.type
                  ),
                },
              }
            : "projects";
          this.props.app.ui.dataTable.exists(dataTable) &&
            (await this.props.app.ui.dataTable(dataTable).refresh());
          ret = <Redirect to={this.props.redirect || redirect} />;
        }
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      this.props.app.ui.snackBar.show({
        message: "Your project has been saved.",
        variant: "success",
      });
      return ret;
    }
    async resourceItemLoader(filters) {
      return await this.props.app.dm.project.fetchResourceSelections(filters);
    }
    async userItemLoader(filters) {
      return await this.props.app.dm.project.fetchUserSelections(filters);
    }
    async datasetItemLoader(filters) {
      return await this.props.app.dm.project.fetchDatasetSelections({
        ...filters,
        type: this.formData.values.type,
      });
    }
    render() {
      return (
          <Form
            name='projectForm'
            onSubmit={this.handleSubmit}
            validateBeforeSubmit
            initialValues={this.formData.values}
            controlsBottomRight={[
              <BackButton title='Cancel' key='cancel' variant='text' />,
              <SubmitButton
                title={this.isEditMode ? "Update" : "Create"}
                key='submit'
              />,
            ]}
            // onBeforeNavigation="You have unsaved changes, are you sure you want to leave?"
            // onBeforeNavigation={async (form) => {
            //   return await form.validate();
            // }}
            // onBeforeNavigation={(form) => {
            //   return await form.validate();
            // }}
          >
            {this.isEditMode && <HiddenInputField name='uid' />}
            <FieldGroup>
              <TextInputField
                name='name'
                label='Name'
                required='Please enter a name.'
                autoFocus
              />
              <TextInputField
                name='key'
                label='Key'
                required='Please enter a key.'
                helper='Used as remote folder name'
              />
            </FieldGroup>
            {this.hasType && <HiddenInputField name='type' />}
            {!this.hasType && (
              <SelectMenuField
                useValuesOnly
                name='type'
                label='Type'
                items={this.formData.typeSelections}
              />
            )}
            <TextInputField name='description' label='Description' />
            {this.formData.resourceSelections.length ? (
              <SelectMenuField
                name='resources'
                label='Resources'
                multiple
                searchable
                itemLoader={this.resourceItemLoader}
                items={this.formData.resourceSelections}
              />
            ) : null}
            {this.formData.userSelections.length ? (
              <SelectMenuField
                name='users'
                label='Users'
                multiple
                searchable
                itemLoader={this.userItemLoader}
                items={this.formData.userSelections}
              />
            ) : null}
            {this.hasType && this.formData.datasetSelections.length ? (
              <SelectMenuField
                name='datasets'
                label='Datasets'
                multiple
                searchable
                itemLoader={this.datasetItemLoader}
                items={this.formData.datasetSelections}
              />
            ) : null}
          </Form>
      );
    }
  }
);
