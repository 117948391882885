import R14 from "./R14";
import Platform from "./components/Platform";

export default class R14Route extends R14.Domain {
  constructor(
    name,
    config,
    portalName,
    location = null,
    match = null,
    navigation = null,
    route = null
  ) {
    super();
    this._name = name;
    this._data = {
      params: match ? match.params : {},
      query: {},
      state: {},
      forms: {},
    };
    this._app = R14.getInstance().app;
    this._portal = portalName;
    this._location = location || null;
    this._navigation = navigation || {};
    this._routeKey = Math.random();



    this._path = location && location.pathname;

    switch (Platform.OS) {
      case "web":
        if (location) {
          if (location.state) {
            for (let prop in location.state) {
              if (prop === "_r14") continue;
              this._data.state[prop] = location.state[prop];
            }
          }
          if (location.search) {
            let searchParams = new URLSearchParams(
              location.search.substring(1)
            );
            for (let key of searchParams.keys()) {
              //console.log("portal keys", key, searchParams.get(key));
              this._data.query[key] = searchParams.get(key);
            }
          }
        }
        /** @todo Abstract the data from platforms. Match is web, navigation is native */
        let params = config.initialParams || {};
        let currentParams = {};
        if (match && match.params) {
          currentParams = match.params;
          // for (let param in match.params) {
          //   console.log("portal match key", match.params[param], `:${param}`);
          //   if (match.params[param] !== `:${param}`)
          //     params[param] = match.params[param];
          // }
          //params = { ...params, ...match.params };
        } else if (navigation && navigation.state.params) {
          currentParams = navigation.state.params;
          //   console.log('params in history', navigation.state.params);
          //    if (match.params[param] !== `:${param}`)
          //       params[param] = match.params[param];
          //   params = { ...params, ...navigation.state.params };
          //   //this._data.params = navigation.state.params;
        }
        for (let param in currentParams) {
          if (currentParams[param] !== `:${param}`)
            params[param] = currentParams[param];
        }
        this._data.params = params;

        break;
      default:
        // let portal = this._r14.navigation.getPortalByRouteName(name);
        if (route) {
          this._data.params = route.params ? route.params : {};
        }

        break;
    }

    if (this.ui.form.activeRouteName === this._name) {
      let submittedForm = this.ui.form.submittedForm;
      this._data.forms[submittedForm.name] = submittedForm;
    }
    this._config = this._parseConfig(config || {});
  }

  // get data(){
  // 	return this._data;
  // }
  createPath(pathTemplate, data) {
    let ret = pathTemplate;
    for (let param in data) {
      let key = `:${param}`;
      if (ret.indexOf(key) !== -1) {
        ret = ret.replace(key, data[param]);
      }
    }
    return ret;
  }
  _parseConfig(initialConfig) {
    let config = { ...initialConfig };
    if (
      initialConfig.metadata &&
      typeof initialConfig.metadata === "function"
    ) {
      config.metadata = initialConfig.metadata({
        route: this,
        app: this._app,
      });
    }
    if (initialConfig.options) {
      config.options =
        typeof initialConfig.options === "function"
          ? initialConfig.options({ route: this, app: this._app })
          : config.options;
      config = { ...config, ...config.options };
      delete config.options;
    }
    return config;
  }
  get component() {
    return this._component;
  }
  setComponent(component) {
    this._component = component;
    return this;
  }
  get location() {
    return this._location;
  }
  get portal() {
    return this._portal;
  }
  get params() {
    return this._data.params;
  }
  get forms() {
    return this._data.forms;
  }
  get query() {
    return this._data.query;
  }
  get state() {
    return this._data.state;
  }
  get qry() {
    return this.query;
  }
  get data() {
    return {
      ...this.metadata,
      ...this.state,
      ...this.query,
      ...this.params,
      ...this.forms,
    };
  }
  get dt() {
    return this.data;
  }
  get path() {
    return this._path;
  }
  get name() {
    return this._name || null;
  }
  get metadata() {
    return this._config.metadata || {};
  }
}
