import React from "react";
import View from "./View";
import R14 from "../R14";
// import IconMap from "../../config/icons.config";
import StyleSheet from "./StyleSheet";
import { Colors } from "./Theme";
import { MaterialCommunityIcons, MaterialIcons } from "./base/IconBase";

export default class Icon extends React.Component {
  constructor(props) {
    super(props);
    this._iconMap = R14.getInstance().app.icons || null;
    // if (!this._iconMap[this.props.name])
    //   throw `Icon ${this.props.name} not found...`;
  }
  getComponent(family) {
    switch (family) {
      case "MaterialCommunityIcons":
        return MaterialCommunityIcons;
        break;
      case "MaterialIcons":
        return MaterialIcons;
        break;
      default:
        throw `Icon family ${this.props.family || "NULL"} not found`;
    }
  }
  getSize(size) {
    if(typeof size === 'number') return size;
    let ret = null;
    switch (size) {
      case "small":
        ret = 20;
        break;
      case "medium":
        ret = 24;
        break;
      case "large":
        ret = 32;
        break;
      case "huge":
        ret = 40;
        break;
      default:
        ret = 24;
    }
    return ret;
  }
  render() {
   
    let styles = this.styles();
    if (!this._iconMap)
      throw new Error("Icon Error: Icon map not found in app config.");
    if (!this.props.name) throw new Error("Icon requires a name");
    if (!this._iconMap[this.props.name]) throw new Error(`Icon Not Found: ${this.props.name}`);
    let Component = this.getComponent(this._iconMap[this.props.name].family);
    let size = this.getSize(this.props.size);
    return (
      <View
        style={[styles.container, this.props.style]}
        tooltip={this.props.tooltip || null}
        htmlTag='i'
      >
        <Component
          size={size}
          name={this._iconMap[this.props.name].name}
          color={this.props.color || Colors.onBackground}
        />
      </View>
    );
  }
  styles() {
    let size = this.getSize(this.props.size);
    return StyleSheet.create({
      container: {
        flex: 0,
        height: size,
        width: size,
        // backgroundColor: "red"
      },
    });
  }
}
