import R14 from "../core";
export default class AppModuleBuildDomain extends R14.EntityDomain {
  constructor(key) {
    super({
      name: "appModuleBuild",
    });
    this.STATE_INIT = "INIT";
    this.STATE_ERROR = "ERROR";
    this.STATE_BUILDING = "BUILDING";
    this.STATE_COMPLETE = "COMPLETE";
    this.STATE_DELETE = "DELETE";
    this.STATE_DELETING = "DELETING";
    this.state = {};
  }
  async get(uid, fields) {
    if (!fields) fields = [("uid", "version", "appModuleUid", "description")];
    return await super.get(uid, fields);
  }
  async delete(uid) {
    let res = await this.update(
      {
        uid: uid,
        state: this.STATE_DELETE,
      },
      { parse: false }
    );
    return res && res.data && res.data.success;
  }
  async fetchEditFormData(uid = null, options = {}) {
    let formVals = {};
    if (options.projectUid) formVals.projectUid = options.projectUid;
    let qry = "";
    let qryVals = {
      userFilter: { clientUid: { eq: this.dm.userSession.clientUid } },
    };
    if (uid) {
      qry = `
      query AppModuleBuildEditFormData($uid: ID!) {
        appModuleBuild(uid: $uid){
          uid
          version
          description
          projectUid
          appModule {
            uid
            name
          }
        }
      }
    `;
      qryVals.uid = uid;
      let res = await this.api.qry(qry, qryVals);
      formVals = {
        uid: res.data.appModuleBuild.uid,
        version: res.data.appModuleBuild.version,
        description: res.data.appModuleBuild.description,
        projectUid: res.data.appModuleBuild.projectUid,
        appModule: {
          value: res.data.appModuleBuild.appModule.uid,
          label: res.data.appModuleBuild.appModule.name,
        },
      };
      console.log("RES DATA APP MODULE BUILD", res.data);
    } else {
    }

    let formData = {
      values: formVals,
    };
    console.log("RES RES RES RES", formData);
    return formData;
  }

  async create(values, options = {}) {
    let input =
      options.parse === false ? values : this.parseFormValues(values, options);
    let res = await this.api.mutate(
      `
      mutation CreateAppModuleBuild($input: CreateAppModuleBuildInput!) {
        createAppModuleBuild(input: $input){
          appModuleBuild {
            uid
            description
          }
          success
        }
      }`,
      {
        input: input,
      }
    );
    return res.errors.length
      ? { success: false, errors: res.errors }
      : { success: true, block: res.data.createAppModuleBuild.appModuleBuild };
  }
  async update(values, options = {}) {
    let input =
      options.parse === false ? values : this.parseFormValues(values, options);
    let res = await this.api.mutate(
      `
      mutation UpdateAppModuleBuild($input: UpdateAppModuleBuildInput!) {
        updateAppModuleBuild(input: $input){
          appModuleBuild {
            uid
            description
          }
        }
      }`,
      {
        input: input,
      }
    );
    return res.errors.length
      ? { success: false, errors: res.errors }
      : { success: true, block: res.data.updateAppModuleBuild.appModuleBuild };
  }
  parseFormValues(values) {
    let ret = {
      clientUid: this.dm.userSession.clientUid,
      projectUid: values.projectUid,
      version: values.version,
      description: values.description || null,
      appModuleUid: values.appModule.value,
    };
    if (values.uid) ret.uid = values.uid;
    console.log("CHECK VALS?", values, ret);
    return ret;
  }
  getStateLabel(state, resourceState) {
    let label = null;
    switch (state) {
      case this.STATE_INIT:
        label = "Initializing";
        break;
      case this.STATE_BUILDING:
        label = "Building";
        break;
      case this.STATE_DELETING:
      case this.STATE_DELETE:
        label = "Deleting";
        break;
      case this.STATE_COMPLETE:
        label = "Complete";
        break;
      case this.STATE_ERROR:
        label = "Error";
        break;
    }
    return label;
  }
  getStateIndicatorColor(state) {
    let color = "yellow";
    switch (state) {
      case this.STATE_INIT:
      case this.STATE_BUILDING:
        color = "yellow";
        break;
      case this.STATE_COMPLETE:
        color = "green";
        break;
      case this.STATE_DELETING:
      case this.STATE_DELETE:
      case this.STATE_ERROR:
        color = "red";
        break;
    }
    return color;
  }
}
