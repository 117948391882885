import React from "react";
import R14 from "../R14";
import FormValidators from "./FormValidators";

export default class FormUiDomain extends R14.DomainInstances {
  constructor() {
    super();
    /**
     * Submitted form wil not be removed on dismount
     * It will be available as a parameter for the routes action
     * and is cleaned up once that route is rendered
     * */
    this._submittedForm = null;
    this.state = {
      activeRouteName: null,
    };
  }
  create(props) {
    let form = new FormUiDomainInstance(props, this);
    this.addInstance(props.name, form);
    return form;
  }
  remove(name) {
    this.removeInstance(name);
  }
  setSubmittedForm(form) {
    this._submittedForm = form;
    this.setState({
      activeRouteName: form.to.route,
    });
  }
  clearSubmittedForm() {
    this._submittedForm = null;
    this.setState({
      activeRouteName: null,
    });
  }
  get submittedForm() {
    return this._submittedForm;
  }
  get activeRouteName() {
    return this.state.activeRouteName;
  }
}

export class FormUiDomainInstance extends R14.Domain {
  constructor(props, forms) {
    super();
    if (!props.name) throw "Form Error: Form must have a name.";
    this.state = {
      redirect: false,
      errors: [],
    };
    this._name = props.name;
    this._to = props.to || null;
    this._values = false;
    this._initialValues = {};
    this._activeColor = props.activeColor || null;
    this._onSubmit = props.onSubmit || null;
    this._onBeforeNavigation = props.onBeforeNavigation || null;
    this._validateBeforeSubmit = props.validateBeforeSubmit || false;
    this._onValueChange = props.onValueChange || null;
    this._backgroundColor = props.backgroundColor || null;
    this._isValid = false;
    this._hasMounted = false;
    this._defaultValidators = FormValidators;
    this._validators = [];
    this._forms = forms;
    this._valid = true;
    this._submitting = false;
    this._app = props.app;
    this.elements = this.elmts = new FormElementUiDomain(this);
    if (props.validators) {
      this.addValidators(props.validators);
    }
  }
  remove() {
    this._forms.remove(this._name);
  }
  addElement(props, options = {}) {
    let ret = this.elements.add(props, options);
    return ret;
  }
  addValidators(validators) {
    for (let name in validators) {
      this._validators[name] = validators[name];
    }
  }
  removeElement(name) {
    return this.elements.remove(name);
  }
  triggerValueChange(value, element) {
    if (this._onValueChange) this._onValueChange(value, element, this);
  }
  async submit(options = {}) {
    if (this.submitting) return false;
    // Validation done in the route action
    this.setSubmitting(true);
    this.clearErrors();
    let to = this.to;
    let ret = true;
    // Check if the form should validate before submission
    if (this._validateBeforeSubmit && !(await this.validate())) {
      ret = false;
    } else if (this._onSubmit) {
      // If on submit returns false do not continue
      let onSubmitRes = await this._onSubmit(this, options);
      /** @todo Form Domain: Redirecting like this will just put whatever element on top... */
      if (onSubmitRes) {
        if (React.isValidElement(onSubmitRes)) {
          // The form component will automatically include redirect at the top of the form
          this.setState({
            redirect: onSubmitRes,
          });
          ret = false;
        } else throw new Error("Unknown type returned from ");
      } else if (onSubmitRes === false) ret = false;
    }

    if (!ret) {
      this.setSubmitting(false);
      return false;
    }

    this._forms.setSubmittedForm(this);
    if (to.route !== this._app.route.name) {
      // if not submitting to self, push the request

      this._app.nav.to(to);
    } else this._app.nav.replace(to);

    this.setSubmitting(false);
  }
  toFormData() {
    let formData = new FormData();
    let formMetadata = {
      name: this.name,
      elmts: {},
    };
    this.elmts.forEach((elmt) => {
      //if (!elmt.doSubmit) continue;
      formMetadata.elmts[elmt.name] = {
        // type: elmt.type
      };
      formData.append(elmt.name, elmt.value);
    });
    formData.append("_r14FormMetadata", JSON.stringify(formMetadata));
    return formData;
  }
  get name() {
    return this._name;
  }
  get activeColor() {
    return this._activeColor;
  }
  get theme() {
    return this._theme;
  }
  get onBeforeNavigation() {
    return this._onBeforeNavigation;
  }
  get backgroundColor() {
    return this._backgroundColor;
  }
  get to() {
    /** @todo this is kind of confusing having it be route.route */
    let to = null;
    // Check for to in form
    if (this._to) {
      if (typeof this._to === "object") to = this._to;
      else
        to = {
          route: this._to,
        };
    }
    // If missing default to current page
    else {
      let route = this._app.route;
      // console.log("form to route",route);
      to = {
        route: this._app.route.name,
        params: route ? { ...route.query, ...route.params } : {},
      };
      this._to = to;
    }
    return to;
  }
  get routeName() {
    return this.to.route;
  }
  get values() {
    let ret = {};
    this.elements.forEach((elmt) => {
      ret[elmt.name] = elmt.parseValue();
    });
    return ret;
  }
  get initialValues() {
    return this._initialValues;
  }
  get hasMounted() {
    return this._hasMounted;
  }
  get isSubmitting() {
    return this._submitting;
  }
  get submitting() {
    return this._submitting;
  }
  setSubmitting(submitting) {
    this._submitting = submitting;
    return this;
  }
  handleMount(values) {
    this._hasMounted = true;
    return this.setInitialValues(values);
  }
  setInitialValues(values) {
    this._initialValues = values;
    this.setValues(values);
    return this;
  }
  setValues(values) {
    for (let name in values) {
      if (!this.elmts[name]) continue;
      this.elmts[name].setValue(values[name]);
    }
    this.clearErrors();
    return this;
  }
  reset() {
    this.elements.forEach((elmt) => {
      elmt.setValue(null);
    });
    this.clearErrors();
    return this;
  }
  get errors() {
    return this.state.errors;
  }
  get validators() {
    return this._validators;
  }
  setErrors(errors) {
    this.setState({ errors: errors });
  }
  clearErrors() {
    this.setErrors([]);
  }
  addError(error) {
    let errors = this.state.errors;
    errors.push(error);
    this.setErrors(errors);
  }
  addErrors(errors) {
    this.setErrors({ ...this.state.errors, ...errors });
  }
  async isValid() {
    return this._isValid;
  }
  async validate() {
    this._valid = true;
    let elmts = this.elmts.getInstances();
    for (let n in elmts) {
      let elmt = elmts[n];
      await elmt.validate();
      // Update the form
      if (!elmt.isValid) {
        this._valid = false;
      }
    }
    return this._valid;
  }
}

export class FormElementUiDomain extends R14.DomainInstances {
  constructor(form) {
    super();
    this._form = form;
  }
  add(props, options = {}) {
    let element = new FormElementUiDomainInstance(this._form, props, options);
    this.addInstance(props.name, element);
    return element;
  }
  remove(name) {
    this.removeInstance(name);
    return this;
  }
}

export class FormElementUiDomainInstance extends R14.Domain {
  constructor(form, props, options = {}) {
    super();
    if (!props.name) throw "Form Error: Form element must have a name.";
    this._form = form;
    this._name = props.name;
    this._label = props.label || null;
    // this._helper = props.helper || null;
    // this._required = props.required || false;
    this._ref = React.createRef();
    this._eventListeners = {};
    this._valueLabels = {};
    this._valueParser = props.valueParser || null;
    this._useTextValue = options.useTextValue || false;

    let validators = props.validators || props.validator || false;
    if (
      validators &&
      !Array.isArray(validators) &&
      typeof validators !== "object"
    )
      validators = [validators];
    // Look for initial form value if the form already mounted
    let initialValue = null;
    if (this._form.hasMounted && this._form.initialValues[this._name]) {
      initialValue = this._form.initialValues[this._name];
    }

    this._validators = validators;
    this._itemRefMap = {};
    this.state = {
      valid: false,
      value: props.value || initialValue || null,
      textValue: props.textValue || null,
      focus: props.focus || false,
      focusItemValue: props.focusItemValue || null,
      blur: props.blur || false,
      disabled: props.disabled || false,
      autoFill: false,
      error: false,
      success: false,
      items: props.items || props.children || [],
      loading: false,
      loaded: false,
      helper: props.helper || null,
      required: props.required || null
    };
  }
  get form() {
    return this._form;
  }
  get value() {
    return this.state.value;
  }
  set value(value) {
    return this.setValue(value);
  }
  get textValue() {
    return this.state.textValue;
  }
  set textValue(value) {
    return this.setTextValue(value);
  }
  async validate(options = {}) {
    let val = options.useTextValue ? this.textValue : this.value;
    this.valid = false;
    this.error = false;
    this.success = false;

    // Check for non required
    if (!this.isRequired && !this.validator) {
      this.valid = true;
    }
    // Check if required
    else if (
      this.isRequired &&
      (!val ||
        (typeof val === "string" && val.trim() === "") ||
        (typeof val === "object" && val.length === 0))
    ) {
      this.valid = false;
      this.error =
        typeof this.isRequired === "string" ? this.isRequired : "Required";
    } else if (
      !this.isRequired &&
      (val === null || (typeof val === "string" && val.trim() === ""))
    ) {
      this.valid = true;
    }
    // Check if there are validators
    else if (this.validator) {
      let validator = {};
      // Check for string (single), array (no options) or object
      if (typeof this.validator === "string") validator[this.validator] = true;
      else if (Array.isArray(this.validator)) {
        this.validator.map((value) => {
          // if (typeof value === "string") validator[this.validator] = true;
          // else
          if (typeof value === "object") validator = { ...validator, ...value };
          else validator[value] = true;
        });
      } else validator = this.validator;

      for (let i in validator) {
        let opts = typeof validator[i] === "object" ? validator[i] : {};
        let validatorFn = null;
        if (!this.form._validators[i]) {
          if (this.form._defaultValidators[i]) {
            validatorFn = this.form._defaultValidators[i];
          } else throw "Unable to find validator: '" + i + "'";
        } else validatorFn = this.form._validators[i];

        let rslt = false;

        // Validate the value as an array
        if (!options.useTextValue && this._useTextValue && Array.isArray(val)) {
          let nVals = [];
          for (let i in val) {
            rslt = await validatorFn(val[i], opts, this, this.form);
            if (rslt === true || rslt.success) nVals.push(rslt.value || val[i]);
            else break;
          }

          // Automatically add text value.
          // if ((rslt === true || rslt.success) && this.textValue) {
          //   let textRslt = this.validate({ useTextValue: true });
          //   let textVal = rslt.value || val[i];
          //   if (textRslt !== true) rslt = textRslt;
          //   if (!this._unique || !nVals.includes(textVal))
          //     nVals.push(textVal);
          // }

          if (rslt === true || rslt.success) this.value = nVals;
        } else rslt = await validatorFn(val, opts, this, this.form);

        if (rslt) {
          if (rslt === true) {
            this.valid = true;
            this.error = "";
          } else if (rslt.error) {
            this.valid = false;
            this.error =
              typeof rslt.error === "string" ? rslt.error : "Not Valid";
            break;
          } else if (rslt.success) {
            this.valid = true;
            this.success = typeof rslt.success === "string" ? rslt.success : "";
          }
          if (rslt.value) {
            if (options.useTextValue) this.textValue = rslt.value;
            else if (!this._useTextValue) this.value = rslt.value;
          }
        } else {
          this.valid = false;
          this.error = "Unknown Validation Error";
          break;
        }
      }
    } else {
      this.valid = true;
    }

    // Update validation message
    if (this.isValid) this.error = false;
    else this.success = false;

    if (this.isValid) return true;
    else
      return {
        error: this.error,
      };
  }
  parseValue() {
    return this._valueParser ? this._valueParser(this.value) : this.value;
  }
  setValue(value) {
    let hasChanged = value !== this.state.value;
    this.setState({ value: value });
    if (hasChanged) this._triggerEventListener("change");
    return this;
  }
  setTextValue(textValue) {
    let hasChanged = textValue !== this.state.textValue;
    this.setState({ textValue: textValue });
    if (hasChanged) this._triggerEventListener("change");
    return this;
  }
  // Items for select, checkbox group, etc...
  get items() {
    return this.state.items;
  }
  set items(items) {
    return this.setItems(items);
  }
  setItems(items) {
    this.setState({ items: items });
    return this;
  }
  resetAutoLoad() {
    this.setState({
      items: [],
      loaded: false
    });
  }
  getItemRef(item) {
    if (!this._itemRefMap[item.value]) {
      this._itemRefMap[item.value] = React.createRef();
    }
    return this._itemRefMap[item.value];
  }
  setLoading(isLoading) {
    this.setState({ loading: isLoading });
  }
  get isLoading() {
    return this.state.loading;
  }
  get loading() {
    return this.state.loading;
  }
  setLoaded(loaded) {
    this.setState({ loaded });
  }
  get hasLoaded() {
    return this.state.loaded;
  }
  get loaded() {
    return this.state.loaded;
  }
  select(options = {}) {
    this.focus(options);
    this.ref.current && this.ref.current.select && this.ref.current.select();
    return this;
  }
  focus(options = {}) {
    return this.setFocus(true, options);
  }
  setFocus(focus, options = {}) {
    let type = focus ? "focus" : "blur";
    if (options.item) return this.setItemFocus(focus, options.item, options);
    this.ref.current && this.ref.current[type] && this.ref.current[type]();
    this.setState({ focus: focus, blur: !focus });
    this._triggerEventListener(type);
    return this;
  }
  get isFocused() {
    return this.state.focus;
  }
  // get blur() {
  //   return this.state.blur;
  // }
  blur(options = {}) {
    return this.setBlur(true, options);
  }
  setBlur(blur, options = {}) {
    if (options.item) return this.setItemBlur(options.item);
    return this.setFocus(!blur);
  }
  get isBlurred() {
    return this.state.blur;
  }
  itemFocus(item, options) {
    return this.setItemFocus(true, item, options);
  }
  setItemFocus(focus, item, options = {}) {
    if (!item) return false;
    let type = focus ? "focus" : "blur";
    this._itemRefMap[item.value].current &&
      this._itemRefMap[item.value].current[type] &&
      this._itemRefMap[item.value].current[type]();
    let focusState = { focus: focus, blur: !focus };
    focusState.focusItemValue = focus ? item.value : null;
    this.setState(focusState);
    this._triggerEventListener(type, { item });
    return this;
  }
  isItemFocused(item) {
    return item.value === this.state.focusItemValue;
  }
  itemBlur(item, options = {}) {
    return this.setItemBlur(item, options);
  }
  setItemBlur(item, options = {}) {
    return this.setItemFocus(false, item, options);
  }
  isItemBlurred(item) {
    return !this.isItemFocused(item);
  }

  setAutoFill(autoFill) {
    this.setState({
      autoFill: autoFill,
    });
  }
  get hasAutoFill() {
    return this.state.autoFill;
  }
  get name() {
    return this._name;
  }
  get label() {
    return this._label;
  }
  get disabled() {
    return this.state.disabled;
  }
  set disabled(disabled) {
    return this.setDisabled(disabled);
  }
  setDisabled(disabled) {
    this.setState({ disabled: disabled });
    disabled
      ? this._triggerEventListener("disabled")
      : this._triggerEventListener("enabled");
    return this;
  }
  get isDisabled() {
    return this.state.disabled;
  }
  get isRequired() {
    return this.state.required;
  }
  get required(){
    return this.state.required;
  }
  setRequired(required){
    console.log("setREquired", required);
    this.setState({required});
  }
  set required(required){
    this.setRequired(required);
  }
  get validator() {
    return this.validators;
  }
  get validators() {
    return this._validators;
  }
  set validators(validators) {
    this._validators = validators;
  }
  get isActive() {
    let val = this._useTextValue ? this.textValue : this.value;
    return this.hasAutoFill || this.isFocused || val ? true : false;
  }
  addValidators(validators) {
    for (let name in validators) {
      this._validators[name] = validators[name];
    }
  }
  setError(error) {
    this.setState({
      error: error,
    });
    if (error) this._triggerEventListener("error");
    return this;
  }
  set error(error) {
    return this.setError(error);
  }
  get error() {
    return this.state.error;
  }
  get helper() {
    return this.state.helper;
  }
  setHelper(helper){
    this.setState({helper});
  }
  set helper(helper){
    this.setHelper(helper);
  }
  setSuccess(success) {
    this.setState({
      success: success,
    });
    if (success) this._triggerEventListener("success");
    return this;
  }
  set success(success) {
    return this.setSuccess(success);
  }
  get success() {
    return this.state.success;
  }
  get valid() {
    return this.state.valid;
  }
  set valid(valid) {
    return this.setValid(valid);
  }
  setValid(valid) {
    this.setState({ valid: valid });
    if (valid) this._triggerEventListener("valid");
    return this;
  }
  get isValid() {
    return this.valid;
  }
  get ref() {
    return this._ref;
  }
  set ref(ref) {
    this._ref = ref;
    return this;
  }
  addEventListener(type, callback) {
    if (!this._eventListeners[type]) this._eventListeners[type] = [];
    this._eventListeners[type].push(callback);
  }
  _triggerEventListener(type) {
    if (this._eventListeners[type])
      this._eventListeners[type].forEach((callback) => {
        callback(type, this, this._form);
      });
  }
  remove() {
    this._form.removeElement(this._name);
  }
}
