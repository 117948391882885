import React from 'react';
import R14, { StyleSheet, ScreenLayout} from '../core';
import ManualEntryPricingConfigDataTable from '../components/ManualEntryPricingConfigDataTable';
export default R14.connect(
  class ManualEntryConfigScreen extends React.Component {
    constructor(props) {
      super(props);
      this.perms = this.props.app.dm.user.getPermissions(
        "admin",
        "manualEntryPricingConfig"
      );
    }
    render() {
      return (
        <ScreenLayout
          floatingActionButton={this.perms.create ? {
            to: "manualEntryPricingConfigCreate",
            icon: "add"
          } : null}
          scrollEnabled
        >
          <ManualEntryPricingConfigDataTable />
        </ScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({

});