import R14 from "../core";

export default class ManualEntryUserDomain extends R14.Domain {
  constructor(key) {
    super();
    this.PERMISSION_TYPE_PIPELINE_BLOCK = "PIPELINE_BLOCK";
    this.MODE_FIELD_CHARACTERS = "FIELD_CHARACTERS";
    this.MODE_CHARACTERS = "CHARACTERS";
    this.MODE_FIELD = "FIELD";
    this.MODE_DOCUMENT_SET_FIELDS = "DOCUMENT_SET_FIELDS";
    this.ACTIVE_MINUTES = "5";

    this.METRIC_TYPE_SUBMISSIONS = "SUBMISSIONS";
    this.METRIC_TYPE_KEYSTROKES = "KEYSTROKES";
    this.METRIC_TYPE_FIELD_UPDATES = "FIELD_UPDATES";
    this.METRIC_TYPE_CHARACTER_UPDATES = "CHARACTER_UPDATES";
    this.METRIC_RATE_MINUTE = "MINUTE";

    this.UPDATE_SOURCE_R14 = "R14";
    this.UPDATE_SOURCE_TELETYPE_PORTAL = "TELETYPE_PORTAL";

    this.MFA_TYPE_SMS = "SMS";
    this.MFA_TYPE_EMAIL = "EMAIL";

    this.SOURCE_FREELANCE = "FREELANCE";
    this.SOURCE_SALARY = "SALARY";

    this.state = {};
  }
  async find(fieldsStr, options = null) {
    // let fieldsStr = this.utils.gql.fieldsToString(fields);
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");
    // Add Client Filter
    if (!options.filter) options.filter = {};
    if (!options.totalCount) options.totalCount = false;
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let result = await this.api.qry(
      `
      query FindManualEntryUsers($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: ManualEntryUserFilter) {
        manualEntryUsers(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return result.data.manualEntryUsers;
  }
  async fetchEditFormData(uid = null) {
    let qryVals = {
      // pipelineFilter: {
      //   clientUid: { eq: this.dm.userSession.clientUid },
      // },
      // pipelineSort: [
      //   {
      //     field: "name",
      //     order: "ASC",
      //   },
      // ],
    };
    // if (!this.dm.user.hasAdminRole)
    //   qryVals.pipelineFilter["userUids"] = { eq: this.dm.userSession.uid };

    qryVals.manualEntryConfigSort = {
      field: "name",
      order: "ASC",
    };
    qryVals.manualEntryConfigFilter = {
      clientUid: { eq: this.dm.userSession.clientUid },
    };

    qryVals.virtualWorkspaceConfigSort = {
      field: "name",
      order: "ASC",
    };
    qryVals.virtualWorkspaceConfigFilter = {
      clientUid: { eq: this.dm.userSession.clientUid },
    };

    if (uid) {
      qryVals.uid = uid;

      // if (!this.dm.user.hasAdminRole)
      //   qryVals.pipelineBlockFilter = {
      //     userUids: { eq: this.dm.userSession.uid },
      //   };
    }
    let res = uid
      ? await this.api.qry(
          `
          query ManualEntryUserEditFormData($uid: ID!, $manualEntryConfigFilter: ManualEntryConfigFilter, $manualEntryConfigSort: [SortOption!]!, $virtualWorkspaceConfigFilter: VirtualWorkspaceConfigFilter, $virtualWorkspaceConfigSort: [SortOption!]!) {
            manualEntryUser(uid: $uid){
              uid
              name
              email
              phone
              username
              mfaType
              modes
              queuedTimeoutSeconds
              reviewTimeoutSeconds
              active
              manualEntryConfigUid
              virtualWorkspaceConfigUid
              source
              country {
                uid
                name
                currencyUid
              }
            }
            manualEntryConfigs(filter: $manualEntryConfigFilter, sort: $manualEntryConfigSort){
              nodes {
                uid
                name
              }
            }
            virtualWorkspaceConfigs(filter: $virtualWorkspaceConfigFilter, sort: $virtualWorkspaceConfigSort){
              nodes {
                uid
                name
              }
            }
          }`,
          qryVals
        )
      : await this.api.qry(
          `
          query ManualEntryUserCreateFormData($manualEntryConfigFilter: ManualEntryConfigFilter, $manualEntryConfigSort: [SortOption!]!, $virtualWorkspaceConfigFilter: VirtualWorkspaceConfigFilter, $virtualWorkspaceConfigSort: [SortOption!]!) {
            manualEntryConfigs(filter: $manualEntryConfigFilter, sort: $manualEntryConfigSort){
              nodes {
                uid
                name
              }
            }
            virtualWorkspaceConfigs(filter: $virtualWorkspaceConfigFilter, sort: $virtualWorkspaceConfigSort){
              nodes {
                uid
                name
              }
            }
          }`,
          qryVals
        );
    let formData = {
      values: {},
      modeSelections: [
        {
          label: "Characters",
          value: this.MODE_CHARACTERS,
        },
        {
          label: "Field",
          value: this.MODE_FIELD,
        },
        {
          label: "Field Characters",
          value: this.MODE_FIELD_CHARACTERS,
        },
        {
          label: "Document Set Fields",
          value: this.MODE_DOCUMENT_SET_FIELDS,
        },
      ],
      manualEntryConfigSelections:
        res.data.manualEntryConfigs && res.data.manualEntryConfigs.nodes
          ? res.data.manualEntryConfigs.nodes.map((val) => ({
              label: val.name,
              value: val.uid,
            }))
          : [],
      virtualWorkspaceConfigSelections:
        res.data.virtualWorkspaceConfigs &&
        res.data.virtualWorkspaceConfigs.nodes
          ? res.data.virtualWorkspaceConfigs.nodes.map((val) => ({
              label: val.name,
              value: val.uid,
            }))
          : [],
        mfaTypeSelections: [
          {
            label: "No Multi-Factor Auth",
            value: null,
          },
          {
            label: "SMS",
            value: this.MFA_TYPE_SMS,
          },
          {
            label: "Email",
            value: this.MFA_TYPE_EMAIL,
          }
        ],
        sourceSelections: [
          {
            label: "Salary",
            value: this.SOURCE_SALARY,
          },
          {
            label: "Freelance",
            value: this.SOURCE_FREELANCE,
          }
        ]
    };
    formData.manualEntryConfigSelections.length &&
      formData.manualEntryConfigSelections.unshift({
        label: "No Manual Entry",
        value: null,
      });
    formData.virtualWorkspaceConfigSelections.length &&
      formData.virtualWorkspaceConfigSelections.unshift({
        label: "No Virtual Workspace(s)",
        value: null,
      });

    let formVals = {};

    if (res.data) {
      // if (res.data.pipelines && res.data.pipelines.nodes) {
      //   res.data.pipelines.nodes.forEach((pipeline) => {
      //     formData.pipelineMap[pipeline.uid] = {
      //       uid: pipeline.uid,
      //       name: pipeline.name,
      //       pipelineBlocks: {},
      //     };
      //   });
      // }
      if (res.data.manualEntryUser) {
        let manualEntryUser = res.data.manualEntryUser;
        formVals = {
          username: manualEntryUser.username,
          email: manualEntryUser.email,
          phone: manualEntryUser.phone,
          uid: manualEntryUser.uid,
          name: manualEntryUser.name,
          active: manualEntryUser.active,
          mfaType: manualEntryUser.mfaType,
          source: manualEntryUser.source,
          modes: manualEntryUser.modes,
          queuedTimeoutSeconds: manualEntryUser.queuedTimeoutSeconds,
          reviewTimeoutSeconds: manualEntryUser.reviewTimeoutSeconds,
          manualEntryConfigUid: manualEntryUser.manualEntryConfigUid,
          virtualWorkspaceConfigUid: manualEntryUser.virtualWorkspaceConfigUid,
        };
        if (manualEntryUser.country && manualEntryUser.country.uid) {
          formVals.countryUid = {
            value: manualEntryUser.country.uid,
            label: manualEntryUser.country.name,
            currencyUid: manualEntryUser.country.currencyUid || null,
          };
        }
        formData.values = formVals;
        //   if (
        //     manualEntryUser.pipelineBlocks &&
        //     manualEntryUser.pipelineBlocks.nodes
        //   ) {
        //     manualEntryUser.pipelineBlocks.nodes.forEach((pipelineBlock) => {
        //       if (!formData.pipelineMap[pipelineBlock.pipelineUid]) return;
        //       formData.pipelineMap[pipelineBlock.pipelineUid].pipelineBlocks[
        //         pipelineBlock.uid
        //       ] = { uid: pipelineBlock.uid, name: pipelineBlock.name };
        //       formVals.permissions.push({
        //         type: this.PERMISSION_TYPE_PIPELINE_BLOCK,
        //         uid: pipelineBlock.uid,
        //         parentUid: pipelineBlock.pipelineUid,
        //       });
        //     });
        //   }
      }
    }
    return formData;
  }
  async create(values) {
    let res = await this.api.mutate(
      `
      mutation CreateManualEntryUser($input: CreateManualEntryUserInput!) {
        createManualEntryUser(input: $input){
          manualEntryUser {
            uid
            name
          }
        }
      }`,
      {
        input: this.parseFormValues(values),
      }
    );
    return true;
  }
  async update(values) {
    let res = await this.api.mutate(
      `
      mutation UpdateManualEntryUser($input: UpdateManualEntryUserInput!) {
        updateManualEntryUser(input: $input){
          manualEntryUser {
            uid
            name
          }
        }
      }`,
      {
        input: this.parseFormValues(values),
      }
    );

    return true;
  }
  parseFormValues(values) {
    let manualEntryConfigUid = values.manualEntryConfigUid || null;
    let queuedTimeoutSeconds = values.manualEntryConfigUid
      ? parseInt(values.queuedTimeoutSeconds)
      : null;
    let modes = values.manualEntryConfigUid ? values.modes : null;
    let virtualWorkspaceConfigUid = values.virtualWorkspaceConfigUid || null;

    let updateValues = {
      username: values.username,
      name: values.name,
      mfaType: values.mfaType,
      email: values.email || null,
      phone: values.phone || null,
      source: values.source,
      active: values.active,
      countryUid: values.countryUid ? values.countryUid.value : null,
      currencyUid: values.countryUid ? values.countryUid.currencyUid : null,

      clientUid: this.dm.userSession.clientUid,
      //pipelineBlockUids: [],
      reviewTimeoutSeconds: parseInt(values.reviewTimeoutSeconds),
      source: values.source || null,
      queuedTimeoutSeconds,
      manualEntryConfigUid,
      modes,
      virtualWorkspaceConfigUid,
      lastUpdateSource: this.UPDATE_SOURCE_R14,
    };
    if (values.uid) updateValues.uid = values.uid;
    if (values.password) updateValues.password = values.password;
    return updateValues;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteManualEntryUser($uid: ID!) {
        deleteManualEntryUser(uid: $uid){
          user {
            uid
            name
          }
        }
      }`,
      {
        uid: uid,
      }
    );
    return true;
  }
  isActive(lastCharacterSetCompleteAt) {
    let diffMinutes =
      (new Date() - lastCharacterSetCompleteAt.getTime()) / 60000;
    return diffMinutes <= this.ACTIVE_MINUTES;
  }
}
