import DashboardDomain from '../domains/DashboardDomain';
import CountryDomain from '../domains/CountryDomain';
import CurrencyDomain from '../domains/CurrencyDomain';
import ProjectDomain from '../domains/ProjectDomain';
import ResourceDomain from '../domains/ResourceDomain';
import ResourceScriptDomain from '../domains/ResourceScriptDomain';
import EventTaskDomain from '../domains/EventTaskDomain';
import ResourceStatusCheckDomain from '../domains/ResourceStatusCheckDomain';
import ResourceAlertDomain from '../domains/ResourceAlertDomain';
import EventDomain from '../domains/EventDomain';
import UserSessionDomain from '../domains/UserSessionDomain';
import ScriptDomain from '../domains/ScriptDomain';
import StatusCheckDomain from '../domains/StatusCheckDomain';
import LogDomain from '../domains/LogDomain';
import UserDomain from '../domains/UserDomain';
import UserPermissionDomain from '../domains/UserPermissionDomain';
import ManualEntryUserDomain from '../domains/ManualEntryUserDomain';
import ManualEntryUserBatchDomain from '../domains/ManualEntryUserBatchDomain';
import ManualEntryUserBatchErrorDomain from '../domains/ManualEntryUserBatchErrorDomain';
import ManualEntryConfigDomain from '../domains/ManualEntryConfigDomain';
import ClientDomain from '../domains/ClientDomain';
import CloudAccessKeyDomain from '../domains/CloudAccessKeyDomain';
import SshKeyDomain from '../domains/SshKeyDomain';
import ContactGroupDomain from '../domains/ContactGroupDomain';
import ThemeDomain from '../domains/ThemeDomain';
import TimesheetDomain from '../domains/TimesheetDomain';
import DevDomain from '../domains/DevDomain';
import TodoDomain from '../domains/TodoDomain';
import MessageDomain from '../domains/MessageDomain';
import ActivityLogDomain from '../domains/ActivityLogDomain'
import AppModuleDomain from '../domains/AppModuleDomain';
import AppModuleBuildDomain from '../domains/AppModuleBuildDomain';

import ResourceDockerTaskDomain from '../domains/ResourceDockerTaskDomain';
import ProjectHealthCheckDomain from '../domains/ProjectHealthCheckDomain';
import HealthCheckDomain from '../domains/HealthCheckDomain';
import PipelineDomain from '../domains/PipelineDomain';
import PipelineBlockDomain from '../domains/PipelineBlockDomain';
import BlockDomain from '../domains/BlockDomain';
import DatasetDomain from '../domains/DatasetDomain';
import PipelineUiDomain from '../domains/PipelineUiDomain';
import PipelineLogDomain from '../domains/PipelineLogDomain';
import DocumentTemplateDomain from '../domains/DocumentTemplateDomain';
import DocumentTemplateUiDomain from '../domains/DocumentTemplateUiDomain';
import DocumentTemplateAnnotationDomain from '../domains/DocumentTemplateAnnotationDomain';
import ManualEntryDocumentDomain from '../domains/ManualEntryDocumentDomain';
import ManualEntryPipelineDomain from '../domains/ManualEntryPipelineDomain';
import ManualEntryAccuracyTestDomain from '../domains/ManualEntryAccuracyTestDomain';
import ManualEntryDomain from '../domains/ManualEntryDomain';
import ManualEntryRouteDomain from '../domains/ManualEntryRouteDomain';
import ManualEntryPricingConfigDomain from '../domains/ManualEntryPricingConfigDomain';
import ManualEntryUserPaymentBatchDomain from '../domains/ManualEntryUserPaymentBatchDomain';
import ManualEntryUserPaymentDomain from '../domains/ManualEntryUserPaymentDomain';
import ManualEntryErrorDomain from '../domains/ManualEntryErrorDomain';

import VirtualWorkspaceConfigDomain from '../domains/VirtualWorkspaceConfigDomain';
import VirtualWorkspaceImageDomain from '../domains/VirtualWorkspaceImageDomain';
import VirtualWorkspaceDomain from '../domains/VirtualWorkspaceDomain';
import VirtualWorkspaceRegionDomain from '../domains/VirtualWorkspaceRegionDomain';

export default {
  domain: {
    activityLog: ActivityLogDomain,
    country: CountryDomain,
    currency: CurrencyDomain,
    dashboard: DashboardDomain,
    project: ProjectDomain,
    resource: ResourceDomain,
    resourceScript: ResourceScriptDomain,
    resourceStatusCheck: ResourceStatusCheckDomain,
    eventTask: EventTaskDomain,
    resourceAlert: ResourceAlertDomain,
    event: EventDomain,
    script: ScriptDomain,
    statusCheck: StatusCheckDomain,
    log: LogDomain,
    userSession: UserSessionDomain,
    user: UserDomain,
    userPermission: UserPermissionDomain,
    manualEntry: ManualEntryDomain,
    manualEntryPipeline: ManualEntryPipelineDomain,
    manualEntryUser: ManualEntryUserDomain,
    manualEntryUserBatch: ManualEntryUserBatchDomain,
    manualEntryUserBatchError: ManualEntryUserBatchErrorDomain,
    manualEntryConfig: ManualEntryConfigDomain,
    manualEntryRoute: ManualEntryRouteDomain,
    manualEntryDocument: ManualEntryDocumentDomain,
    manualEntryAccuracyTest: ManualEntryAccuracyTestDomain,
    manualEntryPricingConfig: ManualEntryPricingConfigDomain,
    manualEntryUserPaymentBatch: ManualEntryUserPaymentBatchDomain,
    manualEntryUserPayment: ManualEntryUserPaymentDomain,
    manualEntryError: ManualEntryErrorDomain,
    virtualWorkspace: VirtualWorkspaceDomain,
    virtualWorkspaceConfig: VirtualWorkspaceConfigDomain,
    virtualWorkspaceImage: VirtualWorkspaceImageDomain,
    virtualWorkspaceRegion: VirtualWorkspaceRegionDomain,
    client: ClientDomain,
    cloudAccessKey: CloudAccessKeyDomain,
    sshKey: SshKeyDomain,
    contactGroup: ContactGroupDomain,
    theme: ThemeDomain,
    timesheet: TimesheetDomain,
    dev: DevDomain,
    message: MessageDomain,
    todo: TodoDomain,
    appModule: AppModuleDomain,
    appModuleBuild: AppModuleBuildDomain,
    resourceDockerTask: ResourceDockerTaskDomain,
    projectHealthCheck: ProjectHealthCheckDomain,
    healthCheck: HealthCheckDomain,
    pipeline: PipelineDomain,
    pipelineLog: PipelineLogDomain,
    pipelineBlock: PipelineBlockDomain,
    block: BlockDomain,
    dataset: DatasetDomain,
    documentTemplate: DocumentTemplateDomain,
    documentTemplateAnnotation: DocumentTemplateAnnotationDomain
  },
  ui: {
    pipeline: PipelineUiDomain,
    documentTemplate: DocumentTemplateUiDomain
  }
}