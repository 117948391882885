import { Platform } from "../core/";

const getBaseUrl = () => {
  if (!window || !window.location) return "";
  let url = `${window.location.protocol}//${window.location.hostname}`;
  if (window.location.port) url = `${url}:${window.location.port}`;
  return url;
};
let baseUrl = getBaseUrl();
let devBaseUrl = "http://r14dev.com";
//let devBaseUrl = baseUrl;

const Config = {
  name: "r14-dev-app",
  api: {
    url: process.env.NODE_ENV === "development" ? devBaseUrl : baseUrl,
  },
  io: {
    url: process.env.NODE_ENV === "development" ? devBaseUrl : baseUrl,
  },
  storage: {
    url: {
      file:
        process.env.NODE_ENV === "development"
          ? `${devBaseUrl}/files`
          : `${baseUrl}/files`,
      image:
        process.env.NODE_ENV === "development"
          ? `${devBaseUrl}/images`
          : `${baseUrl}/images`,
    },
  },
};
export default Config;
