import React from "react";
import R14, {
  Colors,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  DataTable,
  Chip,
  ChipGroup,
  Image,
} from "../core";

export default R14.connect(
  class ManualEntryPricingConfigDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.perms = this.props.app.dm.user.getPermissions("manualEntryPricingConfig");
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {};
      if (search) filter.search = { like: `%${search}%` };
      let res = await this.props.app.dm.manualEntryPricingConfig.find(
        `
          uid
          name
          description
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true,
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("manualEntryConfigs");
    }
    cellRenderer({ cell, columnName, row, columns }) {
      return <Text style={[styles.cellText]}>{cell}</Text>;
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    async handleRemovePress(uid, dataTable) {
      if (window.confirm("Are you sure?")) {
        await this.props.app.dm.manualEntryConfig.delete(uid);
        dataTable.refresh({ indicator: true });
      }
    }
    async handleRunCommandPress(uid, command) {
      this.props.app.ui.progressIndicator.show();
      await this.props.app.dm.manualEntryConfig.runCommand(uid, command);
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
    }
    // rowBottomRenderer(values) {
    //   return (
    //     <View style={styles.fieldValueItem}>
    //       <View style={[styles.fieldValueItemRow]}>
    //         <LabelView
    //           style={styles.fieldValueItemLabelView}
    //           key='mode'
    //           label='Mode'
    //         >
    //           <View>
    //             <Text>
    //               {this.props.app.dm.manualEntryConfig.getPricingModeLabel(
    //                 values.mode
    //               )}
    //             </Text>
    //           </View>
    //         </LabelView>
    //         <LabelView
    //           style={styles.fieldValueItemLabelView}
    //           key='maxRate'
    //           label='Max Rate'
    //         >
    //           <View>
    //             <Text>{values.maxRate || "None"}</Text>
    //           </View>
    //         </LabelView>
    //       </View>
    //       <View style={[styles.fieldValueItemRow, styles.pricingOptionsRow]}>
    //         <LabelView
    //           style={styles.fieldValueItemLabelView}
    //           key='rangePastDays'
    //           label='Sync Range'
    //         >
    //           <View>
    //             <Text>{values.rangePastDays} Day(s)</Text>
    //           </View>
    //         </LabelView>

    //         <LabelView
    //           style={styles.fieldValueItemLabelView}
    //           key='updateFrequencyMinutes'
    //           label='Update Frequency'
    //         >
    //           <View>
    //             <Text>{values.updateFrequencyMinutes} Minutes(s)</Text>
    //           </View>
    //         </LabelView>
    //       </View>
    //       {this.props.app.dm.manualEntryConfig
    //         .getPricingMetricFieldInfo()
    //         .map((mode) => (
    //           <View key={mode.mode} style={styles.fieldValueItemRow}>
    //             <LabelView
    //               style={styles.fieldValueItemLabelView}
    //               key='mode'
    //               label={mode.label}
    //             >
    //               {mode.fields
    //                 .filter((field) => values[field.name])
    //                 .map((field) => (
    //                   <View key={field.name}>
    //                     <Text>{field.label}</Text>
    //                   </View>
    //                 ))}
    //             </LabelView>
    //             <LabelView
    //               style={styles.fieldValueItemLabelView}
    //               key='rate'
    //               label='Rate'
    //             >
    //               {mode.fields
    //                 .filter((field) => values[field.name])
    //                 .map((field) => (
    //                   <View key={field.name}>
    //                     <Text>{values[field.name]}</Text>
    //                   </View>
    //                 ))}
    //             </LabelView>
    //           </View>
    //         ))}
    //     </View>
    //   );
    // }
    render() {
      return (
        <DataTable
          name='manualEntryPricingConfigs'
          columns={{
            name: {
              label: "Name",
              sortable: true,
            },
            description: {
              label: "Description",
              sortable: true,
            },
          }}
          searchable
          // onRowPress={(row) =>
          //   this.props.app.nav.to("projectManualEntryConfig", {
          //     uid: this.props.project.uid,
          //     key: this.props.app.dm.project.getKeyByType(this.props.project.type),
          //     manualEntryConfigUid: row.uid,
          //   })
          // }
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          initialRowsPerPage={10}
          headerTitle={this.props.title || "Manual Entry Pricing"}
          paddingBottom={72} // Make space for the FAB
          initialSortColumnName='type'
          initialSortDirection='asc'
          rowControlsRight={(row, dataTable) => {
            let ret = [];
            this.perms.edit &&
              ret.push(
                <IconButton
                  icon='edit'
                  size='small'
                  key='edit'
                  onPress={() =>
                    this.props.app.nav.to("manualEntryPricingConfigEdit", {
                      uid: row.uid,
                    })
                  }
                />
              );
            // this.perms.execute &&
            //   ret.push(
            //     <PopUpMenu
            //       controlIcon='dotsHorizontal'
            //       controlIconSize='small'
            //       key='projectResourceMenu'
            //       direction='downLeft'
            //     >
            //       <PopUpMenuItem
            //         label='Timeout Documents'
            //         icon='clock'
            //         key='timeout'
            //         onPress={() =>
            //           window.confirm(
            //             "Are you sure? This will timeout all documents currently in the pool."
            //           ) &&
            //           this.handleRunCommandPress(
            //             row.uid,
            //             this.props.app.dm.manualEntryConfig.COMMAND_TIMEOUT
            //           )
            //         }
            //       />
            //       <PopUpMenuItem
            //         label='Restart Services'
            //         icon='refresh'
            //         key='restartServices'
            //         onPress={() =>
            //           window.confirm(
            //             "Are you sure? This will restart all r14 services. Please only use this as a last resort. You will need to refresh the browser after running."
            //           ) &&
            //           this.handleRunCommandPress(
            //             row.uid,
            //             this.props.app.dm.manualEntryConfig
            //               .COMMAND_RESTART_SERVICES
            //           )
            //         }
            //       />
            //     </PopUpMenu>
            //   );

            return ret;
          }}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
  },
  cellChipGroup: {
    marginTop: 8,
  },
});
