import React from "react";
import PropTypes from "prop-types";
import R14 from "../R14";
import TextInput from "./TextInput";
import View from "./View";
import Text from "./Text";
import Icon from "./Icon";
import IconButton from "./IconButton";
import AnimatedView from "./AnimatedView";
import AnimatedText from "./AnimatedText";
import StyleSheet from "./StyleSheet";
import { Colors } from "./Theme";

export default R14.connectForm(
  /**
   * An input field component
   */
  class InputField extends React.Component {
    static propTypes = {
      /** The InputComponent prop is the input component to insert inside the field. */
      InputComponent: PropTypes.node.isRequired,
      /** The element prop is the ui domain form element. */
      element: PropTypes.object.isRequired,
      /** Label for the input field. */
      label: PropTypes.string,
      /** Label will always been in focused position. */
      staticLabel: PropTypes.string,
      /** The input field variant to use */
      variant: PropTypes.oneOf(["outlined", "filled", "simple"]),
      /** If true, disable the input field */
      disabled: PropTypes.bool,
      /** The name of the icon to be displayed to the left of the input field  */
      icon: PropTypes.string,
      /** The function to be called when the icon is pressed  */
      onIconPress: PropTypes.func,
      /** The name of the icon to be displayed to the right of the input field  */
      iconRight: PropTypes.string,
      /** The function to be called when the iconRight is pressed  */
      onIconRightPress: PropTypes.func,
      /** Helper text displayed below the input. */
      helper: PropTypes.string,
      /** The color of the background of the element and label. */
      backgroundColor: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
      ]),
      /** Style to be applied to the input field. */
      style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      /** Helper text style to be applied to the input field helper. */
      helperTextStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      /** Label text style to be applied to the input field helper label. */
      labelTextStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    };
    static defaultProps = {
      variant: "outlined",
    };
    constructor(props) {
      super(props);
      this.handleLayout = this.handleLayout.bind(this);
      this.state = { layout: null };
      this._styles = null;
      this.elmt = this.props.element;
      this.utils = R14.utils;
      this.activeColor =
        this.props.activeColor || this.props.form.activeColor || "#1565c0";
      this.errorColor =
        this.props.errorColor || this.props.form.errorColor || "#B00020";
    }
    get hasLeftPadding() {
      return this.props.icon || this.props.controlsLeft ? true : false;
    }
    get backgroundColor() {
      return (
        this.props.backgroundColor || this.props.form.backgroundColor || null
      );
    }
    handleLayout(event) {
      this.setState({ layout: event.nativeEvent.layout });
    }
    renderIcon(propName, styles) {
      let icon = this.props[propName];
      let onIconPress =
        this.props[`on${this.utils.str.capitalize(propName)}Press`];
      if (!icon) return null;

      if (onIconPress)
        return (
          <IconButton
            icon={icon}
            style={[styles[propName], styles[`${propName}Button`]]}
            onPress={() => onIconPress()}
            color={
              this.elmt.isFocused
                ? Colors.primary
                : StyleSheet.color(Colors.onBackground).rgba(0.5)
            }
          />
        );
      else
        return (
          <Icon
            name={icon}
            style={styles[propName]}
            color={
              this.elmt.isFocused
                ? Colors.primary
                : StyleSheet.color(Colors.onBackground).rgba(0.5)
            }
            pointerEvents='none'
          />
        );
    }
    render() {
      // Update the current styles
      let styles = this.styles();
      let variantStyles = this.variantStyles();
      let helperText = this.elmt.error ? this.elmt.error : this.elmt.helper;
      let helperTextStyle = this.elmt.error
        ? [styles.helperText, styles.errorText]
        : [styles.helperText];
      let labelTranslateY = -16;
      let labelTranslateX = 0;
      if (this.state.layout) {
        let width = this.state.layout.width;
        labelTranslateX =
          (width * 0.75 - width + (this.props.icon ? 24 : 8)) / (2 * 0.75);
      }
      let showIndicator = true;
      switch (this.props.variant) {
        case "outlined":
          labelTranslateY = -28;
          showIndicator = false;
          break;
      }
      return (
        <View
          style={[
            styles.inputFieldWrapper,
            this.elmt.disabled && styles.disabled,
            this.props.style,
          ]}
          pointerEvents={this.elmt.disabled ? "none" : null}
          tooltip={this.props.tooltip || null}
        >
          {/* {this.props.suggestionLoader &&
            <Suggestions element={this.elmt} {...this.props} />
          } */}
          <View
            style={[styles.inputField, variantStyles.inputField]}
            onLayout={this.handleLayout}
          >
            {this.state.layout && this.props.label && (
              <AnimatedView
                style={styles.animatedLabel}
                debug={this.props.debug || null}
                pointerEvents='none'
                animation={{
                  exit: {
                    transform: [
                      { scale: 1 },
                      { translateY: 0 },
                      { translateX: 0 },
                    ],
                    // fontSize: 16,
                  },
                  enter: {
                    transform: [
                      { scale: 0.75 },
                      { translateY: labelTranslateY },
                      { translateX: labelTranslateX },
                      // { translateX: "-17.5%" }
                    ],
                    // fontSize: 12,
                    //color: Colors.primary
                  },
                }}
                timingFunction='linear'
                in={this.elmt.isActive || this.props.staticLabel}
                duration={150}
                htmlTag='label'
                htmlFor={this.props.name}
                //useNativeDriver={false}
              >
                <View
                  style={[
                    styles.label,
                    variantStyles.label,
                    this.props.labelStyle,
                  ]}
                >
                  <Text
                    style={[
                      styles.labelText,
                      variantStyles.labelText,
                      this.props.labelTextStyle,
                    ]}
                  >
                    {this.props.label}
                  </Text>
                </View>
              </AnimatedView>
            )}

            <View
              style={[
                styles.inputCntr,
                variantStyles.inputCntr,
                this.props.containerStyle,
              ]}
            >
              {this.props.icon && this.renderIcon("icon", styles)}
              {this.props.controlsLeft && (
                <View style={styles.controlsLeft}>
                  {this.props.controlsLeft}
                </View>
              )}

              {this.props.InputComponent}
              {this.props.iconRight && this.renderIcon("iconRight", styles)}
            </View>
            {helperText && (
              <Text style={[helperTextStyle, this.props.helperTextStyle]}>
                {helperText}
              </Text>
            )}
            {showIndicator && (
              <AnimatedView
                style={styles.indicator}
                animation={{
                  exit: {
                    transform: [{ scaleX: 0 }],
                    opacity: 0,
                  },
                  enter: {
                    transform: [{ scaleX: 1 }],
                    opacity: 1,
                  },
                }}
                in={this.elmt.isFocused || this.elmt.error}
                duration={150}
              />
            )}
          </View>
        </View>
      );
    }
    variantStyles() {
      switch (this.props.variant) {
        case "outlined":
          return StyleSheet.create({
            inputCntr: {
              borderColor: this.elmt.isFocused
                ? Colors.primary
                : StyleSheet.color(Colors.onBackground).rgba(0.4),
              borderRadius: 4,
              borderWidth: this.elmt.isFocused ? 2 : 1,
              ...StyleSheet.padding(this.elmt.isFocused ? 0 : 1),
            },
            label: {
              backgroundColor: this.backgroundColor || Colors.background,
            },
          });
          break;
        case "filled":
          return StyleSheet.create({
            inputField: {
              backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.1),
            },
          });
          break;
        case "simple":
          return StyleSheet.create({
            inputCntr: {
              borderBottomWidth: 0,
            },
            inputField: {
              backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.1),
              borderWidth: 0,
            },
          });
          break;
        default:
          return {};
      }
    }
    styles() {
      return StyleSheet.create({
        inputFieldWrapper: {
          flex: 1,
          // flexShrink: 1,
          marginBottom: 32,
          // maxWidth: 280,
          screen: ({ width }) => {
            if (width <= 640)
              return {
                maxWidth: null,
              };
          },
        },
        inputField: {
          position: "relative",
          borderTopRightRadius: 4,
          borderTopLeftRadius: 4,
        },
        inputCntr: {
          borderBottomWidth: 1,
          borderColor: StyleSheet.color(Colors.onBackground).rgba(0.4),
          borderStyle: "solid",
        },

        disabled: {
          opacity: 0.5,
        },
        // textInput: {
        //   margin: 0,
        //   paddingTop: 20,
        //   paddingLeft: this.props.icon ? 44 : 12,
        //   paddingRight: this.props.iconRight ? 44 : 12,
        //   paddingBottom: 6,
        //   width: "100%",
        //   height: 55,
        //   backgroundColor: 'transparent',
        //   color: Colors.onBackground,
        //   lineHeight: 29,
        //   fontSize: 16,
        // },
        indicator: {
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: this.elmt.error ? Colors.error : Colors.primary,
          transform: [{ scaleX: 0.1 }],
          opacity: 0,
        },
        helperText: {
          fontSize: 12,
          lineHeight: 20,
          position: "absolute",
          bottom: -20,
          left: this.hasLeftPadding ? 44 : 12,
          color: StyleSheet.color(Colors.onBackground).rgba(0.6),
        },
        errorText: {
          color: Colors.error,
        },
        labelText: {
          //fontSize: 16
          color: this.elmt.isFocused
            ? Colors.primary
            : StyleSheet.color(Colors.onBackground).rgba(0.5),
          lineHeight: 16,
        },
        animatedLabel: {
          zIndex: 2,
          display: "flex",
        },
        label: {
          position: "absolute",
          top: 20,
          left: this.hasLeftPadding ? 40 : 8,
          // pointerEvents: "none",
          // fontWeight: "500",
          paddingLeft: 4,
          paddingRight: 4,
          zIndex: 2,
        },

        icon: {
          position: "absolute",
          top: 16,
          left: 12,
          // pointerEvents: "none"
        },
        iconButton: {
          ...StyleSheet.margin(0),
          top: 10,
          left: 6,
        },
        controlsLeft: {
          position: "absolute",
          top: 16,
          left: 12,
        },
        iconRight: {
          position: "absolute",
          top: 16,
          right: 12,
        },
        iconRightButton: {
          ...StyleSheet.margin(0),
          top: 10,
          right: 6,
        },
      });
    }
  }
);
