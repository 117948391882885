import R14 from "../R14";
import R14Route from "../R14Route";

// import R14Portal from '../R14Portal';
import { matchPath } from "react-router";
export default class R14NavigationBase extends R14.Domain {
  // constructor() {
  //   super();
  //   this._routesConfig = null;
  //   this._routes = {};
  //   this._screens = {};
  //   // url / deep link paths
  //   this._paths = {};
  //   this._navigators = {};
  //   this._portals = {};
  //   this._initialScreen = null;
  //   this._history = null;
  //   this._location = null;
  //   this._match = null;
  //   this._activeRouteParams = {};
  //   this._route = {};
  //   this.state = {
  //     activeRoute: null
  //   }
  // }

  initializeRouterProps(props) {
    if (!props.location) throw "Navigation error, router location not found.";
    if (!props.history) throw "Navigation error, router history not found.";
    this._location = props.location;
    this._history = props.history;
    this._activePortals = {};
    this.update();
  }

  // initializeRoutes(routesConfig) {
  //   this._routesConfig = routesConfig;
  //   this._initNavigation(routesConfig);
  // }

  getPathByRouteName(routeName) {
    // Check if it is a route with no path,
    // If so forward to the first path or initial screen

    if (!this._paths[routeName] && this._navigators[routeName]) {
      if (this._navigators[routeName].initialRoute) {
        let initialRoute = null;
        if (typeof this._navigators[routeName].initialRoute === "function") {
          initialRoute = this._navigators[routeName].initialRoute({
            navigator: this._navigators[routeName],
            app: R14.getInstance().app,
          });
        } else initialRoute = this._navigators[routeName].initialRoute;
        return this.getPathByRouteName(initialRoute);
      } else if (this._navigators[routeName].routes) {
        return this.getPathByRouteName(
          Object.keys(this._navigators[routeName].routes)[0]
        );
      } else return null;
    } else return this._paths[routeName] || null;
  }

  // isActiveRoute(route) {
  //   throw new Error("isActiveRoute?");
  //   let routeName = route.name;
  //   console.log("IS ACTIVE ROUTE", route);

  //   if (this.activeRoute === routeName) return true;
  //   // Check the active routes of the portals
  //   for (let i in this._portals) {
  //     if (!this._portals[i].route) break;
  //     let portalRouteName = this._portals[i].route.name;
  //     if (portalRouteName === routeName) return true;
  //     // Check if it is a parent of the routePath
  //     let portalRoute = this.getRouteByRouteName(portalRouteName);
  //     return (
  //       portalRoute &&
  //       portalRoute.routePath &&
  //       portalRoute.routePath.indexOf(routeName) !== -1
  //     );
  //   }
  // }

  // addNavigator(navigator, name, portal, parent, routePath) {
  //   this._navigators[name] = {
  //     type: navigator.type,
  //     portal: portal,
  //     routePath: [...routePath],
  //     parent: parent,
  //     routes: navigator.routes
  //   };
  //   switch (navigator.type) {
  //     case "stack":
  //       if (navigator.header) this._navigators[name].header = navigator.header;
  //       break;
  //     case "tab":
  //       if (navigator.tabNavigator) {
  //         this._navigators[name].tabNavigator = navigator.tabNavigator;
  //       }
  //       break;
  //     case "modal":
  //       if (navigator.header) {
  //         this._navigators[name].header = navigator.header;
  //       }
  //       break;
  //   }
  // }

  // _initNavigation(routesConfig, portal = null, parent = null, routePath = []) {
  //   if (!routesConfig.routes) return null;

  //   if (parent === null) {
  //     parent = "r14NavRoot";
  //     if (!portal) portal = (routesConfig.type === 'modal') ? 'modal' : 'root'
  //     this.addNavigator(routesConfig, "r14NavRoot", null, null, [...routePath]);
  //     routePath.push(parent);
  //   }

  //   if (routesConfig.path) {
  //     this._paths[parent] = routesConfig.path;
  //   }

  //   for (let name in routesConfig.routes) {

  //     if (routesConfig.routes[name].routes) {
  //       let childRoutePath = [...routePath];
  //       childRoutePath.push(name);
  //       if (!portal || routesConfig.routes[name].type === 'modal') portal = (routesConfig.routes[name].type === 'modal') ? 'modal' : 'root'
  //       this.addNavigator(routesConfig.routes[name], name, portal, parent, childRoutePath);
  //       this._initNavigation(routesConfig.routes[name], portal, name, childRoutePath);
  //       continue;
  //     }

  //     if (routesConfig.routes[name].path) {
  //       this._paths[name] = routesConfig.routes[name].path;
  //     }

  //     this._screens[name] = {
  //       routePath: [...routePath],
  //       portal: portal,
  //       parent: parent,
  //       ...routesConfig.routes[name]
  //     };

  //   }
  // }
  update() {
    let foundMatch = false;
    let activeRoute = null;
    let matchCount = 0;
    let activeRoutes = [];
    let match = null;
    let activeMatch = null;
    for (let routeName in this._paths) {
      match = matchPath(this._location.pathname, this._paths[routeName]);
      if (this._location.pathname === this._paths[routeName]) {
        activeRoute = routeName;
        this._match = match;
        activeMatch = match;
        break;
      } else if (match && match.isExact) {
        // Check the param count
        // Lowest param count of an exact match is a closer match
        if (match.params) {
          if (matchCount && Object.keys(match.params).length > matchCount)
            continue;
          matchCount = Object.keys(match.params).length;
        }
        activeRoute = routeName;
        this._match = match;
        activeMatch = match;
      }
    }

    if (activeRoute) {
      let historyState = this._location.state;
      let activePortals = {};
      let portalName = this.getPortalNameByRouteName(activeRoute);
      activePortals[portalName] = {
        name: activeRoute,
        path: this._location.pathname,
        match: activeMatch,
      };
      if (historyState && historyState.portals) {
        for (let portalName in historyState.portals) {
          if (activePortals[portalName]) continue;
          let portal = historyState.portals[portalName];
          if (!this._paths[portal.name]) continue;
          let portalMatch = matchPath(portal.path, this._paths[portal.name]);

          // Get the match for the portal
          activePortals[portalName] = {
            ...portal,
            match: portalMatch,
          };
        }
      }

      for (let portalName in activePortals) {
        this.initActiveRoute(
          activePortals[portalName].name,
          activePortals[portalName]
        );
      }

      this._activePortals = activePortals;

      // REMOVED, MAKE SURE IT WORKS
      for (let k in this._portals) {
        if (
          !this._portals[k].name !== "root" &&
          !activePortals[this._portals[k].name]
        ) {
          this._portals[k].setActiveRoute(null);
        }
        // else
        //   console.log(
        //     "CHECK IF SHOULD RE INIT PORTAL",
        //     activePortals[this._portals[k].name],
        //     this._portals[k]
        //   );
      }

      //this.initActiveRoute(activeRoute);
    } else if (this._location.pathname === "/") {
      /** @todo refactor this code, is this the best way to forward? */
      // Emulate the behavior of the native navigator initial route
      let initialRoute = this.findInitialRoute(this._routesConfig);
      if (initialRoute) R14.getInstance().app.nav.to(initialRoute);
    }
  }

  createHistoryState(routeName, routePath, options = {}) {
    let activePortal = this.getPortalByRouteName(routeName);
    let ret = {
      portals: {
        [activePortal.name]: { name: routeName, path: routePath },
      },
    };
    for (let portalName in this._portals) {
      // If the portal is root, just clear out all modals and containers
      if (this._portals[portalName].parentPortalName) continue;
      if (
        portalName === activePortal.name ||
        activePortal.type === this.PORTAL_TYPE_ROOT
      )
        continue;
      let portal = this._portals[portalName];
      if (portal.activeRouteName && portal.activeRoutePath)
        ret.portals[portalName] = {
          name: portal.activeRouteName,
          path: portal.activeRoutePath,
        };
    }
    if (options.containerPortals) {
      ret.portals = { ...ret.portals, ...options.containerPortals };
    }
    // Init child portals
    for (let portalName in this._portals) {
      let childPortal = this._portals[portalName];
      if (!childPortal.parentPortalName) continue;
      if (!ret.portals[childPortal.parentPortalName]) continue;
      if (ret.portals[portalName]) continue;
      if (!childPortal.activeRouteName && !childPortal.activeRoutePath)
        continue;
      ret.portals[portalName] = {
        name: childPortal.activeRouteName,
        path: childPortal.activeRoutePath,
      };
    }
    return ret;
  }
  initializeContainerPortalsByRoute(route) {
    let parentPortal = this.getPortalByRouteName(route);
    let containerPortals = {};
    for (let portalName in this._portals) {
      let portal = this._portals[portalName];
      if (
        !portal ||
        this._activePortals[portalName] ||
        portal.type !== this.PORTAL_TYPE_CONTAINER ||
        portal.parentPortalName !== parentPortal.name ||
        portal.initialized
      )
        continue;
        
      let routeConfig = this.routesConfig.routes[portalName];
      let routeName = this.findInitialRoute(routeConfig);
      console.log("CHECK ROUTE NAME?", routeConfig, routeName);
      let portalRoute = this.getRouteByRouteName(routeName);
      let portalData =
        this._portals[portal.parentPortalName] &&
        this._portals[portal.parentPortalName].route
          ? {
              ...this._portals[portal.parentPortalName].route.data,
            }
          : {};
      if (portalRoute.initialParams || portal.initialParams) {
        let initialParams = {
          ...(portalRoute.initialParams || {}),
          ...(portal.initialParams || {}),
        };
        for (let param in initialParams) {
          if (!(param in portalData)) portalData[param] = initialParams[param];
        }
      }
      let portalPath = this.createPath(portalRoute.path, portalData);
      let portalMatch = matchPath(portalPath, portalRoute.path);
      containerPortals[portalName] = {
        name: routeName,
        path: portalMatch.url,
      };
    }
    if (!Object.keys(containerPortals).length) return false;
    let historyState = this.createHistoryState(
      this.currRoute.name,
      this.currRoute.path,
      { containerPortals }
    );
    R14.getInstance().app.nav.updateState(historyState);
  }
  initActiveRoute(routeName, options = {}) {
    let portal = this.getPortalByRouteName(routeName);
    if (!portal) return false;
    let routeConfig = this.screens(routeName);
    // console.log(
    //   "INIT PORTAL 1 INIT ACTIVE ROUTE",
    //   routeName,
    //   options,
    //   portal.activeRouteName,
    //   portal.activeRoutePath
    // );
    // Check if the route is already rendered
    if (
      routeName === portal.activeRouteName &&
      options.path &&
      options.path === portal.activeRoutePath
    ) {
      return false;
    }

    // REMOVED, MAKE SURE IT WORKS
    // if (portal.name === "root") {
    //   /** @todo Navigation base refactor. This is too static, find a dynamic way to close routes. */
    //   for (let k in this._portals) {
    //     if (this._portals[k].name !== "root") {
    //       console.log("setting portal inactive: ", this._portals[k].name);
    //       this._portals[k].setActiveRoute(null);
    //     }
    //   }
    // }

    let location = options.location || this._location;
    let match = options.match || this._match;

    // If this is a child portal, just use match from options
    if (portal.parentPortalName && options.match && options.match.isExact)
      location = {
        pathname: options.match.url,
        search: "",
        state: this._location.state,
      };

    let route = new R14Route(
      routeName,
      routeConfig,
      portal.name,
      location,
      match,
      options.navigation
    );
    portal.setActiveRoute(route);
    this.setActiveRoute(route);

    // if (portal.name === "root") {
    //   // make sure the root route has an activeRoute
    // }
    /** @todo Navigation base needs testing to make sure that this works ok. */
    /** @todo Navigation base maybe use route object instead of name. */
    if (routeConfig && routeConfig.initialParentRoute) {
      // Make sure that the parent route has an active route
      let parentRouteConfig = this.screens(routeConfig.initialParentRoute);

      if (
        parentRouteConfig &&
        parentRouteConfig.portal !== routeConfig.portal
      ) {
        let parentPortal = this.portals(parentRouteConfig.portal);

        if (parentPortal && !parentPortal.activeRouteName) {
          // figure out path and data from portal
          let parentUrl = parentRouteConfig.path;
          let parentParams = {};
          for (let param in portal.route.data) {
            let key = `:${param}`;
            if (parentUrl.indexOf(key) !== -1) {
              parentUrl = parentUrl.replace(key, portal.route.data[param]);
              parentParams[param] = portal.route.data[param];
            }
          }

          let parentMatch = {
            params: parentParams,
            path: parentRouteConfig.path,
            url: parentUrl,
            isExact: true,
          };
          /** @todo seperate state by portal */
          let parentLocation = {
            pathname: parentUrl,
            search: "",
            state: this._location.state,
          };

          let parentRoute = new R14Route(
            routeConfig.initialParentRoute,
            this.screens(routeConfig.initialParentRoute),
            parentPortal.name,
            parentLocation,
            parentMatch
          );
          parentPortal.setActiveRoute(parentRoute);
        }
      }
    }
  }
}
