import R14 from "../core";

export default class UserPermissionDomain extends R14.Domain {
  constructor(type) {
    super();
    this.state = {};
    this.PERMISSION_FULL = "FULL";
    this.PERMISSION_READ = "READ";
    this.PERMISSION_CREATE = "CREATE";
    this.PERMISSION_EDIT = "EDIT";
    this.PERMISSION_DELETE = "DELETE";
    this.PERMISSION_EXECUTE = "EXECUTE";
  }
  async findGroups(fieldsStr, options = null) {
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");
    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };
    let result = await this.api.qry(
      `
      query FindUserPermissionGroups($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: UserPermissionGroupFilter) {
        userPermissionGroups(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return result.userPermissionGroups;
  }
  async createGroup(values) {
    let res = await this.api.mutate(
      `
      mutation CreateUser($input: CreateUserPermissionGroupInput!) {
        createUserPermissionGroup(input: $input){
          userPermissionGroup {
            uid
            name
          }
        }
      }`,
      {
        input: this._parseGroupEditFormValues(values),
      }
    );
    return true;
  }
  async updateGroup(values) {
    console.log("Updates group with values", values);
    let res = await this.api.mutate(
      `
      mutation UpdateUserPermissionGroup($input: UpdateUserPermissionGroupInput!) {
        updateUserPermissionGroup(input: $input){
          userPermissionGroup {
            uid
            name
          }
        }
      }`,
      {
        input: this._parseGroupEditFormValues(values),
      }
    );
    return true;
  }
  _parseGroupEditFormValues(values) {
    let ret = {
      name: values.name,
      permissions: values.permissions || [],
      clientUid: this.dm.userSession.clientUid,
    };
    if (values.uid) ret.uid = values.uid;
    console.log();
    if (values.type) {
      let permission = {
        type: values.type,
        access: values.access,
      };
      if (values.subtype) permission.subtype = values.subtype;
      ret.permissions.push(permission);
    }
    console.log("_parseGroupEditFormValues", values, ret);
    return ret;
  }
  async fetchEditFormData(uid = null) {
    let res = uid
      ? await this.api.qry(
          `
      query UserPermissionEditFormData($uid: ID!) {
        user(uid: $uid){
          uid
          name
          role
          permissions {
            type
            subtype
            access
          }
        }
      }`,
          {
            uid: uid,
          }
        )
      : {};
    let formVals = res.userPermission || {};
    console.log(uid, res);
    formVals.role = (res.user && res.user.role) || null;
    return {
      values: formVals,
      user: res.user || {},
      roleSelections: this.dm.user.getRoleSelections(),
      typeSelections: this.getTypeSelections(),
      subtypeSelections: this.getSubtypeSelections(),
      accessSelections: this.getAccessSelections(),
    };
  }
  async fetchGroupEditFormData(uid = null) {
    let res = uid
      ? await this.api.qry(
          `
      query UserPermissionGroupEditFormData($uid: ID!) {
        userPermissionGroup(uid: $uid){
          uid
          name
          permissions {
            type
            subtype
            access
          }
        }
      }`,
          {
            uid: uid,
          }
        )
      : { data: {} };
    let formVals = res.data.userPermissionGroup || {};
    return {
      values: formVals,
      user: res.user || {},
      roleSelections: this.dm.user.getRoleSelections(),
      typeSelections: this.getTypeSelections(),
      subtypeSelections: this.getSubtypeSelections(),
      accessSelections: this.getAccessSelections(),
    };
  }
  getTypeSelections() {
    return [
      // {
      //   label: this.getTypeLabel("project"),
      //   value: "project"
      // },
      {
        label: this.getTypeLabel("resource"),
        value: "resource",
      },
      {
        label: this.getTypeLabel("dev"),
        value: "dev",
      },
      {
        label: this.getTypeLabel("ai"),
        value: "ai",
      },
      {
        label: this.getTypeLabel("cloud"),
        value: "cloud",
      },
      {
        label: this.getTypeLabel("idp"),
        value: "idp",
      },
      {
        label: this.getTypeLabel("admin"),
        value: "admin",
      },
    ];
  }
  getSubtypeSelections() {
    return {
      // project: [
      //   {
      //     label: this.getSubtypeLabel("project", "resource"),
      //     value: "resource"
      //   }
      // ],
      resource: [
        {
          label: this.getSubtypeLabel("project", "monitor"),
          value: "monitor",
        },
        {
          label: this.getSubtypeLabel("project", "event"),
          value: "event",
        },
        {
          label: this.getSubtypeLabel("project", "script"),
          value: "script",
        },
        {
          label: this.getSubtypeLabel("project", "log"),
          value: "log",
        },
        {
          label: this.getSubtypeLabel("project", "alert"),
          value: "alert",
        },
        {
          label: this.getSubtypeLabel("project", "statusCheck"),
          value: "statusCheck",
        },
        {
          label: this.getSubtypeLabel("project", "healthCheck"),
          value: "healthCheck",
        },
      ],
      dev: [
        {
          label: this.getSubtypeLabel("dev", "appModule"),
          value: "appModule",
        },
        {
          label: this.getSubtypeLabel("dev", "codeEditor"),
          value: "codeEditor",
        },
        // {
        //   label: this.getSubtypeLabel("dev", "ide"),
        //   value: "ide",
        // },
        {
          label: this.getSubtypeLabel("dev", "resource"),
          value: "resource",
        },
      ],
      idp: [
        {
          label: this.getSubtypeLabel("idp", "documentTemplates"),
          value: "documentTemplates",
        },
        {
          label: this.getSubtypeLabel("idp", "manualEntryConfig"),
          value: "manualEntryConfig",
        },
        {
          label: this.getSubtypeLabel("idp", "manualEntryPipeline"),
          value: "manualEntryPipeline",
        },
        {
          label: this.getSubtypeLabel("idp", "manualEntryPipelineTimeout"),
          value: "manualEntryPipelineTimeout",
        },
      ],
      ai: [
        {
          label: this.getSubtypeLabel("ai", "appModule"),
          value: "appModule",
        },
        {
          label: this.getSubtypeLabel("ai", "codeEditor"),
          value: "codeEditor",
        },
        // {
        //   label: this.getSubtypeLabel("ai", "ide"),
        //   value: "ide",
        // },
        {
          label: this.getSubtypeLabel("ai", "pipeline"),
          value: "pipeline",
        },
        {
          label: this.getSubtypeLabel("ai", "pipelineBlock"),
          value: "pipelineBlock",
        },
        {
          label: this.getSubtypeLabel("ai", "block"),
          value: "block",
        },
        {
          label: this.getSubtypeLabel("ai", "resource"),
          value: "resource",
        },
      ],
      cloud: [
        {
          label: this.getSubtypeLabel("cloud", "resource"),
          value: "resource",
        },
        {
          label: this.getSubtypeLabel("cloud", "healthCheck"),
          value: "healthCheck",
        },
      ],
      admin: [
        {
          label: this.getSubtypeLabel("admin", "user"),
          value: "user",
        },
        {
          label: this.getSubtypeLabel("admin", "userPermissionGroup"),
          value: "userPermissionGroup",
        },
        {
          label: this.getSubtypeLabel("admin", "manualEntryUser"),
          value: "manualEntryUser",
        },
        {
          label: this.getSubtypeLabel("admin", "manualEntryConfig"),
          value: "manualEntryConfig",
        },
        {
          label: this.getSubtypeLabel("admin", "manualEntryPipelineTimeout"),
          value: "manualEntryPipelineTimeout",
        },
        {
          label: this.getSubtypeLabel("admin", "resource"),
          value: "resource",
        },
        {
          label: this.getSubtypeLabel("admin", "cloudAccessKey"),
          value: "cloudAccessKey",
        },
        {
          label: this.getSubtypeLabel("admin", "sshKey"),
          value: "sshKey",
        },
        {
          label: this.getSubtypeLabel("admin", "event"),
          value: "event",
        },
        {
          label: this.getSubtypeLabel("admin", "script"),
          value: "script",
        },
        {
          label: this.getSubtypeLabel("admin", "log"),
          value: "log",
        },
        {
          label: this.getSubtypeLabel("admin", "alert"),
          value: "alert",
        },
        {
          label: this.getSubtypeLabel("admin", "contactGroup"),
          value: "contactGroup",
        },
        {
          label: this.getSubtypeLabel("admin", "statusCheck"),
          value: "statusCheck",
        },
      ],
    };
  }
  getAccessSelections() {
    return [
      {
        label: "Full",
        value: this.PERMISSION_FULL,
      },
      {
        label: "Read",
        value: this.PERMISSION_READ,
      },
      {
        label: "Create",
        value: this.PERMISSION_CREATE,
      },
      {
        label: "Edit",
        value: this.PERMISSION_EDIT,
      },
      {
        label: "Delete",
        value: this.PERMISSION_DELETE,
      },
      {
        label: "Execute",
        value: this.PERMISSION_EXECUTE,
      },
    ];
  }
  getAccessLabel(access) {
    let ret = null;
    switch (access) {
      case this.PERMISSION_FULL:
        ret = "Full";
        break;
      case this.PERMISSION_READ:
        ret = "Read";
        break;
      case this.PERMISSION_CREATE:
        ret = "Create";
        break;
      case this.PERMISSION_EDIT:
        ret = "Edit";
        break;
      case this.PERMISSION_DELETE:
        ret = "Delete";
        break;
      case this.PERMISSION_EXECUTE:
        ret = "Execute";
        break;
    }
    return ret;
  }
  getTypeLabel(type) {
    let ret = null;
    switch (type) {
      case "resource":
        ret = "Resources";
        break;
      case "project":
        ret = "Projects";
        break;
      case "dev":
        ret = "Dev Studio Projects";
        break;
      case "ai":
        ret = "nQube Projects";
        break;
      case "cloud":
        ret = "Cloud Ops Projects";
        break;
      case "idp":
        ret = "IDP Projects";
        break;
      case "admin":
        ret = "Admin";
        break;
    }
    return ret;
  }
  getSubtypeLabel(type, subtype) {
    let ret = null;
    switch (subtype) {
      case "resource":
        ret = "Resources";
        break;
      case "user":
        ret = "Users";
        break;
      case "userPermissionGroup":
        ret = "Permission Groups";
        break;
      case "manualEntryUser":
        ret = "Manual Entry Users";
        break;
      case "manualEntryConfig":
        ret = "Manual Entry Config";
        break;
      case "manualEntryPipelineTimeout":
        ret = "Manual Entry Timeout";
        break;
      case "manualEntryPipeline":
        ret = "Manual Entry";
        break;
      case "documentTemplates":
        ret = "Document Templates";
        break;
      case "manualEntry":
        ret = "Manual Entry";
        break;
      case "monitor":
        ret = "Monitor";
        break;
      case "event":
        ret = "Events";
        break;
      case "script":
        ret = "Scripts";
        break;
      case "log":
        ret = "Logs";
        break;
      case "alert":
        ret = "Alerts";
        break;
      case "statusCheck":
        ret = "Status Checks";
        break;
      case "contactGroup":
        ret = "Contact Groups";
        break;
      case "healthCheck":
        ret = "Health Checks";
        break;
      case "cloudAccessKey":
        ret = "Cloud Access Keys";
        break;
      case "sshKey":
        ret = "SSH Key";
        break;
      case "appModule":
        ret = "App Modules";
        break;
      case "codeEditor":
        ret = "File Explorer";
        break;
      case "ide":
        ret = "Ide";
        break;
      case "pipeline":
        ret = "Pipelines";
        break;
      case "pipelineBlock":
        ret = "Pipeline Blocks";
        break;
      case "block":
        ret = "Blocks";
        break;
    }
    return ret;
  }
}
