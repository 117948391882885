import R14, { Colors } from "../core";
export default class PipelineBlockDomain extends R14.Domain {
  constructor(key) {
    super();
    this.TYPE_STORAGE = "STORAGE";
    this.COMMAND_RUN = "RUN";
    this.COMMAND_STOP = "STOP";
    this.COMMAND_RELOAD = "RELOAD";
    this.COMMAND_DELETE_DATA = "DELETE_DATA";
    this.COMMAND_TYPE_BLOCK = "BLOCK";
    this.COMMAND_TYPE_IDE = "IDE";

    this.INSTANCE_STATE_PENDING = "PENDING";
    this.INSTANCE_STATE_STOPPING = "STOPPING";
    this.INSTANCE_STATE_STARTING = "STARTING";
    this.INSTANCE_STATE_RUNNING = "RUNNING";
    this.INSTANCE_STATE_STOPPED = "STOPPED";

    this.STATE_RUNNING = "RUNNING";
    this.STATE_STOPPED = "STOPPED";
    this.STATE_PENDING = "PENDING";

    this.PIPELINE_STATE_STOPPED = "STOPPED";

    this.METRIC_TYPE_NUMERIC = "NUMERIC";
    this.METRIC_TYPE_IMAGE = "IMAGE";
    this.METRIC_TYPE_DATA_FRAME = "DATA_FRAME";
  }
  async find(fieldsStr, options = {}) {
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    if (!options.totalCount) options.totalCount = false;

    let res = await this.api.qry(
      `
      query FindPipelineBlocks($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: PipelineBlockFilter) {
        pipelineBlocks(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return res.data.pipelineBlocks;
  }

  async fetchMetricSeriesData(pipelineBlockUid, metricKey) {
    let filters = {
      clientUid: { eq: this.dm.userSession.clientUid },
      pipelineBlockUid: { eq: pipelineBlockUid },
      key: { eq: metricKey },
      type: { eq: this.METRIC_TYPE_NUMERIC },
    };
    let options = {
      filter: filters,
      sort: {
        field: "name",
        order: "ASC",
      },
    };
    let res = await this.api.qry(
      `
      query FindPipelineBlockMetrics($page: Int, $resultsPerPage: Int, $sort: [SortOption!]!, $filter: PipelineBlockMetricFilter) {
        pipelineBlockMetrics(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          nodes {
            key
            addedAt
            numericValue
          }
        }
      }`,
      options
    );
    let ret = [];
    if (
      res.data &&
      res.data.pipelineBlockMetrics &&
      res.data.pipelineBlockMetrics.nodes
    ) {
      res.data.pipelineBlockMetrics.nodes.forEach((metric) =>
        ret.push({ y: metric.numericValue, x: metric.addedAt })
      );
    }
    return ret;
  }

  async fetchIdeInfo(pipelineBlockUid) {
    let res = await this.api.qry(
      `
      mutation CreateIdeUrl($input: CreateIdeUrlInput!) {
        CreateIdeUrl(input: $input){
          url
          pipelineBlock {
            uid
            name
            description
            ideState {
              resourceDockerTaskUid
              port
              publicIpAddress
              appModuleUid
              state
            }
            block{
              uid
              name
              appModule {
                uid
                state
                type
                serverState {
                  type
                  state
                  port
                  publicIpAddress
                }
                appModuleRepos {
                  nodes {
                    uid
                  }
                }
              }
            }
          } 
        }
      }`,
      {
        input: {
          parentType: this.dm.appModule.IDE_PARENT_TYPE_PIPELINE_BLOCK,
          parentUid: pipelineBlockUid,
          userUid: this.dm.userSession.uid,
          clientUid: this.dm.userSession.clientUid,
        },
      }
    );
    console.log("check res", res.errors, res.data);
   
    if (
      res.data &&
      res.data.CreateIdeUrl
    ) {
      return res.data.CreateIdeUrl;
    }
    else {
      console.error("Check app module res", res.errors);
      return null;
    }
    
  }

  async create(values) {
    let input = this.parseFormValues(values);
    let res = await this.api.mutate(
      `
      mutation CreatePipelineBlock($input: CreatePipelineBlockInput!) {
        createPipelineBlock(input: $input){
          pipelineBlock {
            uid
            name
            description
          }
        }
      }`,
      {
        input: input,
      }
    );
    return res.errors.length
      ? { success: false, errors: res.errors }
      : {
          success: true,
          pipelineBlock: res.data.createPipelineBlock.pipelineBlock,
        };
  }
  async update(values, options = {}) {
    let input = options.parse === false ? values : this.parseFormValues(values);
    let res = await this.api.mutate(
      `
      mutation UpdatePipelineBlock($input: UpdatePipelineBlockInput!) {
        updatePipelineBlock(input: $input){
          pipelineBlock {
            uid
            name
            description
          }
        }
      }`,
      {
        input: input,
      }
    );
    return res.errors.length
      ? { success: false, errors: res.errors }
      : {
          success: true,
          pipelineBlock: res.data.updatePipelineBlock.pipelineBlock,
        };
  }
  async onUpdate(uid, callback, options = {}) {
    let ideStateField = "";
    if (options.ideState) {
      ideStateField = `
    ideState {
      resourceDockerTaskUid
      port
      publicIpAddress
      appModuleUid
      state
    }
`;
    }
    return await this.api.subscribe(
      `
      subscription OnUpdatePipelineBlock($uid: ID!) {
        onUpdatePipelineBlock(uid: $uid){
          uid
          state
          activeInstanceCount
          inputs {
            blockIoUid
            x
            y
            queueItemCount
          }
          outputs {
            blockIoUid
            x
            y
            queueItemCount
          }
          ${ideStateField}
         }
       }`,
      {
        uid: uid,
      },
      (res) => {
        callback(res.data.onUpdatePipelineBlock);
      }
    );
  }
  async onIoUpdate(uid, callback) {
    return await this.api.subscribe(
      `
      subscription OnUpdatePipelineBlockIo($uid: ID!) {
        onUpdatePipelineBlockIo(uid: $uid){
          uid
          queueItemCount
         }
       }`,
      {
        uid: uid,
      },
      (res) => {
        callback(res.data.onUpdatePipelineBlockIo);
      }
    );
  }
  async onStreamLog(uid, callback) {
    return await this.api.subscribe(
      `
      subscription OnStreamPipelineBlockLog($uid: ID!) {
        onStreamPipelineBlockLog(uid: $uid){
          text
        }
      }`,
      {
        uid: uid,
      },
      (res) => {
        callback(res.data.onStreamPipelineBlockLog);
      }
    );
  }

  getHostname() {
    let hostName = window.location.hostname || "r14";
    if (hostName.startsWith("www.")) hostName = hostName.substring(4);
    return hostName;
  }

  createWebUrl(uid, serverType) {
    return `https://${serverType.toLowerCase()}-pb-${this.utils.str.toHex(
      uid
    )}.am.os.${this.getHostname()}`;
  }

  parseFormValues(values) {
    let ret = { ...values };
    ret.clientUid = this.dm.userSession.clientUid;

    if (!ret.cpu) ret.cpu = null;
    if (!ret.memory) ret.memory = null;

    if (values.block) {
      ret.blockUid = values.block.value;
      delete ret.block;
    }
    if (values.boundaryPointMap) {
      delete ret.boundaryPointMap;
    }
    if (values.icon) {
      ret.icon = values.icon.value;
    }
    if (values.datasets) {
      ret.datasetUids = values.datasets.length
        ? values.datasets.map((val) => val.value)
        : null;
      delete ret.datasets;
    }

    // Remove active instance count?
    // delete values.activeInstanceCount;

    // Remove the useAgent switch
    ret.agentCloudAccessKeyUid = values.agentCloudAccessKeyUid || null;
    delete ret.useAgent;

    if (values.statPercentageMatrix)
      ret.statPercentageMatrix = parseFloat(values.statPercentageMatrix);

    // Fix height and width
    if (values.height !== null) ret.height = parseInt(ret.height);
    if (values.width !== null) ret.width = parseInt(ret.width);

    // Fix Max Instances / items per inst
    if (values.maxInstances !== null)
      ret.maxInstances = parseInt(ret.maxInstances);
    if (values.inputItemsPerInstance !== null)
      ret.inputItemsPerInstance = parseInt(ret.inputItemsPerInstance);

    ["inputs", "outputs"].forEach((type) => {
      if (!(type in values)) return;
      ret[type] = null;
      if (values[type]) {
        for (let blockIoUid in values[type]) {
          if (!ret[type]) ret[type] = [];
          let blockInfo = {
            blockIoUid: blockIoUid,
            x: values[type][blockIoUid].x,
            y: values[type][blockIoUid].y,
          };
          if (type === "inputs")
            blockInfo.queueItemCount =
              values[type][blockIoUid].queueItemCount || 0;
          ret[type].push(blockInfo);
        }
      }
    });
    for (let field in values) {
      if (field.startsWith("options_")) {
        if (values[field] !== null) {
          if (!ret.options) ret.options = [];
          ret.options.push({
            key: field.substring(8),
            value: values[field].toString(),
          });
        }
        delete ret[field];
      }
    }

    return ret;
  }

  async get(uid, options = {}) {
    let optionsField = "";
    let ioFields = "";
    let blockField = "";
    let appModuleField = "";
    let statsFields = "";
    let projectField = "";
    let pipelineField = "";
    let instancesField = "";
    let resourceField = "";
    let blockIoQry = "";
    let metricsField = "";
    let ideStateField = "";
    let qryParams = ["$uid: ID!"];
    let qryVals = {
      uid: uid,
    };
    if (options.io) {
      ioFields = `
    inputs {
      blockIoUid
      x
      y
    }
    outputs {
      blockIoUid
      x
      y
    }
  `;
    }
    if (options.ideState) {
      ideStateField = `
    ideState {
      resourceDockerTaskUid
      port
      publicIpAddress
      appModuleUid
      state
    }
`;
    }
    if (options.resource) {
      resourceField = `
    resource {
      uid
      type
    }  
    `;
    }
    if (options.appModule || options.resource) {
      appModuleField = `
    appModule {
      uid
      state
      type
      serverState {
        type
        state
        port
        publicIpAddress
      }
      appModuleRepos {
        nodes {
          uid
        }
      }
      ${resourceField}
    }
    `;
    }
    if (options.block || options.appModule || options.resource) {
      ioFields = `
    block{
      uid
      name
      ${appModuleField}
    }
  `;
    }
    if (options.blockIo) {
      // qryVals.pipelineBlockFilter = {
      //   pipelineBlockUid: { eq: uid },
      //   clientUid: { eq: this.dm.userSession.clientUid },
      // };
      qryVals.blockIoFilter = {
        // type: { eq: this.dm.block.IO_TYPE_INPUT },
        clientUid: { eq: this.dm.userSession.clientUid },
      };
      qryParams.push("$blockIoFilter: BlockIoFilter");
      // qryParams.push("$pipelineBlockFilter: PipelineBlockFilter");
      blockIoQry = `
    blockIo(filter: $blockIoFilter){
      nodes{
        uid
        name
        key
        dataType
        dataStructureType
        type
        blockUid
      }
    }
    `;
    }
    if (options.options) {
      blockField = `
    options {
      key
      label
      required
    }
`;
    }
    if (options.project) {
      if (!options.pipeline)
        throw new Error("Project options requires pipeline");
      projectField = `
    project {
      key
      uid
      name
      type
    }
    `;
    }

    if (options.metrics) {
      metricsField = `
    latestPipelineBlockMetrics {
      nodes {
        uid
        name
        key
        type
        imageValue {
          key
        }
        numericValue
        dataFrameValue
        addedAt
      }
    }
    `;
    }

    if (options.pipeline) {
      pipelineField = `
    pipeline {
      key
      uid
      name
      ${projectField}
    }
    `;
    }

    if (options.instances) {
      instancesField = `
    instanceState {
      resourceDockerTaskUid
      state
    }
    instanceResourceDockerTaskUids
    activeInstanceCount
    `;
    }

    // if (options.stats) {
    //   statsFields = `
    // statPercentageMatrix
    // statConfusionMatrixImageFile {
    //   url
    // }
    //   `;
    // }
    let res = await this.api.qry(
      `
      query GetPipelineBlock(${qryParams.join(", ")}) {
       pipelineBlock(uid: $uid){
          uid
          name
          description
          state
          pipelineUid
          ${statsFields}
          ${blockField}
          ${ioFields}
          ${optionsField}
          ${pipelineField}
          ${instancesField}
          ${metricsField}
          ${blockIoQry}
          ${ideStateField}
        }
      }`,
      qryVals
    );
    return res.data.pipelineBlock;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeletePipelineBlock($uid: ID!) {
        deletePipelineBlock(uid: $uid){
          pipeline {
            uid
          }
        }
      }`,
      {
        uid: uid,
      }
    );
    console.log(res);
    return true;
  }

  async fetchEditFormData(uid = null, options = {}) {
    let qry = "";
    if (!uid && !options.pipelineUid)
      throw new Error(
        "Pipeline Block Form Error: Error pipelineUid not found."
      );
    if (!options.projectUid)
      throw new Error("Pipeline Block Form Error: Error projectUid not found.");
    let blockFilter = { clientUid: { eq: this.dm.userSession.clientUid } };
    let blockSort = [
      {
        field: "name",
        order: "ASC",
      },
    ];
    if (options.projectType)
      blockFilter.projectType = { eq: options.projectType };

    let qryVals = {
      blockFilter: blockFilter,
      blockSort: blockSort,
      projectUid: options.projectUid,
      cloudAccessKeyFilter: {
        clientUid: { eq: this.dm.userSession.clientUid },
        // type: { eq: this.dm.cloudAccessKey.TYPE_R14 },
      },
    };
    if (uid) {
      qry = `
      query PipelineBlockEditFormData($uid: ID!, $projectUid: ID!, $blockFilter: BlockFilter, $blockSort: [SortOption!]!, $cloudAccessKeyFilter: CloudAccessKeyFilter) {
        pipelineBlock(uid: $uid){
          uid
          key
          pipelineUid
          name
          description
          autoRun
          backgroundColor
          height
          width
          icon
          agentCloudAccessKeyUid
          cpu
          memory
          maxInstances
          inputItemsPerInstance
          datasets {
            nodes {
              uid
              name
            }
          }
          options {
            key
            value
          }
          block {
            uid
            name
            appModule {
              uid
              type
              gpuAccelerated
            }
            options {
              key
              label
              required
              type
            }
          }
        },
        project(uid: $projectUid){
          uid
          name
          datasets {
            nodes {
              uid
              name
            }
          }
        }
        blocks(filter:$blockFilter, sort: $blockSort) {
          nodes {
            uid
            name
          }
        }
        cloudAccessKeys(filter: $cloudAccessKeyFilter){
          nodes {
            uid
            name
            type
          }
        }
      }
    `;
      qryVals.uid = uid;
      qryVals.blockIoFilter = {
        type: { eq: this.dm.block.IO_TYPE_INPUT },
        clientUid: { eq: this.dm.userSession.clientUid },
      };
    } else {
      qryVals.pipelineUid = options.pipelineUid;
      qry = `
        query PipelineBlockEditFormData($projectUid: ID!, $blockFilter: BlockFilter, $blockSort: [SortOption!]!, $cloudAccessKeyFilter: CloudAccessKeyFilter) {
          project(uid: $projectUid){
            uid
            name
            datasets {
              nodes {
                uid
                name
              }
            }
          }
          blocks(filter:$blockFilter, sort: $blockSort) {
            nodes {
              uid
              name
            }
          }
          cloudAccessKeys(filter: $cloudAccessKeyFilter){
            nodes {
              uid
              name
              type
            }
          }
        }
      `;
    }

    let res = await this.api.qry(qry, qryVals);
    let optionFields = null;
    let iconSelections = this.getIconSelections();
    let formVals = {
      pipelineUid: options.pipelineUid || null,
    };

    // let memory = 512;
    // let cpu = 512;

    // STANDARD
    // CPU	2048	1024
    // Memory	3884	1836
    // GPU
    // CPU	4096	4096
    // Memory	30654	30654

    // FROM SERVER
    // let memory = 512;
    // let cpu = 512;
    // let gpu = null;
    // case this.TYPE_PYTHON_APP:
    //     switch (serverType) {
    //       case this.SERVER_TYPE_APP:
    //         if (appModule.gpuAccelerated) {
    //           gpu = 1;
    //           cpu = 4096;
    //           memory = 30654;
    //         } else memory = memory / 2;
    //         break;
    //       case this.SERVER_TYPE_IDE:
    //         memory = memory * 2;
    //         break;
    //     }
    //     break;
    //   case this.TYPE_SERVER:
    //   case this.TYPE_TASK:
    //   case this.TYPE_NODE_APP:
    //     switch (serverType) {
    //       case this.SERVER_TYPE_IDE:
    //         memory = memory * 2;
    //         break;
    //     }
    //     break;

    let block = null;
    if (uid) {
      if (res.data.pipelineBlock.block) block = res.data.pipelineBlock.block;
      formVals = { ...formVals, ...res.data.pipelineBlock };
      if (formVals.block) {
        optionFields = formVals.block.options || null;
        formVals.block = {
          value: formVals.block.uid,
          label: formVals.block.name,
        };
      }
      if (formVals.options) {
        let optionsTypeMap = {};
        optionFields &&
          optionFields.forEach(
            (option) => (optionsTypeMap[option.key] = option.type)
          );
        formVals.options.forEach((option) => {
          let type = optionsTypeMap[option.key] || null;
          switch (type) {
            case this.dm.block.OPTION_TYPE_SWITCH:
              formVals[`options_${option.key}`] = option.value === "true";
              break;
            default:
              formVals[`options_${option.key}`] = option.value;
          }
        });
        delete formVals.options;
      }
      if (formVals.icon) {
        formVals.icon = {
          value: formVals.icon,
          label: this.getIconLabelByTypeKey(formVals.icon),
          icon: this.getIconByTypeKey(formVals.icon),
        };
      }
      if (formVals.datasets && formVals.datasets.nodes.length) {
        formVals.datasets = formVals.datasets.nodes.map((val) => ({
          label: val.name,
          value: val.uid,
        }));
      } else formVals.datasets = null;
    }

    // // cpu
    // 512 -> 2048
    // // mem
    // 256 -> 3884

    let cpu = 256;
    let cpuSelections = [{ label: "Default" }];
    while (cpu <= 2048) {
      cpuSelections.push({ label: `${cpu}`, value: cpu });
      cpu += 256;
    }
    let memory = 256;
    let memorySelections = [{ label: "Default" }];
    while (memory <= 3884) {
      memorySelections.push({ label: `${memory}`, value: memory });
      memory += 256;
    }

    let formData = {
      values: formVals,
      block,
      optionFields: optionFields,
      blockSelections: res.data.blocks.nodes.map((val) => ({
        label: val.name,
        value: val.uid,
      })),
      memorySelections,
      cpuSelections,
      cloudAccessKeySelections: res.data.cloudAccessKeys.nodes
        .filter((val) => val.type !== this.dm.cloudAccessKey.TYPE_R14)
        .map((val) => ({
          label: val.name,
          value: val.uid,
          type: val.type,
        })),
      agentCloudAccessKeySelections: res.data.cloudAccessKeys.nodes
        .filter((val) => val.type === this.dm.cloudAccessKey.TYPE_R14)
        .map((val) => ({
          label: val.name,
          value: val.uid,
          type: val.type,
        })),
      datasetSelections: res.data.project.datasets.nodes.map((val) => ({
        label: val.name,
        value: val.uid,
      })),
      iconSelections: iconSelections,
    };
    return formData;
  }

  async updateIo(values, options = {}) {
    let ioMap = options.ioMap;
    let pipelineBlockIoVals = null;
    ["inputs", "outputs"].forEach((type) => {
      if (values[type])
        values[type].forEach((val) => {
          let inputVals = ioMap[val.inputIoMapKey] || {};
          let outputVals = ioMap[val.outputIoMapKey] || {};

          let pipelineBlockIo = {
            clientUid: this.dm.userSession.clientUid,
            // pipelineUid: values.pipelineUid,
            inputBlockUid: inputVals.blockUid || null,
            inputBlockIoUid: inputVals.uid || null,
            inputPipelineBlockUid:
              (inputVals.pipelineBlock && inputVals.pipelineBlock.uid) || null,
            inputBackgroundColor: val.inputBackgroundColor || null,
            outputBlockUid: outputVals.blockUid || null,
            outputBlockIoUid: outputVals.uid || null,
            outputPipelineBlockUid:
              (outputVals.pipelineBlock && outputVals.pipelineBlock.uid) ||
              null,
            outputBackgroundColor: val.outputBackgroundColor || null,
          };

          if (type === "inputs")
            pipelineBlockIo.outputPipelineBlockUid =
              (outputVals.pipelineBlock && outputVals.pipelineBlock.uid) ||
              null;
          else
            pipelineBlockIo.inputPipelineBlockUid =
              (inputVals.pipelineBlock && inputVals.pipelineBlock.uid) || null;

          if (val.uid) pipelineBlockIo.uid = val.uid;
          if (!pipelineBlockIoVals) pipelineBlockIoVals = {};

          //#9c27b0
          if (pipelineBlockIo.uid) {
            if (!pipelineBlockIoVals.update) pipelineBlockIoVals.update = [];
            pipelineBlockIoVals.update.push(pipelineBlockIo);
          } else {
            if (!pipelineBlockIoVals.create) pipelineBlockIoVals.create = [];
            pipelineBlockIoVals.create.push(pipelineBlockIo);
          }
        });
    });
    if (options.removedPipelineBlockIoUids) {
      if (!pipelineBlockIoVals) pipelineBlockIoVals = {};
      pipelineBlockIoVals.delete = options.removedPipelineBlockIoUids;
    }

    let input = {
      uid: values.pipelineUid,
    };
    if (pipelineBlockIoVals) input.pipelineBlockIo = pipelineBlockIoVals;

    // console.log("update", input);

    // Parse the values and options
    let res = await this.api.mutate(
      `
      mutation UpdatePipeline($input: UpdatePipelineInput!) {
        updatePipeline(input: $input){
          pipeline {
            uid
            name
            description
          }

        }
      }`,
      {
        input: input,
      }
    );
    return true;
  }

  async fetchIoEditFormData(uid, pipelineUid, options = {}) {
    let qryVals = {
      uid: uid,
      blockIoFilter: {
        clientUid: { eq: this.dm.userSession.clientUid },
        pipelineBlock: {
          pipelineUid: { eq: pipelineUid },
        },
      },
      pipelineBlockFilter: {
        pipelineUid: { eq: pipelineUid },
        clientUid: { eq: this.dm.userSession.clientUid },
      },
      pipelineBlockInputFilter: {
        clientUid: { eq: this.dm.userSession.clientUid },
      },
      pipelineBlockOutputFilter: {
        clientUid: { eq: this.dm.userSession.clientUid },
      },
    };

    let qry = `
      query PipelineBlockIoFormData($uid: ID!, $blockIoFilter: BlockIoFilter, $pipelineBlockInputFilter: PipelineBlockIoFilter,  $pipelineBlockOutputFilter: PipelineBlockIoFilter, $pipelineBlockFilter: PipelineBlockFilter){
        pipelineBlock(uid: $uid){
          uid
          name
          description
          pipelineBlockInputs(filter: $pipelineBlockInputFilter) {
            nodes {
              uid
              inputBlockUid
              inputBlockIoUid
              inputPipelineBlockUid
              inputBackgroundColor
              outputBlockUid
              outputBlockIoUid
              outputPipelineBlockUid
              outputBackgroundColor
            }
          }
          pipelineBlockOutputs(filter: $pipelineBlockOutputFilter) {
            nodes {
              uid
              inputBlockUid
              inputBlockIoUid
              inputPipelineBlockUid
              inputBackgroundColor
              outputBlockUid
              outputBlockIoUid
              outputPipelineBlockUid
              outputBackgroundColor
            }
          }
        }
        pipelineBlocks(filter: $pipelineBlockFilter){
          nodes {
            uid
            name
            blockUid
          }
        }
        blockIos(filter: $blockIoFilter){
          nodes{
            uid
            name
            dataType
            dataStructureType
            type
            blockUid
            pipelineBlock (filter: $pipelineBlockFilter){
              pipelineUid
            }
          }
        }
      }
    `;

    let res = await this.api.qry(qry, qryVals);

    let formData = {
      inputs: {
        inputSelections: [],
        outputSelections: [],
      },
      outputs: {
        inputSelections: [],
        outputSelections: [],
      },
      ioMap: {},
      values: {
        uid,
        pipelineUid,
        inputs: [],
        outputs: [],
      },
    };

    // Map block ios by block / uid
    let blockIoMap = {};
    res.data.blockIos &&
      res.data.blockIos.nodes.forEach((blockIo) => {
        if (!blockIoMap[blockIo.blockUid]) blockIoMap[blockIo.blockUid] = {};
        blockIoMap[blockIo.blockUid][blockIo.uid] = { ...blockIo };
        delete blockIoMap[blockIo.blockUid][blockIo.uid].pipelineBlock;
      });

    // Get available selections for inputs / ouputs
    res.data.pipelineBlocks &&
      res.data.pipelineBlocks.nodes.forEach((pipelineBlock) => {
        let blockIos = blockIoMap[pipelineBlock.blockUid];
        for (let blockIoUid in blockIos) {
          let blockIo = blockIos[blockIoUid];
          let node = {
            value: `${pipelineBlock.uid},${blockIo.uid}`,
            label: null,
          };
          if (pipelineBlock.uid === uid) {
            node.label = blockIo.name;
            if (blockIo.type === this.dm.block.IO_TYPE_INPUT)
              formData.inputs.inputSelections.push(node);
            else formData.outputs.outputSelections.push(node);
          } else {
            node.label = `${pipelineBlock.name}: ${blockIo.name}`;
            if (blockIo.type === this.dm.block.IO_TYPE_INPUT)
              formData.outputs.inputSelections.push(node);
            else formData.inputs.outputSelections.push(node);
          }
          formData.ioMap[node.value] = { ...blockIo, pipelineBlock };
        }
      });

    if (res.data.pipelineBlock) {
      let createIoValue = (val) => ({
        uid: val.uid,
        inputBackgroundColor: val.inputBackgroundColor || null,
        outputBackgroundColor: val.outputBackgroundColor || null,
        inputIoMapKey: `${val.inputPipelineBlockUid},${val.inputBlockIoUid}`,
        outputIoMapKey: `${val.outputPipelineBlockUid},${val.outputBlockIoUid}`,
      });
      if (res.data.pipelineBlock.pipelineBlockInputs)
        res.data.pipelineBlock.pipelineBlockInputs.nodes.forEach((val) =>
          formData.values.inputs.push(createIoValue(val))
        );
      if (res.data.pipelineBlock.pipelineBlockOutputs)
        res.data.pipelineBlock.pipelineBlockOutputs.nodes.forEach((val) =>
          formData.values.outputs.push(createIoValue(val))
        );
    }
    return formData;
  }

  async fetchEventEditFormData(uid, pipelineUid, options = {}) {
    let qry = "";
    if (!uid)
      throw new Error(
        "Pipeline Block Event Form Error: Error pipelineBlockUid not found."
      );
    if (!pipelineUid)
      throw new Error(
        "Pipeline Block Event Form Error: Error pipelineUid not found."
      );

    let qryVals = {};

    qry = `
      query PipelineBlockEventFormData($uid: ID!, $blockIoFilter: BlockIoFilter) {
        pipelineBlock(uid: $uid){
          uid
          pipelineUid
          runTriggers {
            occurs
            frequency {
              type
              value
              values
              at
            }
            blockIo {
              uid
              threshold
              maxInstances
              inputItemsPerInstance
            }
          }
          blockIo(filter:$blockIoFilter) {
            nodes {
              uid
              name
              type
            }
          }
        }
      }
    `;
    // eventTask {
    //   type
    //   uid
    //   event {
    //     uid
    //     schedule {
    //       occurs
    //       frequency {
    //         type
    //         value
    //         values
    //         at
    //       }
    //       blockIo {
    //         uid
    //         threshold
    //         maxInstances
    //         inputItemsPerInstance
    //       }
    //     }
    //   }
    // }
    qryVals.uid = uid;
    qryVals.blockIoFilter = {
      type: { eq: this.dm.block.IO_TYPE_INPUT },
      clientUid: { eq: this.dm.userSession.clientUid },
    };

    let res = await this.api.qry(qry, qryVals);
    let formVals = {
      uid: uid,
      pipelineUid: pipelineUid,
    };
    // if (uid) {
    //   formVals = { ...formVals, ...res.data.pipelineBlock };
    // }
    formVals.eventSchedule = res.data.pipelineBlock.runTriggers;
    // formVals.eventSchedule =
    //   res.data.pipelineBlock.eventTask &&
    //   res.data.pipelineBlock.eventTask.event &&
    //   res.data.pipelineBlock.eventTask.event.schedule
    //     ? res.data.pipelineBlock.eventTask.event.schedule
    //     : null;

    // if (
    //   res.data.pipelineBlock.eventTask &&
    //   res.data.pipelineBlock.eventTask.event
    // ) {
    //   formVals.eventTaskUid = res.data.pipelineBlock.eventTask.uid;
    //   formVals.eventUid = res.data.pipelineBlock.eventTask.event.uid;
    //   formVals.eventSchedule = res.data.pipelineBlock.eventTask.event.schedule
    //     ? res.data.pipelineBlock.eventTask.event.schedule
    //     : null;
    // }
    let formData = {
      values: formVals,
      occursSelections: this.dm.event.getOccursSelections(
        this.dm.event.TARGET_TYPE_PIPELINE_BLOCK
      ),
      intervalTypeSelections: this.dm.event.getIntervalTypeSelections(),
      daySelections: this.dm.event.getFrequencyDaySelections(),
      blockIoInputSelections: res.data.pipelineBlock.blockIo.nodes.length
        ? res.data.pipelineBlock.blockIo.nodes.map((val) => ({
            label: val.name,
            value: val.uid,
          }))
        : [{ label: "No Input Available", value: null }],
    };
    return formData;
  }

  async updateEvent(values, options = {}) {
    // let isEditMode = values.eventTaskUid ? true : false;
    // let input = {
    //   uid: values.eventUid || null,
    //   clientUid: this.dm.userSession.clientUid,
    //   name: `Pipeline Block (${values.uid})`,
    //   schedule: values.eventSchedule,
    //   triggers: [{ type: this.dm.event.TYPE_START }],
    // };
    // console.log("HERE HERE HERE HER HERE HERE");
    // let isSuccess = true;
    // if (isEditMode) {
    //   if (values.eventSchedule)
    //     isSuccess = await this.dm.event.update(input, {
    //       type: this.dm.eventTask.TYPE_PIPELINE_BLOCK,
    //       pipelineBlockUid: values.uid,
    //     });
    //   else isSuccess = await this.dm.event.delete(values.eventUid);
    // } else if (values.eventSchedule) {
    //   isSuccess = await this.dm.event.create(input, {
    //     type: this.dm.eventTask.TYPE_PIPELINE_BLOCK,
    //     pipelineBlockUid: values.uid,
    //   });
    // }
    console.log(values.eventSchedule);
    // if (values.eventSchedule && isSuccess) {
    let res = await this.update(
      {
        uid: values.uid,
        runTriggers: values.eventSchedule,
        clientUid: this.dm.userSession.clientUid,
      },
      { parse: false }
    );
    return res && res.success ? true : false;
    // }
    //return isSuccess;
  }

  async fetchAddFormData(pipelineUid, { projectType = null }) {
    return {
      values: { pipelineUid },
      blockSelections: await this.fetchSelections({
        projectType,
      }),
    };
  }

  async fetchSelections(filters = {}) {
    let filter = {};
    if (filters.search) {
      filter.search = { like: `%${filters.search}%` };
    }
    if (!this.dm.user.hasAdminRole)
      filter.userUids = { eq: this.dm.userSession.uid };

    let res = await this.dm.block.find(
      `
      uid
      name
      `,
      {
        page: 1,
        resultsPerPage: 10,
        filter: filter,
        totalCount: false,
        sort: [
          {
            field: "name",
            order: "DESC",
          },
        ],
      }
    );

    let ret =
      res && res.nodes
        ? res.nodes.map((val) => ({
            label: val.name,
            value: val.uid,
          }))
        : [];
    return ret;
  }
  async fetchBlockSelections(filters = {}) {
    let filter = {};
    if (filters.search) {
      filter.search = { like: `%${filters.search}%` };
    }
    if (filters.projectType) {
      filter.projectType = { eq: filters.projectType };
    }
    if (filters.pipelineUid) {
      filter.pipelineUid = { eq: filters.pipelineUid };
    }
    // if (!this.dm.user.hasAdminRole)
    //   filter.userUids = { eq: this.dm.userSession.uid };

    let res = await this.dm.block.find(
      `
      uid
      name
      `,
      {
        page: 1,
        resultsPerPage: 10,
        filter: filter,
        totalCount: false,
        sort: [
          {
            field: "name",
            order: "ASC",
          },
        ],
      }
    );
    let ret =
      res && res.nodes
        ? res.nodes.map((val) => ({
            label: val.name,
            value: val.uid,
          }))
        : [];
    return ret;
  }
  async fetchDatasetSelections(projectUid, filters = {}) {
    let filter = {};
    let ret = [];

    if (filters.search) {
      filter.search = { like: `%${filters.search}%` };
    }
    filter.projectUid = { eq: projectUid };

    let res = await this.dm.project.get(projectUid, { datasets: true });
    if (res.datasets && res.datasets.nodes.length) {
      ret = res.datasets.nodes
        .filter((val) => {
          if (!filters.search) return true;
          console.log(
            val.name.toUpperCase(),
            filters.search.toUpperCase(),
            val.name.toUpperCase().indexOf(filters.search.toUpperCase())
          );
          return (
            val.name.toUpperCase().indexOf(filters.search.toUpperCase()) !== -1
          );
        })
        .map((val) => ({ value: val.uid, label: val.name }));
    }
    return ret;
  }

  getStateIndicatorColor(state) {
    let color = null;
    switch (state) {
      case this.STATE_RUNNING:
        color = "green";
        break;
      case this.STATE_PENDING:
        color = "green";
        break;
      default:
        color = "red";
    }
    return color;
  }

  getStateLabel(state) {
    let label = null;
    switch (state) {
      case this.STATE_RUNNING:
        label = "Running";
        break;
      case this.STATE_PENDING:
        label = "Pending";
        break;
      case this.STATE_STOPPED:
        label = "Stopped";
        break;
    }
    return label;
  }

  detectCollisions(block, blocks, cellSize) {
    let collisions = [];
    let activePos = {
      top: block.y,
      left: block.x,
      bottom: block.y + block.height,
      right: block.x + block.width,
    };
    if (!blocks) blocks = this.state.blocks;
    for (let uid in blocks) {
      let currBlock = blocks[uid];
      if (uid === block.uid) continue;
      let pos = {
        top: currBlock.y * cellSize,
        left: currBlock.x * cellSize,
        bottom: currBlock.y * cellSize + currBlock.height * cellSize,
        right: currBlock.x * cellSize + currBlock.width * cellSize,
      };
      // Check if completely over
      if (
        (activePos.left <= pos.left && activePos.right >= pos.right) ||
        (activePos.right > pos.left && activePos.right <= pos.right) ||
        (activePos.left >= pos.left && activePos.left < pos.right)
      ) {
        if (
          (activePos.top <= pos.top && activePos.bottom >= pos.bottom) ||
          (activePos.bottom > pos.top && activePos.bottom <= pos.bottom) ||
          (activePos.top >= pos.top && activePos.top < pos.bottom)
        ) {
          collisions.push(currBlock);
        }
      }
    }
    return collisions;
  }

  async runCommand(uid, type, command, options = {}) {
    // Parse the values and options
    let args = {
      uid,
      type,
      command,
    };
    if (options.instanceResourceDockerTaskUids)
      args.instanceResourceDockerTaskUids =
        options.instanceResourceDockerTaskUids;
    let res = await this.api.mutate(
      `
      mutation RunPipelineBlockCommand($uid: ID!, $type: PipelineBlockCommandTypeEnum!, $command: PipelineBlockCommandEnum!, $instanceResourceDockerTaskUids: [ID!]) {
        runPipelineBlockCommand(uid: $uid, type: $type, command: $command, instanceResourceDockerTaskUids: $instanceResourceDockerTaskUids){
          success
          pipelineBlock {
            activeInstanceCount
            state
            ideState {
              state
              resourceDockerTaskUid
              port
              publicIpAddress
              appModuleUid
            }
          }
        }
      }`,
      args
    );
    if (res.errors.length) {
      console.error(res.errors);
      return false;
    }
    return (res.data && res.data.runPipelineBlockCommand) || false;
  }

  async deleteData(uid, options = { deleteBlock: false }) {
    // Parse the values and options
    let res = await this.api.mutate(
      `
      mutation DeletePipelineBlockData($uid: ID!, $deletePipelineBlock: Boolean) {
        deletePipelineBlockData(uid: $uid, deletePipelineBlock: $deletePipelineBlock){
          success
          pipelineBlock {
            activeInstanceCount
            state
          }
      }
      }`,
      {
        uid,
        deletePipelineBlock: options.deleteBlock ? true : false,
      }
    );
    if (res.errors.length) {
      console.error(res.errors);
      return false;
    }
    return (res.data && res.data.deletePipelineBlockData) || false;
  }

  getIconSelections() {
    return [
      {
        value: "dataset",
        label: this.getIconLabelByTypeKey("dataset"),
        icon: this.getIconByTypeKey("dataset"),
      },
      {
        value: "datasetListener",
        label: this.getIconLabelByTypeKey("datasetListener"),
        icon: this.getIconByTypeKey("datasetListener"),
      },
      {
        value: "datasetStore",
        label: this.getIconLabelByTypeKey("datasetStore"),
        icon: this.getIconByTypeKey("datasetStore"),
      },
      {
        value: "validator",
        label: this.getIconLabelByTypeKey("validator"),
        icon: this.getIconByTypeKey("validator"),
      },
      {
        value: "manualEntry",
        label: this.getIconLabelByTypeKey("manualEntry"),
        icon: this.getIconByTypeKey("manualEntry"),
      },
      {
        value: "model",
        label: "Model",
        icon: this.getIconByTypeKey("model"),
        icon: this.getIconByTypeKey("model"),
      },
      {
        value: "logical",
        label: this.getIconLabelByTypeKey("logical"),
        icon: this.getIconByTypeKey("logical"),
      },
    ];
  }
  getIconLabelByTypeKey(type) {
    let ret = null;
    switch (type) {
      case "dataset":
        ret = "Dataset";
        break;
      case "datasetListener":
        ret = "Dataset Listener";
        break;
      case "datasetStore":
        ret = "Dataset Store";
        break;
      case "validator":
        ret = "Validator";
        break;
      case "manualEntry":
        ret = "Manual Entry";
        break;
      case "model":
        ret = "Model";
        break;
      case "logical":
        ret = "Logical";
        break;
    }
    return ret;
  }
  getIconByTypeKey(type) {
    let ret = null;
    switch (type) {
      case "dataset":
        ret = "database";
        break;
      case "datasetListener":
        ret = "databaseExport";
        break;
      case "datasetStore":
        ret = "databaseImport";
        break;
      case "validator":
        ret = "checkCircle";
        break;
      case "manualEntry":
        ret = "eyeCheckOutline";
        break;
      case "model":
        ret = "cogs";
        break;
      case "logical":
        ret = "sourceBranch";
        break;
    }
    return ret;
  }
}
