import React from "react";
import R14, {
  Colors,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  DataTable,
} from "../core";
import EntityDataTable from "./EntityDataTable";

export default R14.connect(
  class EntityDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.projectDomain = this.props.app.dm.project;
      this.entityDomain = this.props.entityDomain;
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.handleRowRemovePress = this.handleRowRemovePress.bind(this);
      this.handleRowEditPress = this.handleRowEditPress.bind(this);
      this.handleCreatePress = this.handleCreatePress.bind(this);
      this.handleRowPress = this.handleRowPress.bind(this);
      if (!(this.props.entityDomain instanceof R14.EntityDomain))
        throw new Error("Must be instance of entity domain.");
    }
    get projectKey() {
      return this.props.projectType
        ? this.props.app.dm.project.getKeyByType(this.props.projectType)
        : null;
    }
    get perms() {
      return this.props.perms || {};
    }

    // async getPageData(
    //   { page, rowsPerPage, sortColumnName, sortDirection, search = null },
    //   options = {}
    // ) {

    // }
    // async getPageData(
    //   { page, rowsPerPage, sortColumnName, sortDirection, search = null },
    //   options = {}
    // ) {
    //   let filter = {
    //     projectUid: { eq: this.props.projectUid },
    //   };
    //   if (search) filter.search = { like: `%${search}%` };
    //   if (this.props.type) filter.type = { eq: this.props.type };
    //   let res = await this.documentTemplateDomain.find(
    //     `
    //     uid
    //     name
    //     description
    //     `,
    //     {
    //       page: page,
    //       resultsPerPage: rowsPerPage,
    //       filter: filter,
    //       sort: [
    //         {
    //           field: sortColumnName,
    //           order: sortDirection.toUpperCase(),
    //         },
    //       ],
    //       totalCount: options.totalCount || false,
    //     }
    //   );
    //   return {
    //     pageData: res.nodes,
    //     totalRows: res.totalCount || null,
    //   };
    // }
    async initializer(gridState) {
      return await this.props.pageLoader(gridState, {
        totalCount: true,
      });
    }
    async pageLoader(gridState) {
      let res = await this.props.pageLoader(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable(this.props.name);
    }
    get title() {
      return this.props.title;
    }
    cellRenderer({ cell, columnName, row }) {
      let content = cell;
      let style = [styles.cellText];
      return this.props.cellRenderer ? (
        this.props.cellRenderer({ cell, columnName, row })
      ) : (
        <Text style={style}>{content}</Text>
      );
    }
    rowKeyExtractor(row) {
      return row.uid;
    }
    async handleRowRemovePress(row, dataTable) {
      if (this.props.onRowRemovePress)
        return this.props.onRowRemovePress(row, dataTable);
      let rowName = this.props.rowNameExtractor
        ? this.props.rowNameExtractor(row)
        : "this item";
      if (window.confirm(`Are you sure you want to remove ${rowName}?`)) {
        this.props.app.ui.progressIndicator.show();
        await this.entityDomain.delete(row.uid);
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
        dataTable.refresh({ indicator: true });
        this.props.app.ui.snackBar.show({
          message: `${rowName} has been removed.`,
        });
      }
    }
    handleCreatePress() {
      if (this.props.onCreatePress) return this.props.onCreatePress();
      let args = {};
      let action = `${this.props.entityDomain.entityName}Create`;
      if (this.props.projectType && this.props.projectUid) {
        args = {
          uid: this.props.projectUid,
          key: this.props.app.dm.project.getKeyByType(this.props.projectType),
        };
        action = `project${this.props.entityDomain.formatEntityName({
          capitalize: true,
        })}Create`;
      }
      this.props.app.nav.to(this.props.createAction || action, args);
    }
    handleRowEditPress(row) {
      if (this.props.onRowEditPress) return this.props.onRowEditPress(row);
      let args = {};
      let action = `${this.props.entityDomain.entityName}Edit`;
      if (this.props.projectType && this.props.projectUid) {
        args = {
          uid: this.props.projectUid,
          key: this.props.app.dm.project.getKeyByType(this.props.projectType),
          [`${this.props.entityDomain.entityName}Uid`]: row.uid,
        };
        action = `project${this.props.entityDomain.formatEntityName({
          capitalize: true,
        })}Edit`;
      } else
        args = {
          uid: row.uid,
        };
      this.props.app.nav.to(this.props.rowEditAction || action, args);
    }
    handleRowPress(row) {
      if (this.props.onRowPress) return this.props.onRowPress(row);
      let args = {};
      let action = `${this.props.entityDomain.entityName}`;
      if (this.props.projectType && this.props.projectUid) {
        args = {
          uid: this.props.projectUid,
          key: this.props.app.dm.project.getKeyByType(this.props.projectType),
          [`${this.props.entityDomain.entityName}Uid`]: row.uid,
        };
        action = `project${this.props.entityDomain.formatEntityName({
          capitalize: true,
        })}`;
      } else
        args = {
          uid: row.uid,
        };
      this.props.app.nav.to(this.props.pressAction || action, args);
    }
    render() {
      return (
        <DataTable
          name={this.props.name}
          style={this.props.style}
          columns={this.props.columns}
          searchable={this.props.searchable}
          autoRefresh={this.props.autoRefresh || null}
          initializer={this.initializer}
          rowKeyExtractor={this.props.rowKeyExtractor || this.rowKeyExtractor}
          onRowPress={
            this.props.onRowPress !== false ? this.handleRowPress : null
          }
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          rowBottom={this.props.rowBottom}
          initialRowsPerPage={this.props.initialRowsPerPage || 10}
          headerTitle={this.props.title || this.props.headerTitle}
          headerStyle={this.props.headerStyle}
          paddingBottom={72} // Make space for the FAB
          initialSortColumnName={this.props.initialSortColumnName}
          initialSortDirection={this.props.initialSortDirection || "asc"}
          rowControlsRightWidth={this.props.rowControlsRightWidth || null}
          headerControlsRight={
            this.perms.create
              ? [
                  <PopUpMenu
                    controlIcon='dotsVertical'
                    key='adminMenu'
                    direction='downLeft'
                  >
                    <PopUpMenuItem
                      icon='add'
                      iconSize='small'
                      label={`Create ${this.props.title}`}
                      onPress={this.handleCreatePress}
                      key='add'
                    />
                  </PopUpMenu>,
                ]
              : null
          }
          rowControlsRight={
            this.props.rowControlsRight === false
              ? null
              : this.props.rowControlsRight
              ? this.props.rowControlsRight
              : this.perms.edit || this.perms.delete
              ? (row, dataTable) => {
                  let ret = [];
                  if (this.perms.edit)
                    ret.push(
                      <IconButton
                        icon='edit'
                        size='small'
                        key='edit'
                        onPress={() => this.handleRowEditPress(row)}
                      />
                    );
                  if (this.perms.create || this.perms.delete)
                    ret.push(
                      <PopUpMenu
                        controlIcon='dotsHorizontal'
                        controlIconSize='small'
                        key='projectDocumentTemplateMenu'
                        direction='downLeft'
                      >
                        {this.perms.delete && (
                          <PopUpMenuItem
                            icon='close'
                            label='Remove'
                            key='remove'
                            onPress={() =>
                              this.handleRowRemovePress(row, dataTable)
                            }
                          />
                        )}
                      </PopUpMenu>
                    );
                  return ret;
                }
              : null
          }
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
  },
});
