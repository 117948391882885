export default class R14AppNavigationBase {
  constructor(r14, app, options = {}) {
    this._app = app;
    this._r14 = r14;
  }
  back(options = {}) {
    /** @todo History go back should check to make sure there is a previous route. If not, it should either go to the modals initialParentRoute or the initial route for the app. */

    this._historyAction("back", null, null, options);
  }
  // handleBeforeNavigation(){

  // }
  openExternalUrl(url) {
    window.open(url);
  }
  open(route, params = {}) {
    this._historyAction("open", route, params);
  }
  to(route, params = {}) {
    this._historyAction("push", route, params);
  }
  navigate(route, params = {}) {
    this._historyAction("push", route, params);
  }
  replace(route, params) {
    this._historyAction("replace", route, params);
  }
  push(route, params = {}) {
    this._historyAction("push", route, params);
  }
  _historyAction(action, route, params = {}, options = {}) {
    // Check forms for onBeforeNavigation
    let shouldLeave = true;
    let ignoreForms = options.ignoreForms || [];
    let state = null;
    this._r14.app.ui.form.forEach((form) => {
      if (
        form.onBeforeNavigation &&
        !ignoreForms.includes(form.name) &&
        !form.isSubmitting
      ) {
        if (!shouldLeave)
          throw new Error(
            "[FORM ERROR] Multiple form onBeforeNavigation not supported"
          );
        if (typeof form.onBeforeNavigation === "function") {
          let formRes = form.onBeforeNavigation(form);
          if (!formRes) shouldLeave = false;
          else if (formRes instanceof Promise) {
            formRes.then((promiseFormRes) => {
              if (typeof promiseFormRes === "string")
                shouldLeave = window.confirm(promiseFormRes);
              else if (promiseFormRes === true) {
                let nOpts = { ...options };
                if (!nOpts.ignoreForms) nOpts.ignoreForms = [];
                if (!nOpts.ignoreForms.includes(form.name))
                  nOpts.ignoreForms.push(form.name);
                this._historyAction(action, route, params, nOpts);
              }
            });
            shouldLeave = false;
          } else if (formRes === true) {
            // do nothing
          } else if (typeof formRes === "string") {
            shouldLeave = window.confirm(formRes);
          }
        } else if (typeof form.onBeforeNavigation === "string") {
          shouldLeave = window.confirm(form.onBeforeNavigation);
        }
      }
    });
    if (!shouldLeave) return false;

    if (action === "back") {
      this._r14.navigation._history.goBack();
      return;
    }

    // Create history state, and deal with container navigation
    let routeName = typeof route === "object" ? route.route : route;
    let portal = this.getPortal(route);
    let path = this.getPath(route, params);
    let historyState = this._r14.navigation.createHistoryState(routeName, path);
    switch (portal.type) {
      case this._r14.navigation.PORTAL_TYPE_CONTAINER:
        path =
          this._r14.navigation._location.pathname +
          this._r14.navigation._location.search;
        break;
    }
    if (action === "open") {
      this.openExternalUrl(path);
      return;
    }
    else this._r14.navigation._history[action](path, historyState);
  }
  getPath(route, params = {}) {
    if (typeof route === "object") {
      params = route.params || {};
      route = route.route || null;
    }
    let path = this._r14.navigation.getPathByRouteName(route);
    if (!path) throw `Could not find path for route '${route}'`;
    let urlParams = [];
    if (params) {
      /** @todo check for false matches */
      let paramKeys = Object.keys(params).sort((a, b) => b.length - a.length);
      for (let name of paramKeys) {
        if (path.indexOf(`:${name}`) != -1) {
          path = path.replace(`:${name}`, params[name]);
        } else {
          urlParams.push(`${name}=${params[name]}`);
        }
      }
      if (urlParams.length) path = `${path}?${urlParams.join("&")}`;
    }
    return path;
  }
  getPortal(route) {
    if (typeof route === "object") route = route.route || null;
    if (!route) return null;
    return this._r14.navigation.getPortalByRouteName(route);
  }
  /** Updates the history state, only adds or replaces given keys */
  updateState(state) {
    if (!this._r14.navigation._location) return false;
    let path =
      this._r14.navigation._location.pathname +
      this._r14.navigation._location.search;
    let newState = { ...this.state, ...state };
    this._r14.navigation._history.replace(path, newState);
  }
  get state() {
    return this._r14.navigation._location.state || {};
  }
  get route() {
    let activeRoute = this._r14.navigation.activeRouteName;
    if (!activeRoute) return null;
    let route = this._r14.navigation.screens(activeRoute) || null;
    if (route) {
      route.name = activeRoute;
      // This should not be hardcoded, it should use the tru parent
      if (route.portal === "modal" && route.initialParentRoute) {
        let rootPortal = this._r14.navigation.portals("root");
        if (rootPortal.route.name)
          route.initialParentRoute = rootPortal.route.name;
        //   this.props.app.nav.to(rootPortal.route.name, {
        //     ...rootPortal.route.params,
        //     ...rootPortal.route.query,
        //   });
      }
    }
    // Todo make sure that the parent portal is route
    route.data = this._r14.navigation.activeRouteData;
    return route;
  }
  get rt() {
    return this.route;
  }
}
