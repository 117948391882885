import React from "react";
import R14, {
  View,
  IconButton,
  StyleSheet,
  Theme,
  Colors,
  PopUpMenu,
  PopUpMenuItem,
  Image,
} from "../core";

export default R14.connect(
  class HeaderControlsLeft extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      // For now, force a render when the route parent changes.
      let logo = null;
      //console.log("CHECK THEME", Theme.componentOptions("logo").company);
      let logoCompany = Theme.componentOptions("logo").company || "rule14";
      let logoColorScheme =
        this.props.logoColorScheme ||
        Theme.componentOptions("logo").colorScheme ||
        "onDark";
      let logoHeight = 56;
      let logoMobileHeight = 48;
      switch (logoCompany) {
        case "rule14":
          switch (logoColorScheme) {
            case "onLight":
              logo = require("../assets/images/rule14_onLight_logo.png");
              break;
            case "onDark":
              logo = require("../assets/images/rule14_onDark_logo.png");
              break;
          }
          break;
        case "exela":
          logoHeight = 40;
          logoMobileHeight = 40;
          switch (logoColorScheme) {
            case "onLight":
              logo = require("../assets/images/exela_onLight_logo.png");
              break;
            case "onDark":
              logo = require("../assets/images/exela_onDark_logo.png");
              break;
          }
          break;
      }

      const logoStyles = StyleSheet.create({
        logoImage: {
          height: logoHeight,
          screen: ({ width }) => {
            if (width <= 640)
              return {
                height: logoMobileHeight,
              };
          },
        },
      });
      let hasDrawer =
        this.props.app.nav.route.parent !== "main" &&
        this.props.app.ui.navigator.exists("homeNavigator");
      return (
        <View
          style={[
            styles.headerControlsRight,
            !hasDrawer && styles.headerNoDrawer,
          ]}
        >
          {hasDrawer && (
            <IconButton
              icon={this.props.menuIcon || "menu"}
              style={styles.menuIcon}
              color={
                this.props.menuIconColor || Colors.onPrimary
                // Theme.componentColors("header", {
                //   default: Colors.onPrimary,
                // }).onBackground
              }
              onPress={() =>
                this.props.app.ui.navigator("homeNavigator").toggle()
              }
            />
          )}
          {logo && (
            <Image
              resizeMode='contain'
              style={[styles.logoImage, logoStyles.logoImage]}
              source={logo}
            />
          )}
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  headerControlsRight: {
    flex: 1,
    flexDirection: "row",
    // paddingRight: 6,
    paddingLeft: 8,
    alignItems: "center",
  },
  headerNoDrawer: {
    paddingLeft: 16,
  },
  logoImage: {
    height: 56,
    width: 160,
    // height: 40,
    // width: 160,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          height: 48,
          width: 144,
        };
    },
  },
  menuIcon: {
    ...StyleSheet.padding(12, 12, 12, 12),
    height: 48,
    ...StyleSheet.margin(0, 8, 0, 0),
    screen: ({ width }) => {
      if (width <= 640)
        return {
          ...StyleSheet.padding(12, 12, 12, 12),
          ...StyleSheet.margin(0, 8, 0, 0),
        };
    },
  },
});
