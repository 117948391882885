import React from "react";
import R14, { Colors, StyleSheet, Text } from "../core";
import EntityDataTable from "./EntityDataTable";
import StateIndicatorText from "./StateIndicatorText";
export default R14.connect(
  class ManualEntryDocumentDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.appModuleBuildDomain = this.props.app.dm.appModuleBuild;
      this.pageLoader = this.pageLoader.bind(this);
      this.perms = this.props.app.dm.user.getPermissions(
        this.projectKey,
        "appModule"
      );
    }
    get projectKey() {
      return this.props.app.dm.project.getKeyByType(this.props.projectType);
    }
    async pageLoader(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {
        projectUid: { eq: this.props.projectUid },
      };
      if (search) filter.search = { like: `%${search}%` };
      if (this.props.type) filter.type = { eq: this.props.type };
      let res = await this.appModuleBuildDomain.find(
        `
        uid
        description
        version
        appModule {
          name
        }
        state
        createdAt
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    render() {
      return (
        <EntityDataTable
          entityDomain={this.appModuleBuildDomain}
          searchable
          title='App Builds'
          name='appModuleBuilds'
          columns={{
            appModule: {
              label: "Name",
              sortable: true,
            },
            version: {
              label: "Version",
              sortable: true,
            },
            state: {
              label: "State",
              sortable: true,
            },
            createdAt: {
              label: "Created",
              sortable: true,
            },
          }}
          cellRenderer={({ cell, columnName, row, columns }) => {
            let ret = null;
            switch (columnName) {
              case "appModule":
                ret = cell.name;
                break;
              case "createdAt":
                let date = new Date(cell);
                ret = `${this.props.app.utils.date.toShortDate(
                  date
                )} ${this.props.app.utils.date.toTime(date)}`;
                break;
              case "state":
                ret = (
                  <StateIndicatorText
                    label={this.appModuleBuildDomain.getStateLabel(row.state)}
                    color={this.appModuleBuildDomain.getStateIndicatorColor(
                      row.state
                    )}
                  />
                );
                break;
              default:
                ret = cell;
            }
            return <Text style={[styles.cellText]}>{ret}</Text>;
          }}
          pageLoader={this.pageLoader}
          initialSortColumnName='updatedAt'
          initialSortDirection='desc'
          perms={this.perms}
          projectUid={this.props.projectUid}
          projectType={this.props.projectType}
          autoRefresh={30}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
  },
});
