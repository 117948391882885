export default {
  uniqueKey: (val) => {
    let keys = {};
    let isValid = true;
    if (val) {
      for (let i in val) {
        if (keys[val[i].key]) {
          isValid = false;
          break;
        }
        keys[val[i].key] = true;
      }
    }
    return isValid ? true : { error: "All keys must be unique." };
  },
};
