import axios from "axios";
import AsyncStorage from "./components/AsyncStorage";
import R14ApiSubscription from "./R14ApiSubscription";
import R14ApiResult from "./R14ApiResult";

export default class R14Api {
  constructor(R14, app, options) {
    if (!options.config || !options.config.api)
      throw "Api Error: No default config options found.";
    if (!options.config.api.url)
      throw "Api Error: No default config url found.";
    this._config = options.config.api;
    this.qry = this.query;
    this.mut = this.mutate;
    this._r14 = R14;
    this._qryBatch = {};
    this._qryBatchTimeout = null;
    this._qryBatchTimeouts = {};
    this._qryBatchIdx = 0;
    this._qryBatchTimeoutIdx = 0;
    this._qryBatchTimeoutKey = null;
    this._qryBatchResponses = {};
    this._fileBlobCache = {};
  }
  async batch(queries, options = {}) {
    if (Array.isArray()) {
      throw new Error("TODO ADD BATCH QURY BY ARR");
    } else if (typeof queries !== "object")
      throw new Error("Api Error: Batch must be given object or array");
    return await this.fetch("/graphql", { queries: queries });
  }
  async autoBatch(query, variables) {
    let batchKey = `qb${++this._qryBatchIdx}`;

    this._qryBatch[batchKey] = { query: query, variables: variables };

    let promise = new Promise((resolve, reject) => {
      this._qryBatchResponses[batchKey] = {
        resolve: resolve,
        reject: reject,
      };
    });
    this.runBatch();
    return promise;
  }
  runBatch() {
    if (!this._qryBatchTimeoutKey)
      this._qryBatchTimeoutKey = `qbt${++this._qryBatchTimeoutIdx}`;
    let qryBatchTimeoutKey = this._qryBatchTimeoutKey;
    if (!this._qryBatchTimeouts[qryBatchTimeoutKey]) {
      this._qryBatchTimeouts[qryBatchTimeoutKey] = setTimeout(async () => {
        this._qryBatchTimeoutKey = null;
        let res = await this.batch(this._qryBatch);
        let resData = res && res.data ? res.data : {};
        for (let k in resData) {
          if (this._qryBatch[k]) delete this._qryBatch[k];
          if (this._qryBatchResponses[k]) {
            this._qryBatchResponses[k].resolve(new R14ApiResult(resData[k]));
            delete this._qryBatchResponses[k];
          }
        }
        //this._qryBatch = {};
        delete this._qryBatchTimeouts[qryBatchTimeoutKey];
      }, 250);
    }
  }
  async query(query, variables = {}) {
    return await this.autoBatch(query, variables);
    // let res = await this.fetch("/graphql", {
    //   query: query,
    //   variables: variables
    // });
    // return new R14ApiResult(res);
  }
  async subscribe(query, variables = {}, callback) {
    let token = await this._getAccessToken();

    let subscription = new R14ApiSubscription(
      this._r14,
      {
        query: query,
        variables: variables,
        accessToken: token || null,
      },
      callback
    );
    return await subscription.subscribe();

    // let subscriber = new Promise((resolve, reject) => {
    //   this._r14.app.io.emit(
    //     "subscribe",
    //     {
    //       query: query,
    //       variables: variables,
    //       accessToken: token || null,
    //     },
    //     (data) => {
    //       if (data && data.keys) {
    //         for (let i in data.keys) {
    //           this._r14.app.io.on(data.keys[i], (res) => {
    //             callback(new R14ApiResult(res));
    //           });
    //         }
    //       }
    //       resolve(data || null);
    //     }
    //   );
    // });
    // let data = await subscriber;
    // return new R14ApiSubscription(
    //   this._r14,
    //   data && data.keys ? data.keys : null
    // );
  }
  async download(url) {
    // Should this be done with a blob?
    let form = document.createElement("form");
    form.method = "post";
    form.target = "_blank";
    form.action = url;
    form.innerHTML =
      '<input type="hidden" name="accessToken" value="' +
      (await this._getAccessToken()) +
      '">';
    console.log("form:", form);
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }
  async fetchFileBlob(url) {
    let config = {
      headers: {},
      responseType: "blob",
    };
    if (this._fileBlobCache[url]) {
      this._fileBlobCache[url].instances++;
      return this._fileBlobCache[url].blob;
    }
    let ret = null;
    if (await this.hasAccessToken()) {
      config.headers["Authorization"] =
        "Bearer " + (await this._getAccessToken());
    }
    let response = { error: "Unknwon Error." };
    response = await axios.post(url, {}, config);
    ret =
      response && response.status === 200 && response.data
        ? response.data
        : null;
    if (ret) {
      if (this._fileBlobCache[url]) this._fileBlobCache[url].instances++;
      else this._fileBlobCache[url] = { blob: ret, instances: 1 };
    }
    return ret;
  }
  revokeFileBlob(url) {
    if (!this._fileBlobCache[url]) return false;
    if (this._fileBlobCache[url].instances === 1) {
      URL.revokeObjectURL(url);
      delete this._fileBlobCache[url];
    } else this._fileBlobCache[url].instances--;
  }
  async mutate(mutation, variables = {}) {
    // Check if it is an instance of the formDomain, if so, convert to formData
    // let method = "fetch";
    // if(variables.toFormData) variables = variables.toFormData();

    // let vars = {};
    // for(let i in variables){
    //   // Check if formdata should be converted
    //   vars[i] = variables[i].toFormData ? variables[i].toFormData() : variables[i];

    //   if(vars[i] instanceof FormData){
    //     method = "submit";
    //   }
    // }
    let formData = this.createFormData(mutation, variables);
    let res = await this.fetch("/graphql", formData);
    return new R14ApiResult(res);
  }
  async fetch(namespace, data = {}) {
    let config = {
      headers: {},
    };
    if (await this.hasAccessToken()) {
      config.headers["Authorization"] =
        "Bearer " + (await this._getAccessToken());
    }
    let response = { error: "Unknwon Error." };
    // console.log("FETCH DATA", namespace, data, this.baseUrl);
    response = await axios.post(this.baseUrl + namespace, data, config);
    // console.log("FETCH RESPONSE", namespace, response);
    // Save the token
    //if (response.data.accessToken) await AsyncStorage.setItem("accessToken", response.data.accessToken);
    return response.data;
  }
  // Post same as fetch
  async post(namespace, data) {
    return await this.fetch(namespace, data);
  }
  createFileMap(variables, map = {}, namespace = "r14.file") {
    if (variables instanceof File) {
      if (variables.metadata) console.log("CHECK HERE", variables.metadata);
      map[namespace] = variables;
      return variables.metadata ? { metadata: variables.metadata } : null;
    } else if (Array.isArray(variables)) {
      variables.forEach((variable, i) => {
        let nNamespace = namespace ? `${namespace}.${i}` : i;
        variables[i] = this.createFileMap(variable, map, nNamespace);
      });
      return variables;
    } else if (typeof variables === "object") {
      for (let k in variables) {
        let nNamespace = namespace ? `${namespace}.${k}` : k;
        variables[k] = this.createFileMap(variables[k], map, nNamespace);
      }
    } else {
      return variables;
    }
    return variables;
  }
  createFormData(query, variables) {
    let fileMap = {};
    // Variables and fileMap will be updated by value in params
    // All files will be set to null in variables
    // fileMap is a map of file namespace path and file
    this.createFileMap(variables, fileMap);

    let formData = new FormData();
    let ret = null;
    if (Object.keys(fileMap).length) {
      formData.append(
        "r14GraphQlQuery",
        JSON.stringify({
          query,
          variables,
        })
      );
      for (let k in fileMap) {
        formData.append(k, fileMap[k]);
      }
      ret = formData;
    } else ret = { query, variables };
    return ret;
  }
  // Can be either formData or FormUiDomain
  async submit(namespace, form) {
    let formData = form instanceof FormData ? form : form.toFormData();

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    if (await this.hasAccessToken())
      config.headers["Authorization"] =
        "Bearer " + (await this._getAccessToken());
    const response = await axios.post(
      this.baseUrl + namespace,
      formData,
      config
    );

    // Save the token
    //if (response.data.accessToken) await AsyncStorage.setItem("accessToken", response.data.accessToken);

    return response.data.data;
  }
  get config() {
    return this._config;
  }
  get baseUrl() {
    return this.config.url;
  }
  async createAccessTokenRequestUri(uri) {
    if (!(await this.accessTokenExists())) return null;
    let accessToken = await this._getAccessToken();
    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", uri);
      xhr.responseType = "blob";
      xhr.setRequestHeader("Authorization", "Bearer " + accessToken);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === xhr.DONE) {
          if (xhr.status === 200) {
            // this.response is a Blob, because we set responseType above
            let dataUri = URL.createObjectURL(xhr.response);
            resolve(dataUri);
          } else {
            resolve(null);
          }
        }
      };
      xhr.send();
    });
  }
  async _getAccessToken() {
    return (await AsyncStorage.getItem("accessToken")) || null;
  }
  async setAccessToken(accessToken) {
    await AsyncStorage.setItem("accessToken", accessToken);
    return true;
  }
  async deleteAccessToken() {
    await AsyncStorage.removeItem("accessToken");
    return true;
  }
  async accessTokenExists() {
    return (await AsyncStorage.getItem("accessToken")) ? true : false;
  }
  async hasAccessToken() {
    return await this.accessTokenExists();
  }
}
// class R14ApiResult {
//   constructor(res) {
//     this._metadata = {
//       data: res.data || {},
//       errors: res.errors || [],
//     };
//     return new Proxy(this, {
//       get: (obj, prop) => {
//         if (obj[prop]) return obj[prop];
//         if (prop === "then") return undefined;
//         else {
//           console.warn(
//             `R14Api Warning: Accessing data '${prop}' directly from the result is being depreciated. Please use the data / error properties.`
//           );
//           return obj.data[prop];
//         }
//       },
//       set: (obj, prop, val) => {
//         console.warn("R14Api Warning: Cannot set api result values.");
//       },
//     });
//   }
//   toString() {
//     return this._metadata.toString();
//   }
//   get data() {
//     return this._metadata.data;
//   }
//   get errors() {
//     return this._metadata.errors;
//   }
// }
