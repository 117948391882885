import React from "react";
import R14, {
  StyleSheet,
  HiddenInputField,
  BackButton,
  SwitchField,
  Text,
  View,
  SubmitButton,
  TextInputField,
  FileInputField,
  Colors,
  Theme,
  Image,
} from "../core";
import CenteredForm from "../components/CenteredForm";

export default R14.connect(
  class AccountSettingsScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.state = {
        isSuccess: null,
      };
      console.log("formdata?", this.props.formData);
    }
    get formData() {
      return this.props.formData;
    }
    async handleSubmit(accountForm) {
      // await this.props.app.api.download("/files/eyJpIjoiNVBSalRtQ19SIiwiZSI6ImpwZyIsImgiOjY0MCwidyI6OTYwLCJhIjoxfQ");
      // return;

      let values = accountForm.values;
      let updateValues = {
        uid: values.uid,
        name: values.name,
        email: values.email,
        profileImage: undefined,
      };

      if (
        !values.profileImage ||
        this.props.app.utils.form.isFile(values.profileImage)
      )
        updateValues.profileImage = values.profileImage || null;

      console.log("ACCOUNT UPDATES VAL VAL VAL VAL VAL", updateValues);

      if (values.newPassword) updateValues.password = values.newPassword;
      this.props.app.ui.progressIndicator.show();

      let isSuccess = await this.props.app.dm.user.update(updateValues);
      if (isSuccess) {
        this.props.app.ui.snackBar.show({
          message: "Account has been saved.",
        });
        this.props.app.dm.userSession.setName(values.name);
        //this.props.app.dm.userSession.setEmail(values.email);
        this.setState({
          success: isSuccess,
        });
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      this.props.app.ui.snackBar.show({
        message: "Your account has been saved.",
        // variant: "success",
        // dismissTimeout: 0,
        // dismissOnPress: false,
        // actionText: 'Dismiss',
        // // Action defaults to dismiss, however, Material design suggests not using a dismiss button
        // action: ()=>{
        //   console.log("Do something before dismiss...");
        //   this.props.app.ui.snackBar.dismiss();
        // },
        // onRequestDismiss: () => {
        //   // Do something before manual dismiss
        //   this.props.app.ui.snackBar.dismiss();
        // },
        //icon: true
        // // Set margin if there is a FAB
        //marginBottom: 80
      });
    }
    render() {
      return (
        <CenteredForm
          headerLabel='Account Settings'
          name='accountForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          autoComplete={false}
          controlsBottomRight={[<SubmitButton title={"Update"} key='submit' />]}
        >
          {/* {this.state.success && (
            <View style={styles.success}>
              <Text style={styles.successText}>
                Your account has been updated.
              </Text>
            </View>
          )} */}
          <HiddenInputField name='uid' />
          <TextInputField name='name' label='Name' required='Enter Name' />
          <TextInputField
            name='email'
            label='Email'
            validator='email'
            required='Enter Email'
          />
          <TextInputField
            autoComplete={false}
            name='newPassword'
            label='Change Password'
            secure
          />
          <FileInputField name='profileImage' label='Profile Image' />
          {this.props.formData.profileImage && (
            <Image
              autoSize
              auth
              // resizeMode='contain'
              height={this.props.formData.profileImage.height}
              width={this.props.formData.profileImage.width}
              source={{
                uri: this.props.formData.profileImage.url,
              }}
            />
          )}
        </CenteredForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  success: {
    paddingBottom: 24,
  },
  successText: {
    color: Colors.primary,
  },
});
