import React from "react";
import R14, {
  TextInputField,
  SelectMenuField,
  HiddenInputField,
  FieldGroup,
  Text,
  StyleSheet,
} from "../core";
import EditForm from "../components/EditForm";
import LabelView from "../components/LabelView";

export default R14.connect(
  class ProjectAppModuleBuildEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.appModuleItemLoader = this.appModuleItemLoader.bind(this);
      this.handleAppModuleValueChange = this.handleAppModuleValueChange.bind(
        this
      );
    }
    get isEditMode() {
      return this.props.formData &&
        this.props.formData.values &&
        this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    async appModuleItemLoader(filters = {}) {
      filters.type = [
        this.props.app.dm.appModule.TYPE_SERVER,
        this.props.app.dm.appModule.TYPE_REACT_APP,
      ];
      filters.projectUid = this.props.projectUid;
      let res = await this.props.app.dm.appModule.fetchSelections(filters, {
        resultsPerPage: 25,
      });
      return res;
    }
    async handleAppModuleValueChange(val) {}
    render() {
      console.log("check form data",this.props.formData.values);
      return (
        <EditForm
          formData={this.props.formData}
          domainName='appModuleBuild'
          redirect={this.props.redirect}
          refreshDataTable='appModuleBuilds'
        >
          <HiddenInputField key='projectUid' name='projectUid' />
          <SelectMenuField
            name='appModule'
            label='App Module'
            searchable
            itemLoader={this.appModuleItemLoader}
            items={this.formData.appModuleSelections}
            required='Please select an app module.'
            helper='Required'
            autoLoad
          />
          <TextInputField
            name='version'
            label='Version'
            required='Please enter a version.'
            autoFocus
            helper='Example: 1.2.33'
          />
          <TextInputField
            name='description'
            label='Description'
            helper='Optional'
            multiline
            autoSize
          />
        </EditForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  // labelView: {
  //   width: "25%",
  //   minWidth: 0,
  //   flex: 1,
  // },
});
