import React from 'react';
import R14, { Redirect, TextInputField, SubmitButton, StyleSheet } from '../core';
import CenteredForm from '../components/CenteredForm';
export default R14.connect(
  class LoginScreen extends React.Component {
    constructor(props){
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    async handleSubmit(loginForm){
      try{
        this.props.app.ui.progressIndicator.show();
        let isLoggedIn = await this.props.app.dm.userSession.auth(loginForm.values);
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
        if (isLoggedIn) return <Redirect to="landing" />
      }
      catch(err){
        this.props.app.ui.form('loginForm').addError(err.message);
      }
    }
    render() {
      return (
        <CenteredForm
          name="loginForm"
          style={styles.centeredForm}
          headerLabel="Login"
          validateBeforeSubmit
          onSubmit={this.handleSubmit}
          controlsBottomRight={[
            // <Button
            //   title="Forgot Password"
            //   variant="text"
            //   color={Colors.secondary}
            //   to='forgotPassword'
            //   key='forgotPassword'
            // />,
            <SubmitButton
              title="Continue"
              key='submit'
            />
          ]}
        >
          <TextInputField
            name="email"
            label="Email"
            icon="account"
            required="Enter Email"
            validator='email'
            //autoFocus
          />
          <TextInputField
            name="password"
            label="Password"
            icon="lock"
            secure
            required="Enter Password"
            debug="password"
          />
        </CenteredForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  centeredForm: {
    width: 320,
    minWidth: 320,
    screen: ({ width }) => {
      if (width <= 320)
        return {
          width: "100%",
        };
    },
  },
});